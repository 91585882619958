import VueGtag from "vue-gtag-next";
import VueCookieComply from 'vue-cookie-comply'
import App from "./App.vue";
import {createApp} from 'vue' // CHANGE
import {createI18n} from 'vue-i18n';


import router from "./router";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faEnvelope,
    faBrain,
    faComments,
    faCalendarWeek,
    faRecordVinyl,
    faSyringe,
    faStethoscope,
    faCloud,
    faGaugeSimpleHigh,
    faPersonChalkboard,
    faPlusCircle,
    faLightbulb,
    faCompass,
    faGauge,
    faCoins,
    faArrowsLeftRight,
    faStopwatch,
    faBook,
    faUsers,
    faCheck
} from '@fortawesome/free-solid-svg-icons'
import {faLinkedinIn, faTwitter, faMedium} from '@fortawesome/free-brands-svg-icons'
import messages from './components/generic-i18n'

library.add(faMedium, faCheck, faUsers, faArrowsLeftRight, faBook, faStopwatch, faCoins, faGauge, faEnvelope, faBrain, faStethoscope, faLinkedinIn, faSyringe, faRecordVinyl, faComments, faPersonChalkboard, faCalendarWeek, faTwitter, faCloud, faGaugeSimpleHigh, faComments, faLightbulb, faPlusCircle, faCompass);

import VueVideoPlayer from '@videojs-player/vue'

// Vue.config.productionTip = false;

const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: navigator.language.split('-')[0], // set locale to browser locale
    fallbackLocale: 'en', // set fallback locale
    availableLocales: ['nl', 'en'],
    messages: messages
});

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(i18n)
    .use(router)
    .use(VueVideoPlayer)
    .use(VueCookieComply)
    .use(VueGtag, {
        property: {
            params: {
                linker: {
                    domain: [window.location.host]
                }
            }
        }
    })
    .mount('#app')
