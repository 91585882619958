export const PATTERNS = {
    "patterns": [
        {
            "id": "1",
            "title": "The desire for an one-size-fits-all company wide way of working.",
            "category": ["process"],
            "symptoms": [
                "Adopting methods without taking the characteristics of the company, the products and the teams into account.",
                "Struggling with implementation and maintenance of the chosen way or working. Even after a substantial time.",
                "Switching from SAFe, to LeSS, Scrum, Kanban, BusDevOps. All without definite success.",
                "Abolishment of current subjects like service management and product management.",
                "Lack of patterns or building blocks for teams to adopt."
            ],
            "impact": [
                "The chosen way of working does not give the promised improvement of quality, speed, predictability, business value or culture.",
                "The stakeholders are not more content and/or do not have more trust in the IT department than  before.",
                "Teams and the organisation have a hard time to embed the chosen way of working.",
                "Activities are experienced as overhead and far from efficient.",
                "Teams are re-inventing the wheel. Adoption is slow, and the transfer of individuals requires a lot of norming, bootstrapping, relearning."
            ],
            "remedies": [
                "Ways of working should always serve a company's objectives. These objectives can differ per team/product. For teams who have a strong maintenance focus there are certain ways that work better than for the more innovative teams.",
                "A company with highly skilled senior developers has other needs than a company that has more juniors and trainees.",
                "We always look at the company and product objectives. Only after that we will align these with the possible solutions. The core of all solutions is that they are measurable in regards to Predictability, Quality, Stability, Business value. This way we make sure that the continuous improvement processes are data driven and maintainable."
            ],
            "references": []
        },
        {
            "id": "2",
            "title": "Dogmatic implementation and execution of methodologies.",
            "category": ["process"],
            "symptoms": [
                "Mechanical adoption. Adopting only the movements and not the way of thinking, culture.",
                "It's not possible to measure and evaluate a team’s output to evaluate meaningful process changes",
                "Dogmatic execution over a period superseding the first year of adoption.",
                "Method execution is not discussed as retrospective improvements.",
                "Using method components interchangeably, without first mastering them in isolation. "
            ],
            "impact": [
                "Overuse of delivery methods can slow down work execution, tire team members, and cause mechanical execution. More focus on the process and the output than on the outcomes."
            ],
            "remedies": [
                "We firmly believe in Shu Ha Ri. Regardless of what method is selected, a team starts its agile adoption in guided fashion. Performing the movements by the book. After a couple of sprints a team is able to reflect on its performance and should be able to notice cause and effect of tweaks to the process. We recommend doing this within the targeted framework at first.",
                "When delivery of the team can be evaluated, and the process is mastered, the team should autonomously be able to tweak the process to the fullest extent."
            ],
            "references": []
        },
        {
            "id": "3",
            "title": "Delivery is too slow and does not meet the speed the business demands.",
            "category": ["process"],
            "symptoms": [
                "The time it takes to get ideas to the end users takes too long.",
                "The business has much more ideas than IT is able to fulfill.",
                "New tools, technology and processes do not speed up the delivery.",
                "Lack of automation or automation without the required quality."
            ],
            "impact": [
                "The backlog is growing and things that are being delivered are not the things the end user needs.",
                "Competitors deliver more modern solutions and attract your customers.",
                "No time to improve the quality and processes because there is a general  feeling of 'we are running behind and still we have so much more to do'.",
                "The business starts looking for solutions outside the IT department."
            ],
            "remedies": [
                "It is crucial here to start with mapping the whole process from the creation of an idea to whenever it gets to the end user and find the most important bottleneck.",
                "We do this by making this flow transparent, visible and measurable. Based on this action we will prioritise bottlenecks. Often by removing one others will follow.",
                "This way of identifying and measuring will also help in choosing the right kind of investment for resolving each bottleneck (spend money where it hurts first)."
            ],
            "references": []
        },
        {
            "id": "4",
            "title": "Trying to copy the way of working from another company.",
            "category": ["process"],
            "symptoms": [
                "Instead of embracing a learning journey, companies choose to select and copy a well known way of working from a big tech company and implement this top down.",
                "Not fixing the end to end processes but opting for a one-size-fits-all agile scaling framework (making everything bigger).",
                "Using technology or processes because 'big tech company x' also says they use them."
            ],
            "impact": [
                "Spending money and resources for technical practices that do not serve your company's business goals.",
                "Much overhead in the processes/bloated processes. Many meetings and communication lines.",
                "More focus on getting the processes finally working and maintainable instead of building the right things the right way.",
                "Frustration because it takes much work to get the copied way of working up and running and maintaining it."
            ],
            "remedies": [
                "We must always look at the essence of the company and which purpose the software products have in this. Instead of copying ways to deliver very fast we need to learn to honestly see if this will give us a major advantage. Same goes for scalability, security, quality, stability, innovativeness and so on.",
                "Only if we know more about the purpose, strategy and business objectives can we really think of the best and cheapest way to realise these goals."
            ],
            "references": []
        },
        {
            "id": "5",
            "title": "New architecture and technologies still don't fulfill the business goals.",
            "category": ["technology"],
            "symptoms": [
                "There is a new architecture/technology which first needs to be implemented just to deliver the same application.",
                "The promises of the possibilities of new technology are not being met from a business perspective.",
                "Trying to solve all problems with technology instead of also looking at the processes.",
                "New features are on hold until the new architecture/technology is in place.",
                "Speed and quality of delivery is still not meeting the business demands."
            ],
            "impact": [
                "The products are not improving in the eyes of the end user and the business.",
                "Innovative features that are needed are being pushed to the future.",
                "Disappointed stakeholders and end users."
            ],
            "remedies": [
                "All technical choices and promises must serve the business goals. Before any technical decision is being made the business case and the main objectives need to be defined. For any initiative the smallest possible solution needs to be defined and prototyped."
            ],
            "references": []
        },
        {
            "id": "6",
            "title": "Strategy, objectives and the desired outcomes are not clear for all.",
            "category": ["process"],
            "symptoms": [
                "Teams/departments do not know the high level challenges from a business pov and focus on solving features.",
                "Teams/departments can not think of proper solutions since the main problems/challenges are not clearly described.",
                "Teams are presented with solutions instead of problems they can solve.",
                "Outcomes are not evaluated, monitored or measured so the added value is unclear."
            ],
            "impact": [
                "Teams finish user stories instead of building a product that contributes to reaching business goals.",
                "There is uncertainty that the money is spent or the right things.",
                "The product or services are not the best fit for the specific end users and business goals."
            ],
            "remedies": [
                "Objectives and desired outcomes should be clearly described and communicated to the whole company.",
                "The desired outcomes should be measurable as much as possible. These measurements should be known to all and be part of the product development.",
                "The relation between each feature, the epic goals and the business objectives must always be known."
            ],
            "references": []
        },
        {
            "id": "7",
            "title": "Death marches are seen as a solution to speed up delivery.",
            "category": ["culture"],
            "symptoms": [
                "Overwork is common, team members are spending evenings and weekends to make a deadline.",
                "Developers have burnouts / overworked / ask transfer to other teams.",
                "Sick/absence percentages are higher compared to normal teams.",
                "Quality of products produced is being compromised.",
            ],
            "impact": [
                "Team members leave the team, causing additional escalation of the death march. ",
                "Employee satisfaction is low.",
                "Software project going over budget (overwork still comes at a cost).",
                "Quality of products is usually compromised because of an unhealthy pace."
            ],
            "remedies": [
                "Visualise employees efforts versus sprint results.",
                "Make employee happiness part of the retrospective occasionally. Map trends, and listen to your workforce.",
                "Improve the organization's planning capability. A death march is not something people plan for (usually), its because of unforeseen work. Add roadmap and portfolio capabilities.",
                "Increase upfront knowledge, invest in solution architecture, perform technical spikes, use a feedback-first approach (DevOps).",
                "Standardise on technology. Teams running on a well-known technical platform are capable of delivering faster"
            ],
            "references": [
                {
                    "title": "Why project death marches are not all bad",
                    "link": "https://betterprogramming.pub/why-project-death-marches-aren-t-as-bad-as-they-sound-41425e134d30"
                }
            ]
        },
        {
            "id": "8",
            "title": "Teams are waiting on dependent teams to release their work.",
            "category": ["technology"],
            "symptoms": [
                "Deployments are stalling.",
                "Other teams are weight down by future requests from depending teams.",
                "Product owners complain about blocking externals.",
            ],
            "impact": [
                "Delivery is slow. ",
                "Employee satisfaction is low.",
                "Software project going over budget (overwork still comes at a cost).",
                "Quality of products is usually compromised because of an unhealthy pace."
            ],
            "remedies": [
                "Visualise employees efforts versus sprint results.",
                "Make employee happiness part of the retrospective occasionally. Map trends, and listen to your workforce.",
                "Improve the organization's planning capability. A death march is not something people plan for (usually), its because of unforeseen work. Add roadmap and portfolio capabilities.",
                "Increase upfront knowledge, invest in solution architecture, perform technical spikes, use a feedback-first approach (DevOps).",
                "Standardise on technology. Teams running on a well-known technical platform are capable of delivering faster"
            ],
            "references": [
                {
                    "title": "Why project death marches are not all bad",
                    "link": "https://betterprogramming.pub/why-project-death-marches-aren-t-as-bad-as-they-sound-41425e134d30"
                }
            ]
        },
        {
            "id": "9",
            "title": "UX-first approach is not strong enough to deal with complicated business domain.",
            "category": ["architecture"],
            "symptoms": [
                "Many agile adoptions have put UX, and end-user validation at the forefront of running a project.",
                "Software products have become 'screen oriented'.",
                "Data-models, proper abstractions, and API contracts are not first-class citizen.",
            ],
            "impact": [
                "Product elaboration is shallow.",
                "Lots of time-consuming rework.",
                "Solution is not meeting the business case's demands / solving the problem.",
            ],
            "remedies": [
                "Employ elaboration techniques like Impactmapping, Storymapping, Eventstorming to engage in multi-disciplinary ideation." +
                "Invest in a Domain Driven Design (DDD) process to get to develop a business solution as a core first before moving to screens/API's." +
                "Perform contract-based API testing/development to speed up model creation.",
                "Isolate and contain application internals so rework can be done fast, without harming dependant teams."
            ],
            "references": []
        },
        {
            "id": "10",
            "title": "Teams are not organised in harmony with the application/platform architecture. ",
            "category": ["architecture","organization"],
            "symptoms": [
                "Teams have too much dependencies with other teams and require careful synchronisation/coordination to deliver work. ",
            ],
            "impact": [
                "Delivery is slowed down. ",
                "The architecture is evolving in an unplanned and unhealthy manner",
            ],
            "remedies": [
                "A inverse / reverse Conway manoeuvre is meant to align the team's structure to the architecture.",
                "Decouple using message brokers, API's."
            ],
            "references": []
        },
        {
            "id": "11",
            "title": "Platform teams are taking cognitive load on feature teams.",
            "category": ["organization"],
            "symptoms": [
                "Feature teams, and the speed of their delivery, are impacted by the actions platform teams have to take (often manual creation of instances/services/accounts).",
                "Platform team delivers services with little abstraction, without useful pre-configuration and documentation.",
                "Platform team delivers services that cost time to implement and configure for the feature teams.",
                "There are extensive waiting times between requesting and the fulfillment of a service/product.",
                "Platform teams need to provide training for consumers in order for them to use a service.",
                "Hard to find the right people to get a fully staffed feature team up and running since feature team members need to have knowledge of many supporting tools.",
            ],
            "impact": [
                "Feature teams do not have the flexibility to be quick, innovative and agile in their ways to serve the business goals.",
                "The software delivery is slower than it should be with much waiting times and unnecessary communication.",
                "Hiring people and them up to speed is harder and more costly than it should be.",
                "Feature teams experience frustration because they have to request, wait and can not proceed as fast as they need/want.",
                "Platform teams become frustrated as they are serving a never-ending flow of requests instead of focussing on productizing and improving.",
            ],
            "remedies": [
                "Regard platform teams as product teams with the feature teams as most important stakeholders and users. Feature teams must be able to use the platform services with the least amount of knowledge of the platform (self-service).",
                "Align the platform product vision directly to the company's strategy and business cases.",
                "Platform teams must hide complex internals of services from feature teams by automation, self-service and configurations).",
                "Add sensible defaults (and overrides) so implementing teams can bootstrap services with generic configuration.",
                "Supporting services have to be higher in the stack (like Serverless)."
            ],
            "references": [
                {"title":"Create synergy in platform teams!","link":"https://www.linkedin.com/pulse/create-synergy-platform-teams-software-in-rhythm"},
                {"title":"Platform teams are product teams","link":"https://www.linkedin.com/pulse/platform-teams-product-software-in-rhythm/"}
            ]
        },
        {
            "id": "12",
            "title": "The reason for organising as a DevOps organisation is unclear/implicit.",
            "category": ["organization"],
            "symptoms": [
                "Employees have a hard time adopting DevOps WoW since the direction and reason are unclear.",
                "There are many views on the value and definition of DevOps and what is expected from a DevOps organisation and the employees.",
                "Discussions are not about the essence of DevOps (and often Agile) but more on a mechanical level.",
                "There are many views on what is important and what should be done first.",
            ],
            "impact": [
                "Many initiatives and much effort goes into finally getting the WoW to work.",
                "The desired outcomes are not being reached (more quality, speed, levels of knowledge, lower costs etc).",
                "Frustration concerning the lack of progress turns into general apathy.",
                "No clear and concise definition of the why results in many discussions on what is most important and how to approach.",
            ],
            "remedies": [
                "Choose a starting reason to organise as a DevOps organisation.",
                "Make it explicit what is being expected from the organisation, the teams and individuals.",
                "Propagate that the road to a DevOps organisation is a never ending continuous improvement journey that will be taken in baby steps.",
                "Explicitly and transparently discuss the expectations and the concerns with teams and individuals as leadership.",
            ],
            "references": [
                {
                    "title":"Why DevOps?",
                    "link":"https://www.linkedin.com/pulse/why-devops-software-in-rhythm/"
                }
            ]
        }
    ]
}