<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
  <section class="page-section" id="offering">
    <div class="container">
      <div class="text-left">
        <h1 class="section-heading text-uppercase"> {{ t("title")}} </h1>
        <div class="row my-5" id="portfolio">
          <div class="col-lg-8 col-sm-8 mb-8">
            <p class="section-subheading text-muted justified"> {{ t("training_p1")}} </p>
            <p class="section-subheading text-muted justified"> {{ t("training_p2")}} </p>
            <p class="section-subheading text-muted justified"> {{ t("training_p3")}} </p>
            <h3> {{ t("training_p4_title")}} </h3>
            <p class="section-subheading text-muted justified"> {{ t("training_p4")}} </p>
            <p class="section-subheading text-muted justified"> {{ t("training_p5")}} </p>
            <img class="card-img" src="/assets/img/training/po-flow-final.png" alt="models">
            <p class="section-subheading text-muted justified">{{ t("training_p6")}}</p>
            <img class="card-img" src="/assets/img/training/simplified.jpeg" alt="models">
            <p class="section-subheading text-muted justified">{{ t("training_p7")}}</p>
            <img class="card-img" src="/assets/img/training/with-hypo.jpeg" alt="models">
            <h3> {{ t("training_p8_title")}} </h3>
            <p class="section-subheading text-muted justified">{{ t("training_p8")}}</p>
            <img class="card-img" src="/assets/img/training/on-scale.jpeg" alt="models">
            <p class="section-subheading text-muted justified">{{ t("training_p9")}}</p>
            <img class="card-img" src="/assets/img/training/scaled-levels.jpeg" alt="models">
            <p class="section-subheading text-muted justified">{{ t("training_p10")}}</p>
            <img class="card-img" src="/assets/img/training/measure.jpeg" alt="models">
            <h3> {{ t("training_p11_title")}} </h3>
            <p class="section-subheading text-muted justified">{{ t("training_p11")}}</p>
            <ul class="text-muted">
              <li>{{ t("training_lo1") }}</li>
              <li>{{ t("training_lo2") }}</li>
              <li>{{ t("training_lo3") }}</li>
              <li>{{ t("training_lo4") }}</li>
              <li>{{ t("training_lo5") }}</li>
              <li>{{ t("training_lo6") }}</li>
            </ul>
          </div>
          <div class="col-lg-4 col-sm-4 mb-4">
            <img class="img-fluid" src="assets/img/training/on-scale.jpeg" alt=""/>
            <div class="row pt-5">
              <router-link :to="{path:'/', hash:'#contact'}">
                <button class="btn btn-primary w-100" type="button">
                  Book a training
                </button>
              </router-link>
            </div>
            <div class="row pt-5">
              <h3 class="h5">{{ t("training_options") }}</h3>
              <p class="text-muted">{{ t("training_option_1") }}</p>
              <p class="text-muted">{{ t("training_option_2") }}</p>
              <p class="text-muted">{{ t("training_option_3") }}</p>
              <p class="text-muted"></p>
            </div>
            <div class="row pt-5">
              <h3 class="h5">{{ t("training_rel_blogs") }}</h3>
              <ul class="text-muted px-4">
                <li><a href="https://nielstalens.nl/2021/01/25/a-practical-guide-for-getting-ideas-to-production-part-1/" target="_blank">A practical guide for getting ideas to production – Part 1</a></li>
                <li><a href="https://nielstalens.nl/2021/02/15/a-practical-guide-for-getting-ideas-to-production-part-2/" target="_blank">A practical guide for getting ideas to production – Part 2</a></li>
                <li><a href="https://nielstalens.nl/2021/02/24/a-practical-guide-for-getting-ideas-to-production-part-3/" target="_blank">A practical guide for getting ideas to production – Part 3</a></li>
                <li><a href="https://nielstalens.nl/2018/07/12/the-perfect-product-owners-flow/" target="_blank">The perfect Product Owners flow</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Value Driven Product Thinking course",
    "training_p1": "Product leadership is not so much about progress on features, but whether changes have the desired effect on achieving business objectives.",
    "training_p2": "In practice you rarely see the relationship with the strategic goals and, more importantly, the right conversations about what each 'chunk' will contribute to the overall strategy. This makes it very difficult for everyone to understand what is really desired and what goals should be pursued.",
    "training_p3": "During this course, participants learn how to set up an objective-based flow that results in a continuous contribution to strategic goals.",
    "training_p4_title": "The Course",
    "training_p4": "The Value Driven Product Thinking course is based on the principle that all products and their business cases are built around the same strategy and strategic goals. A crucial element is that we continuously look at the effects we hope to achieve with our changes in order to achieve these strategic goals.",
    "training_p5": "A typical product flow is described below. In this flow you get to work with strategic goals and transform them in a controlled way into working software that your users can use:",
    "training_p6": "During the Value Driven Product Thinking course we focus on a subset of all elements from this flow that are leading in regards to product thinking:",
    "training_p7": "Participants learn the importance of working from strategic objectives and how these can be crafted so they continuously can be validated. They learn how these objectives are embedded in business cases, value propositions, product visions and epics/features.",
    "training_p8_title": "Scaling agile portfolio management",
    "training_p8": "One of the most difficult issues for many companies is making agile product portfolio management and planning scalable. Where many scaling methods mainly focus on the planning of features, during this course we learn the importance of planning things that can be validated against the strategic goals and business cases. In addition, participants learn how to embed this in a form and meeting structure that facilitates this:",
    "training_p9": "In what phase and at what level which goals must be set and validated:",
    "training_p10": "And how to ensure strategic product driven development by means of clear objectives and hypotheses.",
    "training_p11_title": "After the course",
    "training_p11": "After the course, the participants will know:",
    "training_lo1": "How to draw up strategic goals, business cases and product visions",
    "training_lo2": "How to set up and link the goals, business cases and product visions",
    "training_lo3": "How to ensure that continuous validation takes place at all levels and whether changes contribute to reaching the goals",
    "training_lo4": "How to position multiple products around a shared strategy",
    "training_lo5": "What meeting structures with their specific goals apply",
    "training_lo6": "How to translate product visions into measurable and validable hypothesis and experiments",
    "training_book": "Book a course",
    "training_options": "Course options",
    "training_option_1": "Language: Dutch/English",
    "training_option_2": "Duration: 1-3 months",
    "training_option_3": "Location: Onsite",
    "training_rel_blogs": "Related blogs"
  },
  "nl": {
    "title": "Cursus Waardegedreven Product Denken",
    "training_p1": "Productleiderschap gaat niet zozeer over de voortgang op features, maar of veranderingen het gewenste effect hebben mbt het bereiken van bedrijfsdoelstellingen. ",
    "training_p2": "In praktijk zie je zelden de relatie met de strategische doelen en, nog belangrijker, de juiste gesprekken over wat elk 'brok' zal bijdragen aan de algehele strategie. Dit maakt het erg moeilijk om te begrijpen wat echt gewenst is en welke doelen er moeten worden nagestreefd. ",
    "training_p3": "Tijdens deze cursus leren deelnemers hoe ze een op strategische doelstellingen gebaseerde flow opzetten die resulteert in een continue bijdrage aan strategische doelen.",
    "training_p4_title": "De cursus",
    "training_p4": "De cursus Waardegedreven Product Denken gaat uit van het principe dat alle producten en hun business cases rondom de strategie en de strategische doelen worden gebouwd. Een cruciaal element is dat we vervolgens continu kijken welke effecten we hopen te bereiken met onze veranderingen om zodoende deze strategische doelen te behalen.",
    "training_p5": "Hieronder staat een typische product flow beschreven. In deze flow ga je aan de slag met strategische doelen en transformeer je deze op een gecontroleerde manier naar werkende software waar je gebruikers mee aan de slag kunnen:",
    "training_p6": "Tijdens de cursus Waardegedreven product denken gaan we uit van een subset van alle elementen uit deze flow die vanuit het product denken leidend zijn:",
    "training_p7": "Deelnemers leren hoe belangrijk het is om vanuit strategische doelstellingen te werken en hoe deze opgesteld kunnen worden zodat ze valideerbaar zijn en blijven. Vervolgens leren ze hoe deze doelstellingen vormgegeven kunnen worden in zaken als business cases, propositie uitwerkingen, product visies en epics/features.",
    "training_p8_title": "Schalen van agile portfolio management",
    "training_p8": "Eén van de lastigste vraagstukken voor veel bedrijven is het schaalbaar maken van agile product portfolio management en planning. Waar veel manieren van schalen vooral de focus leggen op de planning van features leren we tijdens deze cursus het belang van het plannen van zaken die te valideren zijn aan de business case en strategische doelen. Daarnaast leren deelnemers hoe dit te integreren is in een vorm en overlegstructuur die dit maximaal faciliteert:",
    "training_p9": "In welke fase en op welk niveau er welke doelen gesteld en gevalideerd moeten worden:",
    "training_p10": "En hoe je door middel van heldere objectives en hypotheses kan zorgen voor strategisch product driven development.",
    "training_p11_title": "Na de cursus",
    "training_p11": "Na de cursus weten de deelnemers:",
    "training_lo1": "Hoe strategische doelen, business cases en product visies op te stellen",
    "training_lo2": "Hoe de doelen, business cases en product visies aan elkaar te linken",
    "training_lo3": "Hoe ervoor te zorgen dat er continu en op alle niveaus gevalideerd kan worden of er wordt bijgedragen aan de doelen",
    "training_lo4": "Hoe meerdere producten te positioneren rondom een gedeelde strategie",
    "training_lo5": "Welke overlegstructuren er zijn met elk hun specifieke doelen",
    "training_lo6": "Hoe product visies te vertalen in meetbare en valideerbare hypothesen en experimenten",
    "training_book": "Book a course",
    "training_options": "Cursus opties",
    "training_option_1": "Language: Nederland/Engels",
    "training_option_2": "Duur: 1-3 maand",
    "training_option_3": "Location: Onsite",
    "training_rel_blogs": "Related blogs"
  }
}
</i18n>

<script>

const lang = {
  "d":'d'
}

export default {
  name: 'TrainingProductThinking'
};
</script>
