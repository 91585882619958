<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
  <section class="page-section" id="offering">
    <div class="container">
      <div class="text-left">
        <h1 class="section-heading text-uppercase"> {{ t("title")}} </h1>
        <div class="row my-5" id="portfolio">
          <div class="col-lg-8 col-sm-8 mb-8">
            <p class="section-subheading text-muted justified"> {{ t("training_p1")}} </p>
            <p class="section-subheading text-muted justified"> {{ t("training_p2")}} </p>
            <p class="section-subheading text-muted justified"> {{ t("training_p3")}} </p>
            <h3> {{ t("training_p4_title")}} </h3>
            <p class="section-subheading text-muted justified"> {{ t("training_p4")}} </p>
            <img class="card-img" src="/assets/img/training/pltfrm-team.png" alt="models">
            <p class="section-subheading text-muted justified"> {{ t("training_p5")}} </p>
            <p class="section-subheading text-muted justified">
                {{ t("training_p6_title")}}
              <ul class="text-muted">
                <li> {{ t("training_p6_lo1")}} </li>
                <li> {{ t("training_p6_lo2")}} </li>
              </ul>
                {{ t("training_p7_title")}}
              <ul class="text-muted">
                <li> {{ t("training_p7_lo1")}} </li>
                <li> {{ t("training_p7_lo2")}} </li>
                <li> {{ t("training_p7_lo3")}} </li>
              </ul>
                {{ t("training_p8_title")}}
              <ul class="text-muted">
                <li> {{ t("training_p8_lo1")}} </li>
                <li> {{ t("training_p8_lo2")}} </li>
                <li> {{ t("training_p8_lo3")}} </li>
                <li> {{ t("training_p8_lo4")}} </li>
              </ul>
                {{ t("training_p9_title")}}
              <ul class="text-muted">
                <li> {{ t("training_p9_lo1")}} </li>
                <li> {{ t("training_p9_lo2")}} </li>
              </ul>
                {{ t("training_p10_title")}}
              <ul class="text-muted">
                <li> {{ t("training_p10_lo1")}} </li>
                <li> {{ t("training_p10_lo2")}} </li>
                <li> {{ t("training_p10_lo3")}} </li>
                <li> {{ t("training_p10_lo4")}} </li>
              </ul>
            </p>
          </div>
          <div class="col-lg-4 col-sm-4 mb-4">
            <img class="img-fluid" src="assets/img/training/beneath-surface.png" alt=""/>
            <div class="row pt-5">
              <router-link :to="{path:'/', hash:'#contact'}">
                <button class="btn btn-primary w-100" type="button">
                  Book a training
                </button>
              </router-link>
            </div>
            <div class="row pt-5">
              <h3 class="h5">{{ t("training_options") }}</h3>
              <p class="text-muted">{{ t("training_option_1") }}</p>
              <p class="text-muted">{{ t("training_option_2") }}</p>
              <p class="text-muted">{{ t("training_option_3") }}</p>
              <p class="text-muted"></p>
            </div>
            <div class="row pt-5 ">
              <h3 class="h5">{{ t("training_advantage_title") }}</h3>
              <ul class="text-muted px-4">
                <li>{{ t("training_ad_1") }}</li>
                <li>{{ t("training_ad_2") }}</li>
                <li>{{ t("training_ad_3") }}</li>
                <li>{{ t("training_ad_4") }}</li>
                <li>{{ t("training_ad_5") }}</li>
              </ul>
            </div>
            <div class="row pt-5">
              <h3 class="h5">{{ t("training_rel_blogs") }}</h3>
              <ul class="text-muted px-4">
                <li><a href="https://www.linkedin.com/pulse/platform-teams-driving-force-improvement-niels-talens" target="_blank">Platform teams as driving force of improvement</a></li>
                <li><a href="https://www.linkedin.com/pulse/platform-teams-product-software-in-rhythm" target="_blank">Platform teams are product teams</a></li>
                <li><a href="https://www.linkedin.com/pulse/create-synergy-platform-teams-software-in-rhythm" target="_blank">Create synergy in platform teams!</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Running platform teams",
    "training_p1": "Maximizing the delivery of business value is only possible when supporting platform and sub-system teams also strive to reach the same goals as the (customer-facing feature) teams. While having different end-users and stakeholders, the purpose of all teams must be to serve the company's strategy and business goals.",
    "training_p2": "In practice, there often seems to be an entirely different approach in how different teams serve their end-users. Feature teams tend to be more end-user focussed, as a result of the agile way of working, but the operational or supporting teams have a hard time connecting to this way of thinking because they 'don't deliver any value to their customers'.",
    "training_p3": "The perception of business value is just different. While not serving end-users directly an internal platform team has a really big impact on the services that are used by end-users.",
    "training_p4_title": "Platform teams are product teams",
    "training_p4": "This workshop is designed to align the platform strategy and product vision with the ones from the company and its products. The workshop will provide insights and practical methods on how to set up and run in-company platform teams.",
    "training_p5": "During this workshop we will regard platforms and platform teams from different angles. After the workshop participants will know all about:",
    "training_p6_title": "Platforms are products",
    "training_p6_lo1": "Product vision and strategy",
    "training_p6_lo2": "Knowing your customer and their needs",
    "training_p7_title": "Platform teams are product teams",
    "training_p7_lo1": "How to grow from inward facing to outgoing platform teams",
    "training_p7_lo2": "Stakeholder management and communication",
    "training_p7_lo3": "Personas",
    "training_p8_title": "Not only tools",
    "training_p8_lo1": "Platform teams as driver of the DevOps maturity",
    "training_p8_lo2": "Platform team Center of Excellence (CoE)",
    "training_p8_lo3": "Building communities",
    "training_p8_lo4": "Inner source models",
    "training_p9_title": "When is a platform a good idea",
    "training_p9_lo1": "Find the distinctiveness first",
    "training_p9_lo2": "A platform is not the goal",
    "training_p10_title": "Platform products essentials",
    "training_p10_lo1": "Self-service products",
    "training_p10_lo2": "Configuration as code",
    "training_p10_lo3": "Monitoring and logging",
    "training_p10_lo4": "Security",
    "training_p11_title": "How to",
    "training_p11_lo1": "Platform team roles",
    "training_p11_lo2": "How to sell",
    "training_p11_lo3": "How to measure success",
    "training_book": "Book a training",
    "training_options": "Training options",
    "training_option_1": "Language: Dutch/English",
    "training_option_2": "Duration: 1 day",
    "training_option_3": "Location: Onsite / hosted venue",
    "training_advantage_title": "Training advantages",
    "training_ad_1": "Being able actively contribute to a general strategy through a platform",
    "training_ad_2": "Management of platform teams as product teams",
    "training_ad_3": "Know how set-up a Center of Excellence",
    "training_ad_4": "Know how to build a community of users",
    "training_ad_5": "Have insight in the platform products essentials",
    "training_rel_blogs": "Related blogs"
  },
  "nl": {
    "title": "Running platform teams",
    "training_p1": "Het maximaliseren van de levering van business value is alleen mogelijk wanneer de platform teams dezelfde doelen nastreven als de feature teams. Ze hebben enkel verschillende eindgebruikers en belanghebbenden, maar hun doel moet zijn om de strategie en doelen van het bedrijf te dienen.",
    "training_p2": "Er lijkt vaak een heel andere benadering te zijn in de manier waarop verschillende teams hun eindgebruikers bedienen. Feature teams zijn doorgaans meer gericht op de eindgebruiker, als gevolg van de agile manier van werken, maar de operationele of ondersteunende teams hebben moeite om aan te sluiten bij deze manier van denken omdat ze 'geen waarde leveren aan hun klanten'.",
    "training_p3": "De perceptie van business value is gewoon anders. Hoewel een intern platform team eindgebruikers niet rechtstreeks bedient, heeft het een grote impact op de services die door eindgebruikers worden gebruikt.",
    "training_p4_title": "Platform teams are product teams",
    "training_p4": "Deze workshop is bedoeld om de platform strategie en visie af te stemmen op die van het bedrijf en haar producten. De workshop biedt inzichten en praktische methoden voor het opzetten en runnen van in-company platform teams. Niet vanuit een technisch perspectief, maar om juist actief bij te dragen aan het maximaliseren van de business value die het bedrijf levert.",
    "training_p5": "Tijdens deze workshop zullen we ons vanuit verschillende invalshoeken richten op platforms en platform teams. Na de workshop weten de deelnemers alles over het volgende:",
    "training_p6_title": "Platforms zijn producten",
    "training_p6_lo1": "Product visie en -strategie",
    "training_p6_lo2": "Klanten en hun behoeften kennen",
    "training_p7_title": "Platform Teams zijn product teams",
    "training_p7_lo1": "Hoe te groeien van een naar binnen gericht naar een uitgaand servicegericht platform team",
    "training_p7_lo2": "Stakeholder management and communicatie",
    "training_p7_lo3": "Persona's",
    "training_p8_title": "Niet alleen tools",
    "training_p8_lo1": "Platform Teams als aanjager van de DevOps-volwassenheid",
    "training_p8_lo2": "Platform team Center of Excellence (CoE)",
    "training_p8_lo3": "Communities bouwen",
    "training_p8_lo4": "Inner source-modellen",
    "training_p9_title": "Wanneer is een platform een ​​goed idee",
    "training_p9_lo1": "Vind het onderscheidend vermogen",
    "training_p9_lo2": "Een platform is niet het doel",
    "training_p10_title": "Essentiële platform producten",
    "training_p10_lo1": "Self-service producten",
    "training_p10_lo2": "Configuration as code",
    "training_p10_lo3": "Monitoring en logging",
    "training_p10_lo4": "Security",
    "training_p11_title": "How to",
    "training_p11_lo1": "Platform team rollen",
    "training_p11_lo2": "Hoe te verkopen",
    "training_p11_lo3": "Hoe succes te meten",
    "training_book": "Book a training",
    "training_options": "Training options",
    "training_option_1": "Language: Nederland/Engels",
    "training_option_2": "Duration: 1 dag",
    "training_option_3": "Location: Onsite / hosted venue",
    "training_advantage_title": "Training voordelen",
    "training_ad_1": "Actief kunnen bijdragen aan de algemene strategie dmv een platform",
    "training_ad_2": "Sturing van platform teams als product teams",
    "training_ad_3": "Weten hoe een Centre of Excellence op te zetten",
    "training_ad_4": "Weet hoe u een engineering community opbouwt",
    "training_ad_5": "Inzicht in de essentiële platform producten",
    "training_rel_blogs": "Related blogs"
  }
}
</i18n>

<script>

const lang = {
  "d":'d'
}

export default {
  name: 'TrainingPlatformTeams'
};
</script>
