<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
  <section class="page-section" id="patterns">
    <div class="container" v-if="patternList!=null">
      <div class="text-left">
        <h2 class="section-heading text-uppercase">{{ t("title") }}</h2>
        <h3 class="section-subheading text-muted">{{ t("subtitle") }}</h3>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div v-if="Object.keys(patternListGrouped).length>0">
              <div class="col-lg-12"
                   v-for="category in getSortedGroupedKeys()" v-bind:key="category">
                <h3 class="col-lg-12 ml-0" style="text-transform:capitalize;">
                  {{ category }}
                </h3>
                <ul>
                  <li v-for="pattern in patternListGrouped[category]"
                      v-bind:key="pattern.id"
                      class="mt-2">
                    <router-link :to="{path:'/patterns/details/'+pattern.id}" class="ml-5 text-dark">

                      {{ pattern.title }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Patterns & best practises",
    "subtitle": "In the past fifteen years we collected a wide range of patterns, and best-practices. We published a sub-set of them on our website, and are happy to highlight them for you in a meeting or workshop. We also offer to scan your organization and tailor improvements for you."
  },
  "nl": {
    "title": "Patronen & best practises",
    "subtitle": "In de afgelopen vijftien jaar hebben we een breed scala aan patronen en best-practices verzameld. We hebben er een deel van op onze website gepubliceerd en lichten ze graag toe in een gesprek of workshop."
  }
}
</i18n>

<script>
import {PATTERNS} from "@/components/pattern/patterns";

export default {

  name: 'pattern-details',
  data: () => {
    return {
      patternListGrouped: null,
      patternList: []
    }
  },
  methods: {
    getSortedGroupedKeys() {
      return Object.keys(this.patternListGrouped).sort();
    }
  },
  created: function () {
    this.patternList = PATTERNS.patterns;
    this.patternListGrouped = this.patternList.reduce(function (acc, pattern) {
      if (acc[pattern.category[0]]) {
        acc[pattern.category[0]].push(pattern);
      } else {
        acc[pattern.category[0]] = [pattern];
      }
      return acc;
    }, {});
  },
};
</script>
