<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
  <section class="page-section" id="blogs">
    <div class="container">
      <div class="text-left">
        <h2 class="section-heading text-uppercase">Blogs</h2>
        <h3 class="section-subheading text-muted">{{ t("subtitle")}}</h3>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row" v-if="blogList">
            <div class="col-lg-6 blog-wrapper" v-for="blog in blogList" v-bind:key="blog.title">
              <div class="blog">
                <div class="row">
                  <div class="col-lg-12">
                    <h1 class="title">{{ blog.title }}</h1>
                  </div>
                </div>
                <div class="row text-muted blog-description">
                  <div class="col-lg-12">
                      <p v-for="description in  blog.descriptions" v-bind:key="description">
                        {{ description }}
                      </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div>
                      <span class="tag-pill text-uppercase" v-for="category in blog.categories" v-bind:key="category">{{ category }}</span>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 text-end">
                  <div class="col-lg-12   ">
                    <div>
                      <a :href="blog.link" target="_blank">
                        <input type="button" :value="t('READ_MORE') " class="btn btn-primary read"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n lang="json">
{
  "en": {
    "subtitle": "Here you will find the blogs we regularly write about topics that we think are worth sharing with you."
  },
  "nl": {
    "subtitle": "Hier vindt u de blogs die we regelmatig schrijven over onderwerpen die we de moeite vinden om met u te delen."
  }
}
</i18n>

<script>
import {BLOGS} from "@/components/blogs/blogs";

export default {
  name: 'blog-list',
  data: () => {
    return {
      blogList: []
    }
  },
  created: function () {
    this.blogList = BLOGS.BLOGS;
  },
};
</script>
<style scoped>
.blog-wrapper {
  padding: 10px;
}

.blog {
  padding: 20px;
  /*border: 1px solid #5c636a;*/
  border-radius: .5rem;
  height: 100%;
  webkit-box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07) !important;
  box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07) !important;

}
.title{
  font-size: 1.2em;
  height: 15px;
}

.blog-description {
  padding-top: 3rem;
  min-height: 200px;
}

.tag-pill {
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #b4bbc1;
  border-radius: 10rem;
  line-height: 1;
  color: white;

}

.tag-pill:last-child {
  margin-right: 0px;
}

</style>