<template>

  <!-- Footer-->
  <footer class="footer py-4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 text-lg-left">Copyright &copy; Software in Rhythm</div>
        <div class="col-lg-4 my-3 my-lg-0">
          <vue-cookie-comply
              :preferences="preferences"
              @on-accept-all-cookies="onAccept"
              @on-save-cookie-preferences="onAccept"
          >
            <template v-slot:modal-header>
              <h3>Cookie preferences</h3>
            </template>
          </vue-cookie-comply>
          <span class="cookie-modal"></span>
        </div>
        <div class="col-lg-4 text-lg-right">
          <a class="btn btn-dark btn-social mx-2" v-on:click="linkedin()" target="_blanc">
            <font-awesome-icon :icon="['fab','linkedin-in']"/>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import {useState} from "vue-gtag-next";

export default {
  name: 'AppFooter',
  data: function () {
    return {
      preferences: [
        {
          title: 'Analytical cookies',
          description: 'Please give us consent to track analytics. we will use this to validate and improve our content.',
          items: [{label: 'Google Analytics', value: 'analytics', isRequired: false, isEnabled: true}],
        },
      ],
    }
  },
  mounted() {
    // 2. The DOM is ready to go now
    this.initialize();

  },
  methods: {
    initialize() {
      const cookieSetting = localStorage.getItem("cookie-comply");
      if (cookieSetting === 'all' || (Array.isArray(JSON.parse(cookieSetting)) && JSON.parse(cookieSetting).includes('analytics'))) {
        this.useGoogleAnalytics()
      }
    },
    linkedin() {
      this.$gtag.event('external-link', {url: 'https://www.linkedin.com/company/software-in-rhythm'})
      const a = document.createElement('a');
      a.target = "_blank";
      a.href = "https://www.linkedin.com/company/software-in-rhythm";
      a.click();
    },
    onAccept() {
      this.initialize();
    },
    useGoogleAnalytics() {
      const {property} = useState();
      property.value = {id: "G-XTPBWG5EJ4"};
    }
  }
};
</script>
<style>
.cookie-comply__button {
  padding: 12px 32px;
  border: 1px solid #333;
  background-color: var(--color-white);
  font-weight: 600;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer
}

.cookie-comply-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px
}

.cookie-comply-switch input {
  opacity: 0;
  width: 0;
  height: 0
}

.cookie-comply-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s
}

.cookie-comply-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--color-white);
  -webkit-transition: .4s;
  transition: .4s
}

input:checked + .cookie-comply-slider {
  background-color: #17a2b8
}

input:focus + .cookie-comply-slider {
  box-shadow: 0 0 1px #17a2b8
}

input:checked + .cookie-comply-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translate(26px)
}

.cookie-comply-slider.cookie-comply-round {
  border-radius: 34px
}

.cookie-comply-slider.cookie-comply-round:before {
  border-radius: 50%
}

.cookie-comply-required {
  cursor: not-allowed
}

input:checked + .cookie-comply-required.cookie-comply-slider {
  background-color: #ccc
}

.cookie-comply__modal {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--background-overlay)
}

.cookie-comply__modal-middle {
  display: table-cell;
  vertical-align: middle
}

.cookie-comply__modal-inner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  padding: var(--spacing-lg);
  background-color: var(--color-white);
  border-radius: var(--spacing-sm);
  box-shadow: var(--box-shadow)
}

.cookie-comply__back-arrow {
  position: absolute;
  left: var(--spacing-lg);
  cursor: pointer
}

.cookie-comply__modal-content {
  overflow-y: scroll;
  max-height: 400px;
  text-align: left
}

.cookie-comply__modal-content h2 {
  font-size: 1.2rem;
}

.cookie-comply__modal-content h3 {
  font-size: 1.1rem;
}

.cookie-comply__modal-switches {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--border-color-lightest);
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  .cookie-comply__modal-middle {
    padding: var(--spacing-md)
  }

  .cookie-comply__modal-inner {
    width: auto
  }
}

.cookie-comply__modal-header {
  border-bottom: var(--border-color-light);
  padding-top: var(--spacing-lg);
}

.cookie-comply__modal-footer {
  border-top: var(--border-color-light);
  padding-top: var(--spacing-lg)
}

:root {
  --spacing-sm: 8px;
  --spacing-md: 14px;
  --spacing-lg: 20px;
  --color-green: #17a2b8;
  --color-white: #ffffff;
  --box-shadow: 0 1px 6px 1px rgb(0 0 0 / 10%), 0 1px 7px 1px rgb(0 0 0 / 6%);
  --border-radius: 8px;
  --background-overlay: rgba(34, 34, 34, .3);
  --border-color-lightest: 1px solid rgba(200, 200, 200, .2);
  --border-color-light: 1px solid rgba(0, 0, 0, .1)
}

.cookie-comply {
  position: fixed;
  display: grid;
  grid-gap: var(--spacing-lg);
  grid-template-columns:1fr minmax(35%, 40%);
  //position: absolute;
  bottom: 0%;
  left: var(--spacing-sm);
  right: var(--spacing-sm);
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-md);
  border-radius: var(--border-radius)
}

@media (max-width: 1024px) {
  .cookie-comply {
    grid-template-columns:none
  }
}

.cookie-comply__header {
  justify-self: flex-start;
  text-align: initial
}

.cookie-comply__header-title, .cookie-comply__header-description {
  margin: 0
}

.cookie-comply__header-title {
  margin-bottom: var(--spacing-sm)
}

.cookie-comply__header-description {
  line-height: 20px
}

.cookie-comply__actions {
  display: grid;
  grid-gap: var(--spacing-lg);
  grid-template-columns:repeat(2, 1fr);
  align-self: center
}

@media (max-width: 480px) {
  .cookie-comply__header {
    margin-bottom: var(--spacing-sm)
  }

  .cookie-comply__actions {
    grid-template-columns:auto
  }
}

.cookie-comply__button-accept {
  background-color: var(--color-green);
  color: var(--color-white);
  border: none
}
</style>

<style scoped>

.footer {
  text-align: center;
  font-size: 0.9rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}
</style>