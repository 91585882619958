<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top" :class="navigationScrollClass" id="mainNav">
    <div class="container">
      <a class="navbar-brand" href="/"><img src="/assets/img/sir-logo.svg" alt="Logo"/></a>
      <button class="d-xl-none d-l-none navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
              data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
              aria-label="Toggle navigation" v-on:click="toggle()">
        {{ t("menu") }}
        <i class="fas fa-bars ms-1"></i>
      </button>
      <div :class="collapseMenuClass+ ' collapse navbar-collapse text-sm-end'" id="navbarResponsive">
        <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
          <li class="nav-item">
            <router-link :to="{path:'/', hash:'#proposition'}" class="nav-link" v-on:click="navigateToLink">{{ t("our_proposition") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name:'leadership-partnership'}" class="nav-link" v-on:click="navigateToLink">{{ t("leadership-partnership") }}</router-link>
          </li>
<!--          <li class="nav-item">-->
<!--            <router-link :to="{name:'services'}" class="nav-link" v-on:click="navigateToLink">{{ t("our_services") }}</router-link>-->
<!--          </li>-->
          <li class="nav-item">
            <router-link :to="{name:'training'}" class="nav-link" v-on:click="navigateToLink">{{ t("our_trainings") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name:'blogs'}" class="nav-link" v-on:click="navigateToLink">{{ t("blogs") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'about-us' }" class="nav-link" v-on:click="navigateToLink">{{ t("about_us") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{path:'/', hash:'#contact'}" class="nav-link" v-on:click="navigateToLink">{{ t("contact") }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>

</template>

<i18n lang="json">
{
  "en": {
    "menu": "Menu",
    "our_proposition": "What we do",
    "leadership-partnership": "IT Leadership Partnership",
    "our_services": "Our services",
    "our_trainings": "Our trainings",
    "blogs": "Blogs",
    "about_us": "About us",
    "contact": "Contact"
  },
  "nl": {
    "menu": "Menu",
    "our_proposition": "Onze missie",
    "leadership-partnership": "IT Leadership Partnership",
    "our_services": "Onze services",
    "our_trainings": "Onze trainingen",
    "blogs": "Blogs",
    "about_us": "Over ons",
    "contact": "Contact"

  }
}
</i18n>

<script>
export default {
  name: "AppNavigation",
  data: function () {
    return {
      navigationScrollClass: "",
      menuCollapse: "",
      collapseMenuClass: "",
      collapsed: true
    }
  },
  methods: {
    toggle: function () {
      this.collapsed = !this.collapsed;
      if (!this.collapsed) {
        this.collapseMenuClass = "d-block"
      } else {
        this.collapseMenuClass = ""
      }
    },
    navigateToLink: function(){
      this.collapsed = true;
      this.collapseMenuClass = "";
    },
    onScroll: function () {
      if (window.pageYOffset > 170) {
        this.navigationScrollClass = 'navbar-shrink';
      } else {
        this.navigationScrollClass = '';
      }
    }
  },
  created() {
    document.addEventListener('scroll', this.onScroll);
  },
  destroyed() {
    document.removeEventListener('scroll', this.onScroll);
  }
};
</script>
<style scoped>

#mainNav {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #212529;
}
#mainNav .navbar-toggler {
  padding: 0.75rem;
  font-size: 0.75rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
  font-weight: 700;
}
#mainNav .navbar-brand {
  color: #ffc800;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
}
#mainNav .navbar-brand img {
  height: 2.5rem;
}
#mainNav .navbar-nav .nav-item .nav-link {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.95rem;
  color: #fff;
  letter-spacing: 0.0625em;
}
#mainNav .navbar-nav .nav-item .nav-link.active, #mainNav .navbar-nav .nav-item .nav-link:hover {
  color: #0088aa;
}

@media (min-width: 992px) {
  #mainNav {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border: none;
    background-color: transparent;
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }
  #mainNav .navbar-brand {
    font-size: 1.5em;
    transition: font-size 0.3s ease-in-out;
  }
  #mainNav .navbar-brand img {
    height: 5rem;
    transition: height 0.3s ease-in-out;
  }
  #mainNav.navbar-shrink {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #212529;
  }
  #mainNav.navbar-shrink .navbar-brand {
    font-size: 1.25em;
  }
  #mainNav.navbar-shrink .navbar-brand svg,
  #mainNav.navbar-shrink .navbar-brand img {
    height: 2.5rem;
  }
  #mainNav .navbar-nav .nav-item {
    margin-right: 1rem;
  }
  #mainNav .navbar-nav .nav-item:last-child {
    margin-right: 0;
  }
}

</style>