export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practical Product Owner course"])},
        "training_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Practical Product Owner course is set up in a modulair fashion and according to the TED (Theory, Experience and Deliver) principle. This means that every session follows the same pattern:"])},
        "training_p1_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The theory and essence of a subject is explained"])},
        "training_p1_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The subject will be hands on and in depth experienced"])},
        "training_p1_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The exercise results in a ready and directly usable artefact"])},
        "training_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sequence of the sessions will be determined by the direct needs of the organisation. If the need for improvement is the highest in the delivery process we will start there and work our way towards the strategy. In other cases we start with the why and work our way towards end user feedback:"])},
        "training_p3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content of the course"])},
        "training_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The course is designed to be 7 group sessions with hands-on coaching in between these sessions. The level and intensity of coaching depends on factors like group size, complexity, level of both the organisation as product and experience of participants. The sessions are logically grouped together based on certain phases in the whole system from strategy to usage of the products:"])},
        "training_p4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives phase"])},
        "training_p5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 1: Strategy"])},
        "training_p5_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systems thinking"])},
        "training_p5_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategy"])},
        "training_p5_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business case – Business model canvas/Lean canvas"])},
        "training_p6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 2: Product high level "])},
        "training_p6_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Vision (Describe the essence of your product: why, for who, what and how)"])},
        "training_p6_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personas"])},
        "training_p6_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stakeholder management/mapping"])},
        "training_p7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input phase"])},
        "training_p8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 3: Product specific"])},
        "training_p8_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact mapping (set business goals and the way and means to achieve these goals)"])},
        "training_p8_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event storming (create a collective understanding with business, IT and stakeholder)"])},
        "training_p9_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 4: High level planning"])},
        "training_p9_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritisation methods"])},
        "training_p9_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadmapping"])},
        "training_p9_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Story mapping"])},
        "training_p9_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependency mapping"])},
        "training_p10_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output phase"])},
        "training_p11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 5: Planning to sprint"])},
        "training_p11_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epic goals"])},
        "training_p11_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User flows"])},
        "training_p11_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI/UX"])},
        "training_p11_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datamodel"])},
        "training_p11_lo5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance criteria"])},
        "training_p12_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 6: Technical practises explained for non-tech people"])},
        "training_p12_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CI/CD"])},
        "training_p12_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automated testing"])},
        "training_p12_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ITSM & Agile & DevOps"])},
        "training_p13_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outcomes phase"])},
        "training_p14_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 7: Feedback"])},
        "training_p14_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gathering feedback"])},
        "training_p14_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required data to validate objectives"])},
        "training_p14_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different kinds of feedback"])},
        "training_p14_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback systems"])},
        "training_p15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that this workshop does not focus on Scrum processes. During the workshop we actually produce real artefacts that are the basis for your products and backlog."])},
        "training_p16_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the workshop"])},
        "training_p16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After this workshop participants will have a clear view on the full scope of Product Ownership which is much broader than merely Scrum or the backlog. Participants will know about, and have practical experience with, numerous practical tools and activities which they can use right away. Participants will have knowledge on the toolset to organise and structure the flow from idea to customer feedback."])},
        "training_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a training"])},
        "training_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training options"])},
        "training_option_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language: Dutch/English"])},
        "training_option_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration: 7 sessions"])},
        "training_option_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location: Onsite / hosted venue"])},
        "training_advantage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training advantages"])},
        "training_ad_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A clear view on the full scope of Product Ownership"])},
        "training_ad_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants will know about, and have practical experience with, numerous practical tools and activities which they can use right away."])},
        "training_ad_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants will have knowledge on the toolset to organise and structure the flow from idea to customer feedback."])},
        "training_rel_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related blogs"])}
      },
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practische Product Owner leergang"])},
        "training_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Praktische Product Owner leergang is modulair en volgens het TED-principe (Theory, Experience and Deliver) opgezet. Dit betekent dat elke sessie hetzelfde patroon volgt:"])},
        "training_p1_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De theorie en essentie van een onderwerp wordt uitgelegd"])},
        "training_p1_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het onderwerp wordt hands-on en op basis van echte cases ervaren"])},
        "training_p1_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De oefening resulteert in een kant-en-klaar uitgewerkt artefact"])},
        "training_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De volgorde van de sessies wordt bepaald door de directe behoeften van de organisatie. Als de behoefte aan verbetering het hoogst is in het delivery proces, dan beginnen we daar en naar de strategie toe werken. In andere gevallen beginnen we juist met het waarom en werken we toe naar feedback van eindgebruikers:"])},
        "training_p3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhoud van de leergang"])},
        "training_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De cursus is ontworpen als 7 sessies met hands-on coaching in de periodes tussen deze sessies. Het niveau en de intensiteit van de coaching is afhankelijk van factoren als groepsgrootte, complexiteit, niveau van zowel de organisatie als het product en de ervaring van deelnemers. Dit alles in goed overleg. De sessies zijn logisch gegroepeerd op basis van bepaalde fasen in het hele systeem van strategie tot gebruik van de producten:"])},
        "training_p4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelstellingen fase"])},
        "training_p5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 1: Strategie"])},
        "training_p5_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systeemdenken"])},
        "training_p5_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategie en strategische doelen"])},
        "training_p5_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business case – Business model canvas/Lean canvas"])},
        "training_p6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 2: Product high level"])},
        "training_p6_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Visie (Beschrijf de essentie van uw product: waarom, voor wie, wat en hoe)"])},
        "training_p6_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona's"])},
        "training_p6_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stakeholder management/mapping"])},
        "training_p7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input fase"])},
        "training_p8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 3: Productspecifiek"])},
        "training_p8_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact Mapping (bedrijfsdoelen en de manier en middelen om deze doelen te bereiken)"])},
        "training_p8_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event storming (creëer een collectief begrip met business, IT en belanghebbenden)"])},
        "training_p9_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 4: High level planning"])},
        "training_p9_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioriteringmethoden"])},
        "training_p9_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadmapping"])},
        "training_p9_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Story mapping"])},
        "training_p9_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependency mapping"])},
        "training_p10_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output phase"])},
        "training_p11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 5: Roadmap to sprint"])},
        "training_p11_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epic goals"])},
        "training_p11_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User flows"])},
        "training_p11_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI/UX"])},
        "training_p11_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datamodel"])},
        "training_p11_lo5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptancie criteria"])},
        "training_p12_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technische praktijken uitgelegd voor non-techies"])},
        "training_p12_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CI/CD"])},
        "training_p12_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geautomatiseerd testen"])},
        "training_p12_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ITSM & Agile & DevOps"])},
        "training_p13_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten fase"])},
        "training_p14_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session 7: Feedback"])},
        "training_p14_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzamelen van feedback"])},
        "training_p14_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benodigde data om doelstellingen te valideren"])},
        "training_p14_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschillende soorten feedback"])},
        "training_p14_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback systemen"])},
        "training_p15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op: deze workshop is niet gericht op Scrum processen. Tijdens de workshop maken we daadwerkelijk echte artefacten die de basis vormen voor uw producten en backlog."])},
        "training_p16_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na de workshop"])},
        "training_p16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na deze workshop hebben de deelnemers een duidelijk zicht op de volledige scope van Product Ownership die veel breder is dan alleen Scrum of de backlog. Deelnemers kennen, en hebben praktische ervaring met tal van tools en activiteiten waar ze direct mee aan de slag kunnen in hun dagelijks werk. Deelnemers hebben kennis van de toolset om de stroom van idee naar klantfeedback te organiseren en te structureren."])},
        "training_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a training"])},
        "training_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training opties"])},
        "training_option_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal: Nederlands/Engels"])},
        "training_option_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration: 7 sessions"])},
        "training_option_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie: op locatie / hosted venue"])},
        "training_advantage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training voordelen"])},
        "training_ad_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biedt duidelijk zicht op de volledige scope van Product Ownership"])},
        "training_ad_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deelnemers krijgen praktische ervaring met tal van tools en activiteiten waar ze direct mee aan de slag kunnen in hun dagelijks werk. "])},
        "training_ad_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deelnemers kennen de toolset om de stroom van idee naar klantfeedback te organiseren en te structureren."])},
        "training_rel_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerelateerde blogs"])}
      }
    }
  })
}
