export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running platform teams"])},
        "training_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximizing the delivery of business value is only possible when supporting platform and sub-system teams also strive to reach the same goals as the (customer-facing feature) teams. While having different end-users and stakeholders, the purpose of all teams must be to serve the company's strategy and business goals."])},
        "training_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In practice, there often seems to be an entirely different approach in how different teams serve their end-users. Feature teams tend to be more end-user focussed, as a result of the agile way of working, but the operational or supporting teams have a hard time connecting to this way of thinking because they 'don't deliver any value to their customers'."])},
        "training_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The perception of business value is just different. While not serving end-users directly an internal platform team has a really big impact on the services that are used by end-users."])},
        "training_p4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform teams are product teams"])},
        "training_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This workshop is designed to align the platform strategy and product vision with the ones from the company and its products. The workshop will provide insights and practical methods on how to set up and run in-company platform teams."])},
        "training_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During this workshop we will regard platforms and platform teams from different angles. After the workshop participants will know all about:"])},
        "training_p6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platforms are products"])},
        "training_p6_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product vision and strategy"])},
        "training_p6_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowing your customer and their needs"])},
        "training_p7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform teams are product teams"])},
        "training_p7_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to grow from inward facing to outgoing platform teams"])},
        "training_p7_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stakeholder management and communication"])},
        "training_p7_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personas"])},
        "training_p8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not only tools"])},
        "training_p8_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform teams as driver of the DevOps maturity"])},
        "training_p8_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform team Center of Excellence (CoE)"])},
        "training_p8_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building communities"])},
        "training_p8_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inner source models"])},
        "training_p9_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When is a platform a good idea"])},
        "training_p9_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the distinctiveness first"])},
        "training_p9_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A platform is not the goal"])},
        "training_p10_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform products essentials"])},
        "training_p10_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-service products"])},
        "training_p10_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration as code"])},
        "training_p10_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring and logging"])},
        "training_p10_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
        "training_p11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to"])},
        "training_p11_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform team roles"])},
        "training_p11_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to sell"])},
        "training_p11_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to measure success"])},
        "training_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a training"])},
        "training_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training options"])},
        "training_option_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language: Dutch/English"])},
        "training_option_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration: 1 day"])},
        "training_option_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location: Onsite / hosted venue"])},
        "training_advantage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training advantages"])},
        "training_ad_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Being able actively contribute to a general strategy through a platform"])},
        "training_ad_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management of platform teams as product teams"])},
        "training_ad_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know how set-up a Center of Excellence"])},
        "training_ad_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know how to build a community of users"])},
        "training_ad_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have insight in the platform products essentials"])},
        "training_rel_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related blogs"])}
      },
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running platform teams"])},
        "training_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het maximaliseren van de levering van business value is alleen mogelijk wanneer de platform teams dezelfde doelen nastreven als de feature teams. Ze hebben enkel verschillende eindgebruikers en belanghebbenden, maar hun doel moet zijn om de strategie en doelen van het bedrijf te dienen."])},
        "training_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er lijkt vaak een heel andere benadering te zijn in de manier waarop verschillende teams hun eindgebruikers bedienen. Feature teams zijn doorgaans meer gericht op de eindgebruiker, als gevolg van de agile manier van werken, maar de operationele of ondersteunende teams hebben moeite om aan te sluiten bij deze manier van denken omdat ze 'geen waarde leveren aan hun klanten'."])},
        "training_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De perceptie van business value is gewoon anders. Hoewel een intern platform team eindgebruikers niet rechtstreeks bedient, heeft het een grote impact op de services die door eindgebruikers worden gebruikt."])},
        "training_p4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform teams are product teams"])},
        "training_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze workshop is bedoeld om de platform strategie en visie af te stemmen op die van het bedrijf en haar producten. De workshop biedt inzichten en praktische methoden voor het opzetten en runnen van in-company platform teams. Niet vanuit een technisch perspectief, maar om juist actief bij te dragen aan het maximaliseren van de business value die het bedrijf levert."])},
        "training_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdens deze workshop zullen we ons vanuit verschillende invalshoeken richten op platforms en platform teams. Na de workshop weten de deelnemers alles over het volgende:"])},
        "training_p6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platforms zijn producten"])},
        "training_p6_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product visie en -strategie"])},
        "training_p6_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klanten en hun behoeften kennen"])},
        "training_p7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform Teams zijn product teams"])},
        "training_p7_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe te groeien van een naar binnen gericht naar een uitgaand servicegericht platform team"])},
        "training_p7_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stakeholder management and communicatie"])},
        "training_p7_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona's"])},
        "training_p8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet alleen tools"])},
        "training_p8_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform Teams als aanjager van de DevOps-volwassenheid"])},
        "training_p8_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform team Center of Excellence (CoE)"])},
        "training_p8_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communities bouwen"])},
        "training_p8_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inner source-modellen"])},
        "training_p9_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer is een platform een ​​goed idee"])},
        "training_p9_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vind het onderscheidend vermogen"])},
        "training_p9_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een platform is niet het doel"])},
        "training_p10_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essentiële platform producten"])},
        "training_p10_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-service producten"])},
        "training_p10_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration as code"])},
        "training_p10_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring en logging"])},
        "training_p10_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
        "training_p11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to"])},
        "training_p11_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform team rollen"])},
        "training_p11_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe te verkopen"])},
        "training_p11_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe succes te meten"])},
        "training_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a training"])},
        "training_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training options"])},
        "training_option_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language: Nederland/Engels"])},
        "training_option_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration: 1 dag"])},
        "training_option_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location: Onsite / hosted venue"])},
        "training_advantage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training voordelen"])},
        "training_ad_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief kunnen bijdragen aan de algemene strategie dmv een platform"])},
        "training_ad_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sturing van platform teams als product teams"])},
        "training_ad_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weten hoe een Centre of Excellence op te zetten"])},
        "training_ad_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet hoe u een engineering community opbouwt"])},
        "training_ad_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inzicht in de essentiële platform producten"])},
        "training_rel_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related blogs"])}
      }
    }
  })
}
