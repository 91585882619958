export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
        "our_proposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we do"])},
        "leadership-partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Leadership Partnership"])},
        "our_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our services"])},
        "our_trainings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our trainings"])},
        "blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogs"])},
        "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
      },
      "nl": {
        "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
        "our_proposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze missie"])},
        "leadership-partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Leadership Partnership"])},
        "our_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze services"])},
        "our_trainings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze trainingen"])},
        "blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogs"])},
        "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over ons"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
      }
    }
  })
}
