import script from "./poster-header-small.vue?vue&type=script&setup=true&lang=js"
export * from "./poster-header-small.vue?vue&type=script&setup=true&lang=js"

import "./poster-header-small.vue?vue&type=style&index=0&id=6fbf6bc4&lang=css"
/* custom blocks */
import block0 from "./poster-header-small.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__