<template>
  <div>
    <poster_header></poster_header>
    <training_productowner></training_productowner>
  </div>
</template>
<script>
import postHeader from "@/components/poster-header-small";
import training_productowner from "@/components/training/training-product-owner";
export default {
  name: 'po-training-training-page',
  components: {
    poster_header: postHeader,
    training_productowner: training_productowner
  },
  metaInfo: {
    title: 'Platform owner training | Software in Rhythm',
  }
};
</script>