export const BLOGS = {
    "BLOGS": [
        {
            "id": "15",
            "title": "Value driven product thinking\n",
            "categories": ["IT management", "organization", "DevOps"],
            "descriptions": [
                "Product leadership is not so much about progress on features, but whether changes have the desired effect on achieving business objectives."
            ],
            link:"https://nielstalens.nl/2023/01/18/value-driven-product-thinking/"
        },
        {
            "id": "14",
            "title": "Domain-Driven Design for IT managers",
            "categories": ["IT management", "architecture", "DevOps"],
            "descriptions": [
                "Guidance for engineering managers and other tech leaders on domain-driven design and how to deal with implementing it in your organization."
            ],
            link:"https://medium.com/codex/domain-driven-design-for-it-managers-285d92e723c1"
        },
        {
            "id": "14",
            "title": "The monkey trap",
            "categories": ["IT management", "organization", "DevOps"],
            "descriptions": [
                "We seem to have a habit of creating many features and holding on to them. We either hold on to them because or we simply think it would be wasteful to remove them since it took money and effort to create them in the first place. "
            ],
            link:"https://medium.com/codex/domain-driven-design-for-it-managers-285d92e723c1"
        } ,
        {
            "id": "12",
            "title": "How to UNBURDEN software teams",
            "categories": ["architecture", "DevOps", "cloud"],
            "descriptions": [
                "Read how to unburden modern cloud-native software development teams. We should aim for a more holistic perspective when we make IT decisions."
            ],
            link:"https://kevin-van-ingen.medium.com/how-to-unburden-software-teams-1b4aecb6bd18"
        },{
            "id": "11",
            "title": "Domain-driven design (DDD) or just domain-oriented design (DOD)?",
            "categories": ["architecture", "DevOps", "cloud"],
            "descriptions": [
                "DDD on a minimalist approach, taking most benefits for your microservice landscape. I found a more lightweight approach to your software project is almost universally beneficial."
            ],
            link:"https://medium.com/codex/domain-driven-design-ddd-or-just-domain-oriented-design-dod-9098abecd456"
        },
        {
            "id": "10",
            "title": "Leveling up your software delivery capabilities",
            "categories": ["organization", "DevOps", "IT management"],
            "descriptions": [
                "Migrating to the cloud with an immature delivery capability only gets you half-way and zero benefits. Read the blog to get to the real deal."
            ],
            link:"https://medium.com/codex/leveling-up-your-software-delivery-capabilities-part-1-60e5c79ec676"
        },
        {
            "id": "9",
            "title": "Platform teams as driving force of improvement",
            "categories": ["organization","teams", "DevOps", "platform teams", "IT management"],
            "descriptions": [
                "When aligned properly, a platform and the quality of the company's agile and devops levels go hand in hand. Platform teams can be the driving force behind continuous improvement."
            ],
            link:"https://www.linkedin.com/pulse/platform-teams-driving-force-improvement-niels-talens"
        },
        {
            "id": "8",
            "title": "Practical shopkeeping tools for IT Management",
            "categories": ["process","DevOps", "agile", "IT management"],
            "descriptions": [
                "As IT manager, to be able to deliver what & how your company needs, your shop must be in order. Luckily there are a couple of tools, tips and tricks which can help you do just that!"
            ],
            link:"https://www.linkedin.com/pulse/practical-shopkeeping-tools-management-niels-talens"
        },
        {
            "id": "7",
            "title": "The product owner retrospective",
            "categories": ["process","teams", "agile"],
            "descriptions": [
                "The product owner is one of the most elusive roles known to the software creation process. The tool described below can be used as a self-reflection tool. However, I would recommend performing this in a retrospective / intervision session with fellow product owners, or within your agile team."
            ],
            link:"https://www.linkedin.com/pulse/product-owner-retrospective-software-in-rhythm/"
        },
        {
            "id": "6",
            "title": "Why DevOps?",
            "categories": ["organization","teams", "DevOps"],
            "descriptions": [
                "There can be many reasons to organise as a DevOps organisation but we often mis an explicit reasoning of the why, who will benefit from it and in what way. A strong emphasis on innovation has a different implementation strategy than if the primary need is to improve stability."
            ],
            link:"https://www.linkedin.com/pulse/why-devops-software-in-rhythm/"
        },
        {
            "id": "5",
            "title": "ITSM or DevOps? You don't have to choose!",
            "categories": ["organization","teams", "DevOps", "itsm"],
            "descriptions": [
                "In essence ITSM, Agile and DevOps absolutely strive for the same thing: optimise the delivery of value for your customers and your organisation. Who could argue with that statement?"
            ],
            link:"https://www.linkedin.com/pulse/itsm-devops-you-dont-have-choose-niels-talens/"
        },
        {
            "id": "4",
            "title": "Platform teams are product teams",
            "categories": ["organization","teams", "DevOps", "platform teams"],
            "descriptions": [
                "Supporting platform teams are not perceived as agile, sexy and cool as feature teams. Without aligning their purpose with feature teams, the delivery of business value is heavily limited."
            ],
            link:"https://www.linkedin.com/pulse/platform-teams-product-software-in-rhythm"
        },
        {
            "id": "3",
            "title": "Create synergy in platform teams!",
            "categories": ["organization","teams", "platform teams"],
            "descriptions": [
                "Platform teams are commonly created to provide a single place for fixing common problems. However, I want to stress that we need to go the extra mile to not only move work to platform teams but create more synergy using platform teams.  "
            ],
            link:"https://www.linkedin.com/pulse/create-synergy-platform-teams-software-in-rhythm"
        },
        {
            "id": "2",
            "title": "Embed eventstorming in your agile toolset.",
            "categories": ["process","agile"],
            "descriptions": [
                "Read how to combine DDD concepts in an eventstorming workshops with storymapping to create a product backlog. " +
                "Event Storming has caught me by surprise in the way how it boosts a project’s startup and in the way the business can connect to it.",
            ],
            link:"https://kevin-van-ingen.medium.com/mix-event-storming-in-your-agile-toolset-437a54939aeb"
        },
        {
            "id": "1",
            "title": "Practical guide for improving software delivery in DevOps – part 1",
            "categories": ["DevOps"],
            "descriptions": [
                "In regards to the quality of both software products as devops processes there are four main metrics that can be considered to be leading. " +
                "Mean Lead Time for changes (MLT), Mean Time To Recover (MTTR), Deployment Frequency (DF), Change Failure Rate (CFR) ." ,
            ],
            link:"https://nielstalens.nl/2021/05/14/practical-guide-for-improving-software-delivery-in-devops-part-1/"
        },
    ]
}