<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
  <section class="page-section" id="offering">
    <div class="container">
      <div class="text-left">
        <h2 class="section-heading text-uppercase">{{ t("title") }}</h2>
        <h3 class="section-subheading text-muted justified">{{ t("subtitle") }}</h3>
      </div>
      <div class="row my-5" id="portfolio">

      <div class="row text-left">
        <div class="col-lg-4 col-sm-6 mb-4">
          <span class="fa-3x">
             <font-awesome-icon icon="cloud" class="iconcolor" />
          </span>
          <h4 class="my-3">{{ t("sub1_title")}}</h4>
          <p class="text-muted justified">{{ t("sub1_desc")}}</p>
          <ul class="text-muted">
            <li>{{ t("sub1_desc2[0]") }}</li>
            <li>{{ t("sub1_desc2[1]") }}</li>
            <li>{{ t("sub1_desc2[2]") }}</li>
            <li>{{ t("sub1_desc2[3]") }}</li>
          </ul>
      </div>
        <div class="col-lg-4 col-sm-6 mb-4">
          <span class="fa-3x">
             <font-awesome-icon icon="gauge-simple-high" class="iconcolor" />
          </span>
          <h4 class="my-3">{{ t("sub2_title")}}</h4>
          <p class="text-muted justified">{{ t("sub2_desc") }}</p>
          <ul class="text-muted">
            <li>{{ t("sub2_desc2[0]") }}</li>
            <li>{{ t("sub2_desc2[1]") }}</li>
            <li>{{ t("sub2_desc2[2]") }}</li>
            <li>{{ t("sub2_desc2[3]") }}</li>
            <li>{{ t("sub2_desc2[4]") }}</li>
          </ul>
        </div>
        <div class="col-lg-4 col-sm-6 mb-4">
          <span class="fa-3x">
             <font-awesome-icon icon="comments" class="iconcolor" />
          </span>
          <h4 class="my-3">{{ t("sub3_title") }}</h4>
          <p class="text-muted justified">{{ t("sub3_desc") }}</p>
          <ul class="text-muted">
            <li>{{ t("sub3_desc2[0]") }}</li>
            <li>{{ t("sub3_desc2[1]") }}</li>
            <li>{{ t("sub3_desc2[2]") }}</li>
            <li>{{ t("sub3_desc2[3]") }}</li>
            <li>{{ t("sub3_desc2[4]") }}</li>
            <li>{{ t("sub3_desc2[5]") }}</li>
          </ul>
        </div>
      </div>
      </div>

      <div class="row my-5">
        <h3 class="section-heading text-uppercase">{{ t("title2")}}</h3>
        <p class="text-muted justified">{{ t("subtitle2")}}</p>
      </div>
      <div class="row my-5" id="portfolio">
        <div class="col-lg-4 col-sm-6 mb-4">
          <div class="portfolio-item">
            <a class="portfolio-link" href="/#contact">
              <div class="portfolio-hover">
                <div class="portfolio-hover-content"><font-awesome-icon icon="envelope" size="3x"/></div>
              </div>
              <img class="img-fluid" src="assets/img/proposition/proposition-round-table.jpeg" alt=""/>
            </a>
            <div class="portfolio-caption">
              <div class="portfolio-caption-heading"> {{ t("sub4_title") }}</div>
            </div>
          </div>
          <p class="text-muted justified">{{ t("sub4_desc") }}</p>
        </div>
        <div class="col-lg-4 col-sm-6 mb-4">
          <div class="portfolio-item">
            <a class="portfolio-link" href="/#contact">
              <div class="portfolio-hover">
                <div class="portfolio-hover-content"><font-awesome-icon icon="envelope" size="3x"/></div>
              </div>
              <img class="img-fluid" src="assets/img/proposition/proposition-workshop.png" alt=""/>
            </a>
            <div class="portfolio-caption">
              <div class="portfolio-caption-heading">{{ t("sub5_title") }}</div>
            </div>
          </div>
          <p class="text-muted justified">{{ t("sub5_desc") }} </p>
          <p class="text-muted justified"> {{ t("sub5_desc2") }} <a href="/training">trainings</a>, <a href="/patterns">patterns</a> & <router-link :to="{name:'blogs-list'}" v-on:click="navigateToLink">blogs</router-link> {{ t("sub5_desc3") }} </p>
        </div>
        <div class="col-lg-4 col-sm-6 mb-4">
          <div class="portfolio-item">
            <a class="portfolio-link" href="/#contact">
              <div class="portfolio-hover">
                <div class="portfolio-hover-content"><font-awesome-icon icon="envelope" size="3x"/></div>
              </div>
              <img class="img-fluid" src="assets/img/proposition/proposition-boots.jpeg" alt=""/>
            </a>
            <div class="portfolio-caption">
              <div class="portfolio-caption-heading">{{ t("sub6_title") }}</div>
            </div>
          </div>
          <p class="text-muted justified">{{ t("sub6_desc") }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Our services",
    "subtitle": "Delivering the right product the right way at the right time is our core expertise. It takes a carefully structured organization and a holistic approach to be able to do so. Below are some of the subjects we helped, and are still helping, numerous customers with:",
    "sub1_title": "Becoming Cloud native",
    "sub1_desc": "Most organizations have tried cloud technology or even switched completely, however we notice that many organizations fail to get the benefits out of these transitions. We can help when:",
    "sub1_desc2": [
    "Delivery of new features is still slow",
    "The migration to the cloud takes (too) long",
    "Architecture got increasingly complex",
    "Hiring new employees is difficult, Skills are not matching the IT landscape"
    ],
    "sub2_title": "Platforms & platform teams",
    "sub2_desc": "Maximizing the delivery of business value is only possible when supporting platforms and sub-system teams also strive to reach the same goals as the (customer-facing feature) teams. We provide guidance, training and practical methods on how to set up and run in-company platform teams. We have extensive experience in:",
    "sub2_desc2": [
      "Building solid platform teams",
      "Creating platforms as products",
      "Creation of inner source and community driven Platforms",
      "Manage the culture and organizational transition",
      "Reviewing your team structure and organization, Training"
    ],
    "sub3_title": "DevOps transformation",
    "sub3_desc": "Getting the full benefits from a transformation to a DevOps organization is pretty complex. It touches upon all aspects of an organisation and their products, and is absolutely not only of interest to the IT department. When done right DevOps as an organizational structure can deliver enormous value to both the company as their customers. We know all about:",
    "sub3_desc2": [
    "Setting the desired outcomes DevOps should provide for",
    "Applying DevOps as organization model",
    "Training and hands-on consultancy",
    "Improving Continuous Integration and Delivery",
    "Building an engineering culture",
    "Aligning Domain models"
    ],
    "title2": "How we work",
    "subtitle2": "Different organizations require different tactics. We have formulated some of our common types of interactions with clients.",
    "sub4_title": "Management coaching",
    "sub4_desc": "Need a second pair of eyes, inspiration or a sparring partner? We know from experience that IT transformations are complicated and delicate. Every day it seems we need to make hundreds of irreversible decisions and still have to be on top of everything. We offer coaching based on an experience in IT transformations.",
    "sub5_title": "Meetups & trainings",
    "sub5_desc": "We are happy to do an on-site or online knowledge session for your organization or Meetup group. We can tailor our content completely to your needs but also offer a wide range of pre-made trainings.",
    "sub5_desc2": "Take a look at our ",
    "sub5_desc3": "to see what subjects suits your situation.",
    "sub6_title": "Consultancy",
    "sub6_desc": "Some situations require dedicated attention from experienced people. We are happy to help with digital transformation projects or transformations to a new way of working, using a strong hands-on approach."
  },
  "nl": {
    "title": "Onze diensten",
    "subtitle": "Het leveren van het juiste product op de juiste manier op het juiste moment is onze kernexpertise. Daarvoor is een zorgvuldig gestructureerde organisatie en een holistische benadering nodig. Hieronder vind je een aantal onderwerpen waarmee we talloze klanten hebben geholpen en nog steeds helpen:",
    "sub1_title": "Cloud native worden",
    "sub1_desc": "De meeste organisaties hebben cloud technologieën uitgeprobeerd of zijn zelfs helemaal overgestapt, maar we merken dat veel organisaties er niet in slagen om de voordelen uit deze transities te halen. We kunnen helpen wanneer:",
    "sub1_desc2": [
    "Levering van nieuwe features nog steeds te traag is",
    "De migratie naar de cloud (te) lang duurt, Architectuur steeds complexer wordt",
    "Nieuwe medewerkers aannemen moeilijk is",
    "Vaardigheden van de medewerkers niet passen bij het IT-landschap"
    ],
    "sub2_title": "Platformen & platform teams",
    "sub2_desc": "Het maximaliseren van de levering van bedrijfswaarde is alleen mogelijk als platforms en subsysteem teams er ook naar streven om dezelfde doelen te bereiken als de feature of end-to-end teams. Wij bieden begeleiding, training en praktische methodes voor het opzetten en runnen van in-company platform teams. We hebben uitgebreide ervaring in:",
    "sub2_desc2": [
    "Het creëren van solide platform teams",
    "Het creëren van platforms als producten",
    "Het creëren van inner-source community gestuurde platforms",
    "Management van de cultuur en organisatie transitie",
    "Herziening van uw teamstructuur en organisatie"
    ],
    "sub3_title": "DevOps-transformatie",
    "sub3_desc": "Het volledige voordeel halen uit een transformatie naar een DevOps-organisatie kan erg complex zijn. De verandering raakt alle aspecten van een organisatie en haar producten en is absoluut niet alleen van belang voor de IT-afdeling. Als de transformatie goed wordt gedaan, kan DevOps als organisatiestructuur enorme waarde opleveren voor zowel het bedrijf als hun klanten. We weten alles over:",
    "sub3_desc2": [
    "Het bepalen van de gewenste resultaten die DevOps moet bieden",
    "Het toepassen van DevOps als organisatiemodel",
    "Training en hands-on advies",
    "Bouwen aan een engineering cultuur",
    "Domeinmodellen op elkaar afstemmen en tweaken",
    "Verbeteren van Continuous Integration and Delivery"
    ],
    "title2": "Hoe we werken",
    "subtitle2": "Verschillende organisaties hebben verschillende tactieken nodig. We hebben enkele van onze meest voorkomende typen interacties met klanten beschreven",
    "sub4_title": "Management coaching",
    "sub4_desc": "Een tweede paar ogen, inspiratie of een sparringpartner nodig? Uit ervaring weten we dat IT-transformaties ingewikkeld en delicaat zijn. Elke dag lijkt het erop dat we honderden onomkeerbare beslissingen moeten nemen en toch overal bovenop moeten zitten. Wij bieden coaching die gebaseerd is op jarenlange ervaring in IT transformaties.",
    "sub5_title": "Meetups & trainingen",
    "sub5_desc": "Wij verzorgen graag een kennissessie op locatie of online voor uw organisatie of Meetup groep. We kunnen onze inhoud volledig op uw behoeften afstemmen, maar bieden ook een breed scala aan kant-en-klare trainingen.",
    "sub5_desc2": "Bekijk onze ",
    "sub5_desc3": " om te zien welke onderwerpen bij uw situatie passen.",
    "sub6_title": "Consultancy",
    "sub6_desc": "Sommige situaties vragen om toegewijde aandacht van ervaren mensen. Wij helpen graag bij digitale transformatie projecten of transformaties naar een nieuwe manier van werken waarbij een sterke hands-on aanpak hanteren."
  }
}
</i18n>

<script>

export default {
  name: 'ServicesComponent',
};
</script>
<style>

#portfolio .portfolio-item {
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;
}

#portfolio .portfolio-item img{
  height: 250px;
}
#portfolio .portfolio-item .portfolio-link {
  position: relative;
  display: block;
  margin: 0 auto;
}
#portfolio .portfolio-item .portfolio-link .portfolio-hover {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 200, 0, 0.9);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity ease-in-out 0.25s;
}
#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
  font-size: 1.25rem;
  color: white;
}
#portfolio .portfolio-item .portfolio-link:hover .portfolio-hover {
  opacity: 1;
}
#portfolio .portfolio-item .portfolio-caption {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
  background-color: #fff;
}
#portfolio .portfolio-item .portfolio-caption .portfolio-caption-heading {
  font-size: 1.5rem;
  text-align: left;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  margin-bottom: 0;
}
#portfolio .portfolio-item .portfolio-caption .portfolio-caption-subheading {
  font-style: italic;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.iconcolor {
  color: #17a2b8;
}
</style>