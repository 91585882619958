export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our workshops and trainings"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are happy to transfer our knowledge and experience to you! We have been coaching, teaching, mentoring, and leading teams with our way of working for fifteen years. It is no problem for us to tailor one of our existing workshops to your needs, or to develop a custom training. This page gives an indication and some inspiration of what kind of trainings and workshops we can offer you."])},
        "sub1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Topologies training"])},
        "sub1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During this training participants will learn all there is to know about organizing business and technology teams in order to serve the business goals in an optimal way."])},
        "sub2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running platform teams"])},
        "sub2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This workshop is designed to align the platform strategy and vision with the ones from the company and its products. The workshop will provide insights and practical methods on how to set up and run in-company platform teams."])},
        "sub3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDD for IT leadership"])},
        "sub3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A training for onboarding managers en IT leads on domain driven design. This training helps to create a strategy, and support for adoption of domain-driven philosophy."])},
        "sub4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventstorming"])},
        "sub4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give your project a huge push forwards with Eventstorming. In a pressure cooker setup your domain experts and engineering team will create a shared understanding of your problem domain."])},
        "sub5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practical Product owner course"])},
        "sub5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product owner remains one of the most influential yet least supported roles in our industry. After the course participants will have a clear view on the full scope of Product Ownership, know about, and have practical experience with, numerous practical tools and activities which they can use right away."])},
        "sub6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Driven Product Thinking course"])},
        "sub6_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product leadership is not so much about progress on features, but whether changes have the desired effect on achieving business objectives. During this course, participants learn how to set up an objective-based flow that results in a continuous contribution to strategic goals."])}
      },
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze trainingen en workshops"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij delen onze kennis en ervaring graag! Met onze manier van werken, coachen, onderwijzen begeleiden en leiden we al vijftien jaar teams. Het is voor ons geen enkel probleem om één ​​van onze bestaande workshops op uw wensen af ​​te stemmen of een training op maat te ontwikkelen. Deze pagina geeft een indicatie van wat voor trainingen en workshops wij bieden."])},
        "sub1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Topologies Training "])},
        "sub1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdens deze training leren de deelnemers alles wat er te weten valt over het organiseren van business- en IT teams om de bedrijfsdoelstellingen op een optimale manier te dienen."])},
        "sub2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform teams"])},
        "sub2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze workshop is ontworpen om de platform strategie en visie af te stemmen op die van het bedrijf en haar producten. De workshop biedt inzichten en praktische methoden voor het opzetten en runnen van in-company platform teams."])},
        "sub3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDD voor IT leadership"])},
        "sub3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een training om managers en IT leads in te leiden in domain driven design. De training helpt met het creëren van strategie, draagvlak en ondersteuning voor de adoptie van domain-driven gedachtengoed."])},
        "sub4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventstorming"])},
        "sub4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef uw project een enorme boost met Eventstorming! In deze pressure cooker workshop zullen uw domein experts en engineering teams een ​​gedeeld begrip van uw probleemdomein creëren."])},
        "sub5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practische Product owner leergang"])},
        "sub5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De product owner blijft een van de meest invloedrijke maar minst ondersteunde rollen in onze branche. Na afloop van de cursus hebben de deelnemers een duidelijk beeld van de volledige reikwijdte van Product Ownership, kennen en hebben ze praktische ervaring met tal van praktische tools en activiteiten waar ze direct mee aan de slag kunnen."])},
        "sub6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursus Waardegedreven Product Denken"])},
        "sub6_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productleiderschap gaat niet zozeer over de voortgang op features, maar of veranderingen het gewenste effect hebben mbt het bereiken van bedrijfsdoelstellingen. Tijdens deze cursus leren deelnemers hoe ze een op strategische doelstellingen gebaseerde flow opzetten die resulteert in een continue bijdrage aan strategische doelen."])}
      }
    }
  })
}
