<template>
    <div id="app">
        <router-view name="headerComponent"></router-view>
        <main>
<!--            <fade-transition origin="center" mode="out-in" :duration="200">-->
              <router-view name="default"></router-view>
<!--            </fade-transition>-->
        </main>
        <router-view name="footerComponent"></router-view>
    </div>
</template>
<script>
// import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    // FadeTransition,
  }
};
</script>
