<template>
  <div>
    <poster_header />
    <ctoPartenership />
  </div>
</template>
<script>
import postHeader from "@/components/poster-header-small";
import ctoPartenership from "@/components/cto-partnership/cto-partnership";

export default {
  name: 'CtoPartnershipPage',
  components: {
    poster_header: postHeader,
    ctoPartenership: ctoPartenership
  },
};
</script>
