<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
  <section class="page-section" id="offering">
    <div class="container">
      <div class="text-left">
        <h2 class="section-heading text-uppercase">{{ t("title") }}</h2>
        <h3 class="section-subheading text-muted justified">{{ t("subtitle") }}</h3>
      </div>
      <div id="portfolio">
        <div class="row">
          <div class="col-lg-4 col-sm-6 mb-4">
            <div class="portfolio-item">
              <a class="portfolio-link" href="/team-topologies-training">
                <div class="portfolio-hover">
                  <div class="portfolio-hover-content">
                    <font-awesome-icon icon="person-chalkboard" size="3x"/>
                  </div>
                </div>
                <img height="200px" class="img-fluid" src="assets/img/training/team-topologies-header.png" alt=""/>
              </a>
              <div class="portfolio-caption px-0">
                <div class="portfolio-caption-heading">{{ t("sub1_title") }}</div>
              </div>
            </div>
            <p class="text-muted justified">{{ t("sub1_desc") }} <a href="/team-topologies-training">Training details</a>
            </p>
          </div>
          <div class="col-lg-4 col-sm-6 mb-4">
            <div class="portfolio-item">
              <a class="portfolio-link" href="/platform-teams-training">
                <div class="portfolio-hover">
                  <div class="portfolio-hover-content">
                    <font-awesome-icon icon="person-chalkboard" size="3x"/>
                  </div>
                </div>
                <img class="img-fluid" src="assets/img/training/beneath-surface.png" alt="Patterns"/>
              </a>
              <div class="portfolio-caption px-0">
                <div class="portfolio-caption-heading">{{ t("sub2_title") }}</div>
              </div>
            </div>
            <p class="text-muted justified">{{ t("sub2_desc") }} <a href="/platform-teams-training">Training details</a>
            </p>
          </div>
          <div class="col-lg-4 col-sm-6 mb-4">
            <div class="portfolio-item">
              <a class="portfolio-link" href="/ddd-for-it-leadership-and-management">
                <div class="portfolio-hover">
                  <div class="portfolio-hover-content">
                    <font-awesome-icon icon="person-chalkboard" size="3x"/>
                  </div>
                </div>
                <img height="200px" class="img-fluid" src="assets/img/training/ddd-leadership-header.png" alt=""/>
              </a>
              <div class="portfolio-caption">
                <div class="portfolio-caption-heading">{{ t("sub3_title") }}</div>
              </div>
            </div>
            <p class="text-muted justified">{{ t("sub3_desc") }} <a
                href="/ddd-for-it-leadership-and-management">Training details</a>
            </p>
          </div>

        </div>
        <div class="row">
          <div class="col-lg-4 col-sm-6 mb-4">
            <div class="portfolio-item">
              <a class="portfolio-link" href="/eventstorming-training">
                <div class="portfolio-hover">
                  <div class="portfolio-hover-content">
                    <font-awesome-icon icon="person-chalkboard" size="3x"/>
                  </div>
                </div>
                <img height="200px" class="img-fluid" src="assets/img/training/event-storming-header.png" alt=""/>
              </a>
              <div class="portfolio-caption">
                <div class="portfolio-caption-heading">{{ t("sub4_title") }}</div>
              </div>
            </div>
            <p class="text-muted justified">{{ t("sub4_desc") }} <a
                href="/eventstorming-training">Training details</a>
            </p>
          </div>

          <div class="col-lg-4 col-sm-6 mb-4">
            <div class="portfolio-item">
              <a class="portfolio-link" href="/product-owner-training">
                <div class="portfolio-hover">
                  <div class="portfolio-hover-content">
                    <font-awesome-icon icon="person-chalkboard" size="3x"/>
                  </div>
                </div>
                <img height="200px" class="img-fluid" src="assets/img/training/po-workshop.png" alt=""/>
              </a>
              <div class="portfolio-caption px-0">
                <div class="portfolio-caption-heading">{{ t("sub5_title") }}</div>
              </div>
            </div>
            <p class="text-muted justified">{{ t("sub5_desc") }} <a href="/product-owner-training">Training
              details</a>
            </p>
          </div>
          <div class="col-lg-4 col-sm-6 mb-4">
            <div class="portfolio-item">
              <a class="portfolio-link" href="/product-thinking-training">
                <div class="portfolio-hover">
                  <div class="portfolio-hover-content">
                    <font-awesome-icon icon="person-chalkboard" size="3x"/>
                  </div>
                </div>
                <img height="200px" class="img-fluid" src="assets/img/training/scaled-levels.jpeg" alt=""/>
              </a>
              <div class="portfolio-caption px-0">
                <div class="portfolio-caption-heading">{{ t("sub6_title") }}</div>
              </div>
            </div>
            <p class="text-muted justified">{{ t("sub6_desc") }} <a href="/product-thinking-training">Training
              details</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Our workshops and trainings",
    "subtitle": "We are happy to transfer our knowledge and experience to you! We have been coaching, teaching, mentoring, and leading teams with our way of working for fifteen years. It is no problem for us to tailor one of our existing workshops to your needs, or to develop a custom training. This page gives an indication and some inspiration of what kind of trainings and workshops we can offer you.",
    "sub1_title": "Team Topologies training",
    "sub1_desc": "During this training participants will learn all there is to know about organizing business and technology teams in order to serve the business goals in an optimal way.",
    "sub2_title": "Running platform teams",
    "sub2_desc": "This workshop is designed to align the platform strategy and vision with the ones from the company and its products. The workshop will provide insights and practical methods on how to set up and run in-company platform teams.",
    "sub3_title": "DDD for IT leadership",
    "sub3_desc": "A training for onboarding managers en IT leads on domain driven design. This training helps to create a strategy, and support for adoption of domain-driven philosophy.",
    "sub4_title": "Eventstorming",
    "sub4_desc": "Give your project a huge push forwards with Eventstorming. In a pressure cooker setup your domain experts and engineering team will create a shared understanding of your problem domain.",
    "sub5_title": "Practical Product owner course",
    "sub5_desc": "The product owner remains one of the most influential yet least supported roles in our industry. After the course participants will have a clear view on the full scope of Product Ownership, know about, and have practical experience with, numerous practical tools and activities which they can use right away.",
    "sub6_title": "Value Driven Product Thinking course",
    "sub6_desc": "Product leadership is not so much about progress on features, but whether changes have the desired effect on achieving business objectives. During this course, participants learn how to set up an objective-based flow that results in a continuous contribution to strategic goals."
  },
  "nl": {
    "title": "Onze trainingen en workshops",
    "subtitle": "Wij delen onze kennis en ervaring graag! Met onze manier van werken, coachen, onderwijzen begeleiden en leiden we al vijftien jaar teams. Het is voor ons geen enkel probleem om één ​​van onze bestaande workshops op uw wensen af ​​te stemmen of een training op maat te ontwikkelen. Deze pagina geeft een indicatie van wat voor trainingen en workshops wij bieden.",
    "sub1_title": "Team Topologies Training ",
    "sub1_desc": "Tijdens deze training leren de deelnemers alles wat er te weten valt over het organiseren van business- en IT teams om de bedrijfsdoelstellingen op een optimale manier te dienen.",
    "sub2_title": "Platform teams",
    "sub2_desc": "Deze workshop is ontworpen om de platform strategie en visie af te stemmen op die van het bedrijf en haar producten. De workshop biedt inzichten en praktische methoden voor het opzetten en runnen van in-company platform teams.",
    "sub3_title": "DDD voor IT leadership",
    "sub3_desc": "Een training om managers en IT leads in te leiden in domain driven design. De training helpt met het creëren van strategie, draagvlak en ondersteuning voor de adoptie van domain-driven gedachtengoed.",
    "sub4_title": "Eventstorming",
    "sub4_desc": "Geef uw project een enorme boost met Eventstorming! In deze pressure cooker workshop zullen uw domein experts en engineering teams een ​​gedeeld begrip van uw probleemdomein creëren.",
    "sub5_title": "Practische Product owner leergang",
    "sub5_desc": "De product owner blijft een van de meest invloedrijke maar minst ondersteunde rollen in onze branche. Na afloop van de cursus hebben de deelnemers een duidelijk beeld van de volledige reikwijdte van Product Ownership, kennen en hebben ze praktische ervaring met tal van praktische tools en activiteiten waar ze direct mee aan de slag kunnen.",
    "sub6_title": "Cursus Waardegedreven Product Denken",
    "sub6_desc": "Productleiderschap gaat niet zozeer over de voortgang op features, maar of veranderingen het gewenste effect hebben mbt het bereiken van bedrijfsdoelstellingen. Tijdens deze cursus leren deelnemers hoe ze een op strategische doelstellingen gebaseerde flow opzetten die resulteert in een continue bijdrage aan strategische doelen."
  }
}
</i18n>

<script>

export default {
  name: 'TrainingsList',
};
</script>
<style scoped>

#portfolio .portfolio-item {
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;
}

#portfolio .portfolio-item img {
  height: 250px;
}

#portfolio .portfolio-item .portfolio-link {
  position: relative;
  display: block;
  margin: 0 auto;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 200, 0, 0.9);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity ease-in-out 0.25s;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
  font-size: 1.25rem;
  color: white;
}

#portfolio .portfolio-item .portfolio-link:hover .portfolio-hover {
  opacity: 1;
}

#portfolio .portfolio-item .portfolio-caption {
  padding: 1.5rem;
  text-align: center;
  background-color: #fff;
}

#portfolio .portfolio-item .portfolio-caption .portfolio-caption-heading {
  font-size: 1.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  margin-bottom: 0;
}

#portfolio .portfolio-item .portfolio-caption .portfolio-caption-subheading {
  font-style: italic;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

</style>
