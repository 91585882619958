<template>
  <div>
    <poster_header />
    <training />
  </div>
</template>
<script>
import postHeader from "@/components/poster-header-small";
import training from "@/components/training/training";

export default {
  name: 'TrainingsPage',
  components: {
    poster_header: postHeader,
    training: training
  },
  metaInfo: {
    title: 'Trainings and workshops | Software in Rhythm',
  }
};
</script>
