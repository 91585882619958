<template>
  <div>
    <poster_header></poster_header>
    <training_platformteams></training_platformteams>
  </div>
</template>
<script>
import postHeader from "@/components/poster-header-small";
import training_platformteams from "@/components/training/training-platformteams";

export default {
  name: 'running-platform-teams-training-page',
  components: {
    poster_header: postHeader,
    training_platformteams: training_platformteams
  },
  metaInfo: {
    title: 'Running platform teams training | Software in Rhythm',
  }
};
</script>
