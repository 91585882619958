<template>
  <div>
    <poster_header></poster_header>
    <training_ddd_leadership></training_ddd_leadership>
  </div>
</template>
<script>
import postHeader from "@/components/poster-header-small";
import training_ddd_leadership from "@/components/training/training-ddd-leadership";

export default {
  name: 'training-ddd-page',
  components: {
    poster_header: postHeader,
    training_ddd_leadership: training_ddd_leadership
  },
  metaInfo: {
    title: 'DDD for IT leadership / management training | Software in Rhythm',
  }
};
</script>
