export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patterns & best practises"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the past fifteen years we collected a wide range of patterns, and best-practices. We published a sub-set of them on our website, and are happy to highlight them for you in a meeting or workshop. We also offer to scan your organization and tailor improvements for you."])}
      },
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronen & best practises"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de afgelopen vijftien jaar hebben we een breed scala aan patronen en best-practices verzameld. We hebben er een deel van op onze website gepubliceerd en lichten ze graag toe in een gesprek of workshop."])}
      }
    }
  })
}
