<template>
  <div>
    <poster_header></poster_header>
    <training_eventstorming></training_eventstorming>
  </div>
</template>
<script>
import postHeader from "@/components/poster-header-small";
import training_eventstorming from "@/components/training/training-eventstorming";

export default {
  name: 'training-page',
  components: {
    poster_header: postHeader,
    training_eventstorming: training_eventstorming
  },
  metaInfo: {
    title: 'Eventstorming training | Software in Rhythm',
  }
};
</script>
