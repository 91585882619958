export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Driven Product Thinking course"])},
        "training_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product leadership is not so much about progress on features, but whether changes have the desired effect on achieving business objectives."])},
        "training_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In practice you rarely see the relationship with the strategic goals and, more importantly, the right conversations about what each 'chunk' will contribute to the overall strategy. This makes it very difficult for everyone to understand what is really desired and what goals should be pursued."])},
        "training_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During this course, participants learn how to set up an objective-based flow that results in a continuous contribution to strategic goals."])},
        "training_p4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Course"])},
        "training_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Value Driven Product Thinking course is based on the principle that all products and their business cases are built around the same strategy and strategic goals. A crucial element is that we continuously look at the effects we hope to achieve with our changes in order to achieve these strategic goals."])},
        "training_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A typical product flow is described below. In this flow you get to work with strategic goals and transform them in a controlled way into working software that your users can use:"])},
        "training_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During the Value Driven Product Thinking course we focus on a subset of all elements from this flow that are leading in regards to product thinking:"])},
        "training_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants learn the importance of working from strategic objectives and how these can be crafted so they continuously can be validated. They learn how these objectives are embedded in business cases, value propositions, product visions and epics/features."])},
        "training_p8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scaling agile portfolio management"])},
        "training_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the most difficult issues for many companies is making agile product portfolio management and planning scalable. Where many scaling methods mainly focus on the planning of features, during this course we learn the importance of planning things that can be validated against the strategic goals and business cases. In addition, participants learn how to embed this in a form and meeting structure that facilitates this:"])},
        "training_p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In what phase and at what level which goals must be set and validated:"])},
        "training_p10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And how to ensure strategic product driven development by means of clear objectives and hypotheses."])},
        "training_p11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the course"])},
        "training_p11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the course, the participants will know:"])},
        "training_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to draw up strategic goals, business cases and product visions"])},
        "training_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to set up and link the goals, business cases and product visions"])},
        "training_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to ensure that continuous validation takes place at all levels and whether changes contribute to reaching the goals"])},
        "training_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to position multiple products around a shared strategy"])},
        "training_lo5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What meeting structures with their specific goals apply"])},
        "training_lo6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to translate product visions into measurable and validable hypothesis and experiments"])},
        "training_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a course"])},
        "training_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course options"])},
        "training_option_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language: Dutch/English"])},
        "training_option_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration: 1-3 months"])},
        "training_option_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location: Onsite"])},
        "training_rel_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related blogs"])}
      },
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursus Waardegedreven Product Denken"])},
        "training_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productleiderschap gaat niet zozeer over de voortgang op features, maar of veranderingen het gewenste effect hebben mbt het bereiken van bedrijfsdoelstellingen. "])},
        "training_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In praktijk zie je zelden de relatie met de strategische doelen en, nog belangrijker, de juiste gesprekken over wat elk 'brok' zal bijdragen aan de algehele strategie. Dit maakt het erg moeilijk om te begrijpen wat echt gewenst is en welke doelen er moeten worden nagestreefd. "])},
        "training_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdens deze cursus leren deelnemers hoe ze een op strategische doelstellingen gebaseerde flow opzetten die resulteert in een continue bijdrage aan strategische doelen."])},
        "training_p4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De cursus"])},
        "training_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De cursus Waardegedreven Product Denken gaat uit van het principe dat alle producten en hun business cases rondom de strategie en de strategische doelen worden gebouwd. Een cruciaal element is dat we vervolgens continu kijken welke effecten we hopen te bereiken met onze veranderingen om zodoende deze strategische doelen te behalen."])},
        "training_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hieronder staat een typische product flow beschreven. In deze flow ga je aan de slag met strategische doelen en transformeer je deze op een gecontroleerde manier naar werkende software waar je gebruikers mee aan de slag kunnen:"])},
        "training_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdens de cursus Waardegedreven product denken gaan we uit van een subset van alle elementen uit deze flow die vanuit het product denken leidend zijn:"])},
        "training_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deelnemers leren hoe belangrijk het is om vanuit strategische doelstellingen te werken en hoe deze opgesteld kunnen worden zodat ze valideerbaar zijn en blijven. Vervolgens leren ze hoe deze doelstellingen vormgegeven kunnen worden in zaken als business cases, propositie uitwerkingen, product visies en epics/features."])},
        "training_p8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schalen van agile portfolio management"])},
        "training_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eén van de lastigste vraagstukken voor veel bedrijven is het schaalbaar maken van agile product portfolio management en planning. Waar veel manieren van schalen vooral de focus leggen op de planning van features leren we tijdens deze cursus het belang van het plannen van zaken die te valideren zijn aan de business case en strategische doelen. Daarnaast leren deelnemers hoe dit te integreren is in een vorm en overlegstructuur die dit maximaal faciliteert:"])},
        "training_p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In welke fase en op welk niveau er welke doelen gesteld en gevalideerd moeten worden:"])},
        "training_p10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En hoe je door middel van heldere objectives en hypotheses kan zorgen voor strategisch product driven development."])},
        "training_p11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na de cursus"])},
        "training_p11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na de cursus weten de deelnemers:"])},
        "training_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe strategische doelen, business cases en product visies op te stellen"])},
        "training_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe de doelen, business cases en product visies aan elkaar te linken"])},
        "training_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe ervoor te zorgen dat er continu en op alle niveaus gevalideerd kan worden of er wordt bijgedragen aan de doelen"])},
        "training_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe meerdere producten te positioneren rondom een gedeelde strategie"])},
        "training_lo5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke overlegstructuren er zijn met elk hun specifieke doelen"])},
        "training_lo6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe product visies te vertalen in meetbare en valideerbare hypothesen en experimenten"])},
        "training_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a course"])},
        "training_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursus opties"])},
        "training_option_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language: Nederland/Engels"])},
        "training_option_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duur: 1-3 maand"])},
        "training_option_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location: Onsite"])},
        "training_rel_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related blogs"])}
      }
    }
  })
}
