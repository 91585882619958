<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n();
</script>

<template>
  <section class="page-section" id="team">
    <div class="container">
      <div class="text-left">
        <h2 class="section-heading text-uppercase">{{ t("title") }}</h2>
        <h3 class="section-subheading text-muted justified">{{ t("subtitle") }}</h3>
      </div>
      <div class="row my-5" id="about-us">
        <div class="row" v-if="even">
          <div class="col-lg-6">
            <div class="team-member">
              <img class="mx-auto rounded-circle" src="assets/img/team/kevinvaningen.jpg" alt="Kevin van Ingen"/>
              <h4>Kevin van Ingen</h4>
              <p class="text-muted">IT Lead | Cloud Architect </p>
              <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/kevinvaningen/" target="_blank">
                <font-awesome-icon :icon="['fab','linkedin-in']"/>
              </a>
              <a class="btn btn-dark btn-social mx-2" href="https://kevin-van-ingen.medium.com/" target="_blank">
                <font-awesome-icon :icon="['fab','medium']"/>
              </a>
            </div>
            <h4 class="my-3">Kevin van Ingen</h4>
            <p class="text-muted justified">{{ t("kevin_desc") }}</p>
            <a href="https://www.kevinvaningen.nl" target="_blank">
              <button class="btn btn-primary btn-l text-uppercase" href="https://www.kevinvaningen.nl">
                {{ t("link_desc") }} >
              </button>
            </a>
          </div>

          <div class="col-lg-6">
            <div class="team-member">
              <img class="mx-auto rounded-circle" src="assets/img/team/nielstalens.jpg" alt="Niels Talens"/>
              <h4>Niels Talens</h4>
              <p class="text-muted">IT Lead | Chief Product Owner </p>
              <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/nielstalens/" target="_blank">
                <font-awesome-icon :icon="['fab','linkedin-in']"/>
              </a>
            </div>
            <h4 class="my-3">Niels Talens</h4>
            <p class="text-muted justified">{{ t("niels_desc") }}</p>
            <a href="https://www.nielstalens.nl" target="_blank">
              <button class="btn btn-primary btn-l text-uppercase">{{ t("link_desc") }}></button>
            </a>
          </div>
        </div>
        <div class="row" v-if="!even">
          <div class="col-lg-6">
            <div class="team-member">
              <img class="mx-auto rounded-circle" src="assets/img/team/nielstalens.jpg" alt="Niels Talens"/>
              <h4>Niels Talens</h4>
              <p class="text-muted">IT Lead | Chief Product Owner </p>
              <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/nielstalens/" target="_blank">
                <font-awesome-icon :icon="['fab','linkedin-in']"/>
              </a>
            </div>
            <h4 class="my-3">Niels Talens</h4>
            <p class="text-muted justified">{{ t("niels_desc") }}</p>
            <a href="https://www.nielstalens.nl" target="_blank">
              <button class="btn btn-primary btn-l text-uppercase">{{ t("link_desc") }}></button>
            </a>
          </div>

          <div class="col-lg-6">
            <div class="team-member">
              <img class="mx-auto rounded-circle" src="assets/img/team/kevinvaningen.jpg" alt="Kevin van Ingen"/>
              <h4>Kevin van Ingen</h4>
              <p class="text-muted">IT Lead | Cloud Architect </p>
              <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/kevinvaningen/" target="_blank">
                <font-awesome-icon :icon="['fab','linkedin-in']"/>
              </a>
              <a class="btn btn-dark btn-social mx-2" href="https://kevin-van-ingen.medium.com/" target="_blank">
                <font-awesome-icon :icon="['fab','medium']"/>
              </a>
            </div>
            <h4 class="my-3">Kevin van Ingen</h4>
            <p class="text-muted justified">{{ t("kevin_desc") }}</p>
            <a href="https://www.kevinvaningen.nl" target="_blank">
              <button class="btn btn-primary btn-l text-uppercase" href="https://www.kevinvaningen.nl">
                {{ t("link_desc") }} >
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n lang="json">
{
  "en": {
    "title": "About us",
    "subtitle": "Building high performance organizations is what makes us tick!",
    "kevin_desc": "An engineering leader that has a thorough understanding of the state-of-the-art technologies as well as how to build a high-performance organizations to match it. I enjoy the diversity of work. Whether it is setting with a domain architecture, creating an engineering culture, speeding up development's output or creating a reference architecture. The end result should be a healthy, enjoyable working environment for engineers with great outcomes for the business.",
    "niels_desc": "For me the most important thing is finding ways to help companies in creating the best possible products and services. In this quest there are numerous tools, ways of working, environments, trends and frameworks that can help us in doing this secure, fast and reliable. Whether it is agile portfolio management or continuous delivery, Devops transitions or product visions, I always look for optimization the whole. For me this is the flow from strategic goals to customer feedback.",
    "link_desc": "Read more about me"
  },
  "nl": {
    "title": "Over ons",
    "subtitle": "Het bouwen van high performance organisaties is wat ons drijft!",
    "kevin_desc": "Een technisch leider die een grondige kennis heeft van de allernieuwste technologieën en die ook weet hoe hij een krachtige organisatie kan bouwen die hiermee uit de voeten kan. Ik geniet van de diversiteit van het werk. Of het nu gaat om het instellen met een domeinarchitectuur, het creëren van een engineering cultuur, het versnellen van de output van de ontwikkeling of het creëren van een referentiearchitectuur. Het eindresultaat moet een gezonde, plezierige werkomgeving voor ingenieurs zijn met geweldige resultaten voor het bedrijf.",
    "niels_desc": "Voor mij is het belangrijkste om manieren te vinden om bedrijven te helpen bij het creëren van de best mogelijke producten en diensten. In deze zoektocht zijn er tal van tools, werkwijzen, omgevingen, trends en frameworks die ons kunnen helpen om dit veilig, snel en betrouwbaar te doen. Of het nu gaat om agile portfoliomanagement of continuous delivery, Devops transities of product visies, ik zoek altijd naar optimalisatie van het geheel. Voor mij is dit de flow van strategische doelen naar klantfeedback.",
    "link_desc": "Meer over mij"
  }
}
</i18n>

<script>

export default {
  name: 'AboutUsComponent',
  data: function () {
    return {
      even: (Math.floor(Math.random() * 2)) % 2 === 0,
    }
  }
};
</script>
<style scoped>
.team-member {
  margin-bottom: 1rem;
  text-align: center;
}

.team-member img {
  width: 13rem;
  height: 13rem;
  border: 0.5rem solid rgba(0, 0, 0, 0.1);
}

.team-member h4 {
  margin-top: 1.5rem;
  margin-bottom: 0;
}
</style>