<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n();
</script>

<template>
  <div class="masthead masthead-small bg-dark">
    <div class="container">
      <div class="masthead-heading-small text-uppercase">{{ t('company_name') }}</div>
    </div>
  </div>
</template>
<i18n lang="json">
{
  "en": {
    "company_name": "Software in Rhythm"
  },
  "nl": {
    "company_name": "Software in Rhythm"
  }
}
</i18n>
<script>
export default {
  name: 'PosterHeaderSmall',
};
</script>
<style>
.masthead {
  padding-top: 12rem;
  padding-bottom: 6rem;
  color: #fff;
  background-image: url("../../public/assets/img/sir-header.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  min-height: 500px;
}

.masthead .masthead-heading-small {
  line-height: 4rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.masthead-heading-small {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 3rem;
  font-weight: 800px;
}

@media (min-width: 768px) {
  .masthead {
    overflow-y: hidden;
  }

  .masthead .masthead-heading-small {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 30px;
    font-size: 5rem;
  }
}
</style>