export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDD for IT leadership"])},
        "training_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A training domain Driven Design for IT leadership and managers."])},
        "training_p2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why DDD and why IT leadership?"])},
        "training_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the challenges in software product development is to correctly understand, describe, and verify what is needed. DDD offers insights and tools for efficient delivery in a scalable organization. It helps to set up the right organization, team, and communication structures. Where trends like agile and DevOps increased our pace to get feedback early, it often leaves room for improvement in finding the correct interpretation of our problem space."])},
        "training_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDD helps to connect business and IT and to build the foundation of a healthy architecture and a modern software department. It’s crucial to get IT leadership and management onboard on domain-driven ideology."])},
        "training_p4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short description of the training"])},
        "training_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This training helps to create a strategy, and support for the adoption of domain-driven philosophy. More and more we see that domain-driven is an enabler for the modern enterprise. In order to make digitization a success we must support our business and software domains with the right structure. Despite having the cloud as a technical enabler, we need socio-technical enablers to support digitization fully. "])},
        "training_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This training starts with an overview of strategy IT and how it relates to DDD. We will get more insights on modern trends like domain-driven, microservices, the data mesh, team toplogoies and the socio-technical system in which they relate. We will learn how to manage and influence the adoption of these trends through best-practices and implementation patterns. We will use case studies to make this hands-on and recognizable. "])},
        "training_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionally, we can expand the training with an Eventstorming case. This offers an interactive workshop way of learning more on DDD and will make participants get a real feal on how it is to work on a DDD enabled field complex platform."])},
        "training_p7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target audience"])},
        "training_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This training is intended for people in roles such as software development manager, product owners, product managers, C-level management, architects, agile staff, IT managers and engineering leads, in short, anyone who wants to make software development teams a success but may not have the technical background of an engineer. Technical knowledge is certainly not required, but some experience in or around a software team is usefull."])},
        "training_p8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preperation"])},
        "training_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No preparation is required. In consultation, examples and assignments can be aimed at the domain of the participants."])}
      },
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDD voor IT leadership"])},
        "training_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een training domain Driven Design voor IT leadership en managers."])},
        "training_p2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarom DDD en waarom voor IT leadership?"])},
        "training_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eén van de grootste uitdagingen bij product development is het correct kunnen begrijpen, beschrijven en toetsen van hetgeen wat er nodig is. DDD biedt de inzichten en tools die  nodig zijn om zo effectief mogelijk de juiste producten te bouwen in een schaalbare organisatie. Het helpt de juiste organisatie-, team- en communicatiestructuren op te zetten. Waar we door manieren van werken als agile en organisatie structuren als DevOps sneller feedback krijgen is hier in de praktijk nog te veel ruimte voor verkeerde interpretaties en inefficiënte organisatie."])},
        "training_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDD helpt in de aansluiting tussen business en IT en helpt bij het neerzetten van een gezonde architectuur voor een moderne software-afdeling. Het is cruciaal dat we de beslissingmakers in ons business-IT-landschap meenemen in domain-driven gedachtegoed.   "])},
        "training_p4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korte omschrijving van de training"])},
        "training_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De training DDD for IT leadership helpt met het creëren van strategie, draagvlak en ondersteuning voor de adoptie van domain-driven gedachtengoed. Domain-driven is steeds meer een enabler van moderne software omgevingen. Om van digitalisering een succes te maken moeten we onze business en software domeinen met de juiste structuur organiseren. Ondanks dat de cloud een technische enabler is, hebben we socio-technische enablers nodig om digitalisering voldoende te ondersteunen."])},
        "training_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze training begint met een verkenning van strategisch IT en hoe dit met DDD ondersteunt kan worden. Je krijgt inzicht op moderne trends als domain-driven, microservices, de data mesh, team topologies en het socio-technische systeem waarin ze zich tot elkaar verhouden. Via best-practices en bekende patronen bespreken we hoe hierop te sturen valt en waar men rekening mee moet houden. We behandelen casussen om het praktisch en herkenbaar te maken. "])},
        "training_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De training is uit te breiden met een Evenstorming casus. Dit is een interactieve kennismaking met het gedachtegoed en laat de deelnemers ervaren hoe het is om  complexe platformen te ontwerpen. "])},
        "training_p7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelgroep"])},
        "training_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze training is bedoeld voor mensen in rollen als software development manager, product owners, product managers, C-level management, architecten, agile staff, IT managers en engineering leads, kortom iedereen die van software development teams een succes wil maken maar wellicht niet de technische achtergrond van een engineer heeft.  Technische kennis is zeker niet vereist, maar het enige ervaring in of rondom een software team komt van pas. "])},
        "training_p8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbereiding"])},
        "training_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen voorbereiding benodigd. In overleg kunnen voorbeelden en opdrachten op het domein van de deelnemers gericht worden.  "])}
      }
    }
  })
}
