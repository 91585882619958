<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n();
</script>

<template>
  <section class="page-section" id="offering">
    <div class="container">
      <div class="text-left">
        <h1 class="section-heading text-uppercase">{{ t("title") }}</h1>
        <div class="row my-5" id="portfolio">
          <div class="col-lg-8 col-sm-8 mb-8">
            <p class="section-subheading text-muted justified">{{ t("training_p1") }}</p>
            <p class="section-subheading text-muted justified">{{ t("training_p2") }}</p>
            <p class="section-subheading text-muted justified">{{ t("training_p3") }}</p>
            <h3>{{ t("training_p4_title") }}</h3>
            <p class="section-subheading text-muted justified">{{ t("training_p4") }}</p>
            <h3>{{ t("training_p4_title") }}</h3>
            <p class="section-subheading text-muted justified">{{ t("training_p5") }}</p>
          </div>

          <div class="col-lg-4 col-sm-4 mb-4">
            <img class="img-fluid" src="assets/img/offering/eventstorming-kevin.png" alt=""/>
            <div class="row pt-5">
              <router-link :to="{path:'/', hash:'#contact'}">
                <button class="btn btn-primary w-100" type="button">
                  Book a training
                </button>
              </router-link>
            </div>
            <div class="row pt-5 ">
              <h3 class="h5">Training advantages</h3>
              <ul class="text-muted px-4">
                <li>Expert knowledge transfer</li>
                <li>Domain exploration</li>
                <li>Durable software designs</li>
                <li>Bootstrap your project/team</li>
                <li>System Architecture</li>
              </ul>
            </div>
            <div class="row pt-5">
              <h3 class="h5">Related blogs</h3>
              <ul class="text-muted px-4">
                <li><a href="https://kevin-van-ingen.medium.com/mix-event-storming-in-your-agile-toolset-437a54939aeb"
                       target="_blank">Embed eventstorming in your agile toolset</a></li>
                <li><a
                    href="https://medium.com/codex/domain-driven-design-ddd-or-just-domain-oriented-design-dod-9098abecd456"
                    target="_blank">DDD or just domain oriented design?</a></li>
                <li><a
                    href="https://medium.com/codex/domain-driven-design-for-it-managers-285d92e723c1"
                    target="_blank">Domain-Driven Design for IT managers</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Eventstorming workshop",
    "training_p1": "Building software is an increasingly more complex, multi-domain, multi-disciplinary engagement. In order to succeed and thrive we need tools and approaches that bring our best and bright minds together to produce designs that are ready for implementation.",
    "training_p2": "We have a history in leading projects. They come in every flavor and size, from greenfields, to migration of systems that have been serviced for over a decade. Over the years, we built up a range of processes, tools, and workshop techniques. Eventstorming is one of those techniques where everything comes together. We have gained experience designing using Eventstorming, and also implemented systems according to those designs and realised the value of this type of workshop. If the project is still in early stage, or needs a strong foundation, we can also start with a product vision session, and/or interaction design. Furthermore, we can design and guide the implementation of such a system.",
    "training_p3": "Every workshop is custom and details will need to be discussed. For inspiration we can provide some options:",
    "training_p4_title": "Project bootstrap workshop",
    "training_p4": "A half/full-day pressure cooker that takes your project initiation to the next level! After a full day of immersion, your team will have a solid understanding of the complexity, bottlenecks, and will be able to identify application domains. Starting with a shared understanding and a big picture will speed up project bootstrapping.",
    "training_p5_title": "Full design workshop",
    "training_p5": "A two-four day workshop that starts by creating a high-level picture of the entire domain. Afterwards, iterations will add more detail and gradually progresses more to a practical engineering level. This is a great way if you have development capacity in multiple teams but need a way to partition work streams. We can take modern architecture practices like microservices, and DDD paradigms into account. When desired, we take modern practices like DevOps, Safe, and 'Team Topologies' philosophy into account."
  },
  "nl": {
    "title": "Eventstorming workshop",
    "training_p1": "Het maken van software is steeds meer een complex, multi-domain, multidisciplinaire aangelegenheid. Om dit succesvol te doen hebben we tools, processen en een aanpak nodig die het beste haalt uit jouw experts. Om zo tot designs te komen die geschikt zijn voor implementatie.",
    "training_p2": "Wij hebben een achtergrond in het leiden van IT projecten. In elke maat, grote, volwassenheid en diversiteit. Van nieuwbouw tot migratie, van startup tot enterprise. In de jaren hebben we een breed scala aan tools, processen, en workshoptechnieken opgedaan. Eventstorming is een prachtige techniek waar alles samen komt. Wij hebben ervaring met het begeleiden van Eventstorming workshops, het deelnemen als engineer, tot het implementeren van hieruit voort vloeiende ontwerpen. Als het project nog in een vroeg stadium is (of een aanscherping nodig heeft) kunnen we starten met een productvisie sessie. Eventueel kunnen we helpen bij het inrichten van de organisatie rondom de implementatie en de bijhorende architectuur.",
    "training_p3": "Elke workshop is maatwerk en details worden samen ingevuld. Voor inspiratie hebben we enkele opties:",
    "training_p4_title": "Project bootstrap workshop",
    "training_p4": "Een halve of hele dag als pressure cooker waar vanuit niets een complete plaat van het systeem (of zelfs het IT landschap) wordt opgebouwd! Na een hele dag ondergedompeld te zijn heeft het team een sterk begrip van het domein, de complexiteit en bottlenecks of probleemgebieden. Een project starten met een gedeelde veronderstelling is een enorme versneller voor uw project.",
    "training_p5_title": "Full design workshop",
    "training_p5": "Een twee tot vier daagse workshop waar we starten met een high-level overzicht op het domein. Daarna itereren we opnieuw en opnieuw waar detaillering wordt aangebracht tot uiteindelijk het implementatieniveau. Dit is de perfecte manier om je domein op te breken in mooie stromen werk en de beste manier om afhankelijkheid en samenwerking goed op te zetten. We nemen de architectuurdoelstelling (bv microservices) mee in de discussies en beslissingen en bijvoorbeeld het hoe het design het beste kan landen in een domain-driven-design paradigma. Indien gewenst kunnen we ook de implementatie voor een organisatie met DevOps, Safe, en 'Team Topologies' meenemen."
  }
}
</i18n>
<script>
export default {
  name: 'TrainingEventstorming'
};
</script>
