<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
  <section class="page-section" id="offering">
    <div class="container">
      <div class="text-left">
        <h1 class="section-heading text-uppercase">{{ t("title") }}</h1>
        <div class="row my-5" id="portfolio">
          <div class="col-lg-8 col-sm-8 mb-8">
            <p class="section-subheading text-muted justified"> {{ t("training_p1") }}  </p>
            <p class="section-subheading text-muted justified"> {{ t("training_p2") }} </p>
            <img class="card-img" src="/assets/img/training/team-topologies-1.png" alt="models">
            <p class="section-subheading text-muted justified"> {{ t("training_p3") }} </p>
            <h3 class="h5">{{ t("training_p4_title") }}</h3>
            <p class="section-subheading text-muted justified"> {{ t("training_p4") }} </p>
            <h3 class="h5">{{ t("training_p5_title") }}</h3>
            <img class="card-img" src="/assets/img/training/team-topologies-2.png" alt="models">
            <p class="section-subheading text-muted justified"> {{ t("training_p5") }} </p>

            <ul class="text-muted">
              <li>{{ t("training_lo1") }}</li>
              <li>{{ t("training_lo2") }}</li>
              <li>{{ t("training_lo3") }}</li>
              <li>{{ t("training_lo4") }}</li>
              <li>{{ t("training_lo5") }}</li>
              <li>{{ t("training_lo6") }}</li>
              <li>{{ t("training_lo7") }}</li>
              <li>{{ t("training_lo8") }}</li>
              <li>{{ t("training_lo9") }}</li>
              <li>{{ t("training_lo10") }}</li>
              <li>{{ t("training_lo11") }}</li>
            </ul>
          </div>
          <div class="col-lg-4 col-sm-4 mb-4">
            <img class="img-fluid" src="assets/img/training/team-topologies-header.png" alt=""/>
            <div class="row pt-5">
              <router-link :to="{path:'/', hash:'#contact'}">
                <button class="btn btn-primary w-100" type="button">
                  {{ t("training_book") }}
                </button>
              </router-link>
            </div>

            <div class="row pt-5">
              <h3 class="h5">{{ t("training_options") }}</h3>
              <p class="text-muted">{{ t("training_option_1") }}</p>
              <p class="text-muted">{{ t("training_option_2") }}</p>
              <p class="text-muted">{{ t("training_option_3") }}</p>
              <p class="text-muted"></p>
            </div>
            <div class="row pt-5 ">
              <h3 class="h5">{{ t("training_advantage_title") }}</h3>
              <ul class="text-muted px-4">
                <li>{{ t("training_ad_1") }}</li>
                <li>{{ t("training_ad_2") }}</li>
                <li>{{ t("training_ad_3") }}</li>
                <li>{{ t("training_ad_4") }}</li>
              </ul>
            </div>
            <div class="row pt-5">
              <h3 class="h5">{{ t("training_rel_blogs") }}</h3>
              <ul class="text-muted px-4">
                <li><a
                    href="https://medium.com/codex/domain-driven-design-ddd-or-just-domain-oriented-design-dod-9098abecd456"
                    target="_blank">DDD or just domain oriented design?</a></li>
                <li><a
                    href="https://medium.com/codex/domain-driven-design-for-it-managers-285d92e723c1"
                    target="_blank">Domain-Driven Design for IT managers</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Team topologies training",
    "training_p1": "During this training participants will learn all there is to know about organizing business and technology teams in order to serve the business goals in an optimal way.",
    "training_p2": "In many organizations, teams are set up in such a way that it appears very clear and logical on the organization chart, but in reality it is very difficult to achieve the speed and quality that customers and users expect. There is a lot of mutual interaction, dependencies and employees must have knowledge of many topics that are not necessarily needed for their daily work (a high cognitive load).",
    "training_p3": "The current setup often prevents organizations from really applying product thinking, where customers and users frequently receive qualitative features.",
    "training_p4_title": "Building high-performance organizations & teams",
    "training_p4": "The training provides a practical hands-on structure for organizational design and team interaction and the identification of bottlenecks. Not only does the training provide insights in both the current situation and the desired end state but gives you also the practical steps on how to get there, tailored to your situation.",
    "training_p5_title": "Learning objectives",
    "training_p5": "Upon completion of this training participants will know:",
    "training_lo1": "How different team patterns are used by successful organizations.",
    "training_lo2": "The team patterns that are best suited for your situation and products.",
    "training_lo3": "When and why to use different team patterns.",
    "training_lo4": "How to create a mapping of your current and the desired organizational design and team interactions.",
    "training_lo5": "Practical steps on how to gradually transform from the current to the desired state.",
    "training_lo6": "How to develop your own framework for tailor-made change.",
    "training_lo7": "What software engineering performance strategies are and their importance.",
    "training_lo8": "About the concepts of team topologies.",
    "training_lo9": "Learn to identify performance opportunities.",
    "training_lo10": "How DORA metrics relate to performance.",
    "training_lo11": "How these things relate to modern software architectures.",
    "training_book": "Book a training",
    "training_options": "Training options",
    "training_option_1": "Language: Dutch/English",
    "training_option_2": "Duration: Program / 1 day / half day",
    "training_option_3": "Location: Onsite / hosted venue",
    "training_advantage_title": "Training advantages",
    "training_ad_1": "Understand Team Topologies",
    "training_ad_2": "Optimize team performance",
    "training_ad_3": "Align people, organization, and IT",
    "training_ad_4": "Obtain new management techniques",
    "training_rel_blogs": "Related blogs"
  },
  "nl": {
    "title": "Team topologies training",
    "training_p1": "Tijdens deze training leren de deelnemers alles wat er te weten valt over het organiseren van business- en IT teams om de bedrijfsdoelstellingen op een optimale manier te dienen. ",
    "training_p2": "In veel organisaties zijn teams zo ingericht zodat het op het organogram erg overzichtelijk en logisch lijkt maar het in werkelijkheid heel lastig is om de snelheid en kwaliteit te behalen die klanten en gebruikers verwachten. Zo is er veel onderlinge interactie, afhankelijkheden en moeten medewerkers kennis hebben van veel onderwerpen die niet perse nodig zijn voor de dagelijkse werkzaamheden (veel cognitieve belasting).",
    "training_p3": "De huidige organisatiestructuur weerhoudt organisaties er veelal van om echt het product denken toe te passen waarbij klanten en gebruikers vaak kwalitatieve features krijgen. ",
    "training_p4_title": "High-performance organisaties en teams",
    "training_p4": "De training biedt een praktische, hands-on structuur voor organisatieontwerp en teaminteractie en de identificatie van knelpunten. De training geeft niet alleen inzicht in zowel de huidige situatie als de gewenste eindtoestand, maar geeft ook de praktische stappen om daar te komen, afgestemd op uw situatie.",
    "training_p5_title": "Leerdoelen",
    "training_p5": "Na afloop van deze training weten de deelnemers:",
    "training_lo1": "Wat de verschillende team structuren die worden gebruikt door succesvolle organisaties zijn.",
    "training_lo2": "Welke team structuren het beste passen bij uw situatie en producten.",
    "training_lo3": "Wanneer en waarom verschillende team structuren te gebruiken.",
    "training_lo4": "Hoe u uw huidige en gewenste organisatieontwerp en team interacties in kaart brengt.",
    "training_lo5": "Praktische stappen om geleidelijk van de huidige naar de gewenste toestand te transformeren.",
    "training_lo6": "Hoe een eigen framework te ontwikkelen voor verandering op maat.",
    "training_lo7": "Welke prestatie strategieën voor software-engineering zijn en hun belang.",
    "training_lo8": "Over de concepten van team topologieën.",
    "training_lo9": "Leer prestatiemogelijkheden te identificeren.",
    "training_lo10": "Hoe DORA-statistieken betrekking hebben op prestaties.",
    "training_lo11": "Hoe bovenstaande is gerelateerd aan het bouwen van moderne software architecturen.",
    "training_book": "Book een training",
    "training_options": "Training mogelijkheden",
    "training_option_1": "Taal: Nederlands/Engels",
    "training_option_2": "Duur: leerlijn / 1 dag / halve dagen",
    "training_option_3": "Locatie: Onsite / hosted venue",
    "training_advantage_title": "Training voordelen",
    "training_ad_1": "Begrip van Team Topologies",
    "training_ad_2": "Optimaliseren van team performance",
    "training_ad_3": "Alignment tussen mensen, organisatie, en IT",
    "training_ad_4": "Nieuwe managementtechnieken",
    "training_rel_blogs": "Gerelateerde blogs"
  }
}
</i18n>

<script>
export default {
  name: 'TrainingEventstorming'
};
</script>
