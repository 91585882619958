import Router from "vue-router";
import footer from "@/components/footer";
import navigation from "@/components/navigation";
import PatternList from "@/components/pattern/pattern-list";
import PatternDetail from "@/components/pattern/pattern-details";
import PatternPage from "@/components/pattern/PatternPage";
import BlogPage from "@/components/blogs/BlogPage";
import ServicesPage from "@/components/services/ServicesPage";
import TrainingPage from "@/components/training/TrainingsPage";
import AboutUsPage from "@/components/about-us/AboutUsPage";
import EventstormingTrainingPage from "@/components/training/EventstormingTrainingPage";
import PlatformTeamsTrainingPage from "@/components/training/PlatformTeamsTrainingPage";
import ProductOwnerTrainingPage from "@/components/training/ProductOwnerTrainingPage";
import ProductThinkingTrainingPage from "@/components/training/ProductThinkingTrainingPage";

import TeamPerformanceTrainingPage from "./components/training/TeamTopologiesTrainingPage";
import {createWebHistory, createRouter} from "vue-router";
import DDDItLeadershipPage from "./components/training/DDDItLeadershipPage";
import CtoPartnershipPage from "./components/cto-partnership/CTOPage.vue";
import { trackRouter } from "vue-gtag-next";

const routes = [
    {
        path: "/",
        name: "home",
        components: {
            headerComponent: navigation,
            default: () => import('@/components/home/HomePage'),
            footerComponent: footer
        },
        meta: {title: 'IT Leadership Partnership | Fractional CTO | Software in Rhythm'},
    },
    {
        path: "/services",
        name: "services",
        components: {
            headerComponent: navigation,
            default: ServicesPage,
            footerComponent: footer
        },
        meta: {title: 'Our services | IT Leadership Partnership | Interim CTO | Fractional CTO | Software in Rhythm'}
    },
    {
        path: "/leadership-partnership",
        name: "leadership-partnership",
        components: {
            headerComponent: navigation,
            default: CtoPartnershipPage,
            footerComponent: footer
        },
        meta: {title: 'IT Leadership Partnership | Fractional CTO | Software in Rhythm'}
    },
    {
        path: "/training",
        name: "training",
        components: {
            headerComponent: navigation,
            default: TrainingPage,
            footerComponent: footer
        },
        meta: {title: 'Team Topologies training | Eventstorming workshops | Platform teams training | Software Product Management training | DDD training | Software in Rhythm'}
    },
    {
        path: "/team-topologies-training",
        name: "Team Topologies Training",
        components: {
            headerComponent: navigation,
            default: TeamPerformanceTrainingPage,
            footerComponent: footer
        },
        meta: {title: 'Team Topologies training | Team Topologies workshop | Software in Rhythm'}
    },
    {
        path: "/platform-teams-training",
        name: "Running Platform Teams Training",
        components: {
            headerComponent: navigation,
            default: PlatformTeamsTrainingPage,
            footerComponent: footer
        },
        meta: {title: 'Team Topologies training | Team Topologies workshop | Software in Rhythm'}
    },
    {
        path: "/ddd-for-it-leadership-and-management",
        name: "DDD for IT leadership",
        components: {
            headerComponent: navigation,
            default: DDDItLeadershipPage,
            footerComponent: footer
        },
        meta: {title: 'Domain-driven design training | DDD training | DDD workshop | Eventstorming workshop | Software in Rhythm'}
    },
    {
        path: "/eventstorming-training",
        name: "Eventstorming workshop",
        components: {
            headerComponent: navigation,
            default: EventstormingTrainingPage,
            footerComponent: footer
        },
        meta: {title: 'Eventstorming workshop | Event storming training | DDD modeling workshop | Software in Rhythm'}
    },
    {
        path: "/product-owner-training",
        name: "Product owner Training",
        components: {
            headerComponent: navigation,
            default: ProductOwnerTrainingPage,
            footerComponent: footer
        },
        meta: {title: 'Product owner training | Software in Rhythm'}
    },
    {
        path: "/product-thinking-training",
        name: "Product thinking Training",
        components: {
            headerComponent: navigation,
            default: ProductThinkingTrainingPage,
            footerComponent: footer
        },
        meta: {title: 'Product thinking training | Software product management workshop | Software in Rhythm'}
    },
    {
        path: "/patterns",
        components: {
            headerComponent: navigation,
            default: PatternPage,
            footerComponent: footer,
        },
        children: [
            {
                path: "",
                name: 'patterns-list',
                components: {
                    patternView: PatternList,
                }
            },
            {
                path: "details/:id",
                components: {
                    patternView: PatternDetail,
                }
            },
        ]
    },
    {
        path: "/about-us",
        name: "about-us",
        components: {
            headerComponent: navigation,
            default: AboutUsPage,
            footerComponent: footer
        }
    },
    {
        path: "/blogs",
        name: "blogs",
        components: {
            headerComponent: navigation,
            default: BlogPage,
            footerComponent: footer,
        },
        meta: {title: 'Blogs | Product thinking blogs | IT Leadership blogs | DevOps blogs | DDD blogs | Software in Rhythm'},
    },
    {
        path: "/.*",
        name: "all",
        components: {
            headerComponent: navigation,
            default: () => import('@/components/home/HomePage'),
            footerComponent: footer
        },
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                top: -10,
            }
        } else {
            return {x: 0, y: 0};
        }
    }
});

router.beforeEach((to, from, next) => {
    // Get the page title from the route meta data that we have defined
    // See further down below for how we setup this data
    const title = to.meta.title
// If the route has a title, set it as the page title of the document/page
    if (title) {
        document.title = title
    } else {
        document.title = 'Software in Rhythm | IT Leadership | Fractional CTO | DevOps | Team Topologies'
    }
    // Continue resolving the route
    next()
});

trackRouter(router);
export default router;