<template>
    <div>
      <poster_header></poster_header>
      <training_productthinking></training_productthinking>
    </div>
  </template>
  <script>
  import postHeader from "@/components/poster-header-small";
  import training_productthinking from "@/components/training/training-product-thinking";
  export default {
    name: 'product-thinking-training-page',
    components: {
      poster_header: postHeader,
      training_productthinking: training_productthinking
    },
    metaInfo: {
      title: 'Value Driven Product Thinking Course | Software in Rhythm',
    }
  };
  </script>