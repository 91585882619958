import script from "./services.vue?vue&type=script&setup=true&lang=js"
export * from "./services.vue?vue&type=script&setup=true&lang=js"

import "./services.vue?vue&type=style&index=0&id=07c73893&lang=css"
/* custom blocks */
import block0 from "./services.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__