export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software in Rhythm"])}
      },
      "nl": {
        "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software in Rhythm"])}
      }
    }
  })
}
