<template>
  <div>
    <poster_header></poster_header>
    <services>
    </services>
  </div>
</template>
<script>
import postHeader from "@/components/poster-header-small";
import services from "@/components/services/services";

export default {
  name: 'ServicesPage',
  components: {
    poster_header: postHeader,
    services: services
  },
  metaInfo: {
    title: 'Services | Software in Rhythm',
  }
};
</script>
