<template>
  <div>
    <poster_header></poster_header>
    <aboutUs></aboutUs>
  </div>
</template>
<script>
import postHeader from "@/components/poster-header-small";
import aboutUs from "@/components/about-us/about-us";

export default {
  name: 'AboutUsPage',
  components: {
    poster_header: postHeader,
    aboutUs: aboutUs
  },
};
</script>
