export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTO Partnership"])},
        "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CTO Partnership is the solution for part time IT leadership. We work in a partnership, which means that we aim for long-term success. "])},
        "introduction2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ways we help with our partnership:"])},
        "introduction_li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As fractional/part time CTO or IT Lead/Manager"])},
        "introduction_li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As coach or consultant for big IT transitions (cloud, devops, etc)."])},
        "introduction_li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As coach for  IT leadership, like a CTO or IT Lead/Manager"])},
        "introduction_li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a sparring partner for IT management or the CTO"])},
        "introduction_li5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As sparring partner for principal IT engineers of architecten"])},
        "approach_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A partnership, not just contracting"])},
        "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do this in a phased approach with attention and care for the design, development and safeguarding of a strong IT organization that serves the business objectives. Once the CTO role can be filled permanently, we will help with the selection of candidates and, as long as the need exists, we will remain involved and provide periodic advice and guidance. Creating a high-performance IT organization does not happen overnight."])},
        "approach2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The IT Leadership Partnership consists of a phased approach with attention to the plan, implementation, and tranfer of a strong IT organisation that services the business goals."])},
        "approach3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above we describe a typical course of a collaboration where the intensity naturally varies depending on the situation. We know that the moment of 'standing on our own two feet' is actually the most important and we continuously work towards that. No matter how nice a collaboration is, it is healthy to gradually reduce it to a situation where the company meets the needs on its own."])},
        "approach4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will also stay in touch then. Technology, products and ways of working continue to develop and we will provide advice and assistance on new issues. Creating a high-performance IT organization does not happen overnight! That is one of the reasons why a partnership is a lasting relationship."])},
        "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the benefits of a CTO Partnership at a glance:"])},
        "sub1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timing"])},
        "sub1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiting a CTO is a big decision that often depends on a number of factors such as sufficient turnover, growth or profitability. To prevent strategic decisions and direction from being made too late, a CTO partnership is the solution."])},
        "sub2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost savings"])},
        "sub2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiring a full-time CTO is a significant cost item and difficult to justify, while strategic technical choices have to be made. A CTO partnership, on the other hand, offers the expertise you need, at the right time, at a fraction of the cost."])},
        "sub3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibility"])},
        "sub3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects, transformations and business growth all have specific timelines with their own phasing. We adapt to the changing needs of the business and so services can scale up or down"])},
        "sub4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertise"])},
        "sub4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A CTO partnership brings a wealth of experience and a broad view of things, which makes it possible to avoid common pitfalls and seize opportunities. It means insights and advice based on experience in the field, in order to make informed decisions."])},
        "sub5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategic insight"])},
        "sub5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are able to map technological initiatives onto business objectives at a strategic level. To identify opportunities for technological innovation, determine what resources are best suited for it and develop technology roadmaps that support business goals."])},
        "sub6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed"])},
        "sub6_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We get to work immediately to accelerate your technological initiatives so you stay ahead of your competition. We will quickly gain insight into your business, identify areas for improvement and implement solutions, saving you valuable time."])},
        "sub7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical leadership"])},
        "sub7_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is very possible that there will come a time when all the conditions for hiring a CTO are in place. We will help you to coach and train people within the organization to become CTOs as well as to recruit external candidates."])}
      },
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Leadership Partnership"])},
        "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het IT Leadership Partnership is dé oplossing voor situaties waar de randvoorwaarden voor full-time IT Leiderschap nog ontbreken. Het is een duurzame samenwerking gericht op snelle resultaten, maar met oog op de lange termijn."])},
        "introduction2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De manieren waarop we binnen het partnership helpen:"])},
        "introduction_li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als fractional/deeltijd CTO of IT Lead/Manager"])},
        "introduction_li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als begeleider bij grote IT transities (cloud, devops, etc)."])},
        "introduction_li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als coach voor IT leadership rollen als CTO of IT Lead/Manager"])},
        "introduction_li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als sparringpartner voor IT management of de CTO"])},
        "introduction_li5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als sparringpartner voor principal IT engineers of architecten"])},
        "approach_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een partnership, en geen detachering"])},
        "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het IT Leadership Partnership bestaat uit een gefaseerde aanpak met aandacht en zorg voor de opzet, uitwerking en borging van een sterke IT organisatie die de zakelijke doelstellingen dient."])},
        "approach2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In tegenstelling tot detachering of consultancy modellen kijken wij uit naar een duurzaam partnerschap. We inventariseren bij de start en doen een voorstel voor het traject. Naargelang de behoefte, komen we samen (waar nodig met experts uit het netwerk) en volgt er een intensieve samenwerkingsperiode."])},
        "approach3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hierboven beschrijven wij een gangbaar verloop van een samenwerking waar de intensiteit uiteraard afhankelijk van de situatie varieert. Wij weten dat het moment van 'op eigen benen staan' eigenlijk het belangrijkste is en werken daar continu naar toe. Hoe fijn een samenwerking ook is, het is gezond om deze geleidelijk af te bouwen naar een situatie waar de onderneming op eigen kracht voorziet in de behoefte."])},
        "approach4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ook dan blijven we in contact. Technologie, producten en manieren van werken blijven zich ontwikkelen en we zullen bij nieuwe vraagstukken met raad en daad bijstaan. Het neerzetten van een high-performance IT organisatie gaat niet over één nacht ijs! Dat is één van de redenen waarom een partnerschap een duurzame relatie is."])},
        "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een aantal van de voordelen:"])},
        "sub1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorkomen ipv genezen"])},
        "sub1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het werven van een IT Leiderschapsrol is een grote beslissing die vaak afhankelijk is van een aantal factoren zoals voldoende omzet, groei of winstgevendheid. Om toch te voorkomen dat er te laat strategische beslissingen worden genomen en richting wordt gegeven is het IT leiderschap partnership de oplossing."])},
        "sub2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenbesparing"])},
        "sub2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het aannemen van een fulltime IT Leiderschapsrol is een aanzienlijke kostenpost en lastig te verantwoorden terwijl er wel strategische technische keuzes gemaakt moeten worden.  Het partnership daarentegen biedt de expertise die je nodig hebt, op het juiste moment tegen een fractie van de kosten."])},
        "sub3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibiliteit"])},
        "sub3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projecten, transformaties en bedrijfsgroei hebben alle specifieke tijdslijnen met hun eigen fasering. Wij passen ons aan aan de veranderende behoeften van het bedrijf en dus kunnen diensten op- of afschalen."])},
        "sub4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertise"])},
        "sub4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het partnership brengt een schat aan ervaring en een brede kijk op zaken met zich mee, Dit maakt het mogelijk om veelvoorkomende valkuilen te vermijden en kansen te benutten. Het betekent inzichten en advies op basis van ervaring in het veld, om zo geïnformeerde beslissingen te kunnen nemen."])},
        "sub5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategisch inzicht"])},
        "sub5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij in staat om op strategisch niveau technologische initiatieven af te plotten op zakelijke doelstellingen. Om mogelijkheden voor technologische innovatie te identificeren, te bepalen wat voor resources daar het meest geschikt voor zijn en om technologische roadmaps te ontwikkelen die de bedrijfsdoelen ondersteunen."])},
        "sub6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snelheid"])},
        "sub6_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij gaan direct aan de slag om zo je technologische initiatieven te versnellen waardoor je concurrentie voorblijft. Wij hebben snel inzicht in je bedrijf, zullen gebieden voor verbetering identificeren en oplossingen implementeren, wat je waardevolle tijd bespaart."])},
        "sub7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical leadership"])},
        "sub7_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het is zeer goed mogelijk dat er een moment komt dat alle voorwaarden om een IT Leiderschapsrol te vervullen er zijn. Wij helpen je om zowel binnen de organisatie mensen te coachen en op te leiden als om de werving van externe kandidaten te vervullen."])}
      }
    }
  })
}
