export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventstorming workshop"])},
        "training_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building software is an increasingly more complex, multi-domain, multi-disciplinary engagement. In order to succeed and thrive we need tools and approaches that bring our best and bright minds together to produce designs that are ready for implementation."])},
        "training_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a history in leading projects. They come in every flavor and size, from greenfields, to migration of systems that have been serviced for over a decade. Over the years, we built up a range of processes, tools, and workshop techniques. Eventstorming is one of those techniques where everything comes together. We have gained experience designing using Eventstorming, and also implemented systems according to those designs and realised the value of this type of workshop. If the project is still in early stage, or needs a strong foundation, we can also start with a product vision session, and/or interaction design. Furthermore, we can design and guide the implementation of such a system."])},
        "training_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every workshop is custom and details will need to be discussed. For inspiration we can provide some options:"])},
        "training_p4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project bootstrap workshop"])},
        "training_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A half/full-day pressure cooker that takes your project initiation to the next level! After a full day of immersion, your team will have a solid understanding of the complexity, bottlenecks, and will be able to identify application domains. Starting with a shared understanding and a big picture will speed up project bootstrapping."])},
        "training_p5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full design workshop"])},
        "training_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A two-four day workshop that starts by creating a high-level picture of the entire domain. Afterwards, iterations will add more detail and gradually progresses more to a practical engineering level. This is a great way if you have development capacity in multiple teams but need a way to partition work streams. We can take modern architecture practices like microservices, and DDD paradigms into account. When desired, we take modern practices like DevOps, Safe, and 'Team Topologies' philosophy into account."])}
      },
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventstorming workshop"])},
        "training_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het maken van software is steeds meer een complex, multi-domain, multidisciplinaire aangelegenheid. Om dit succesvol te doen hebben we tools, processen en een aanpak nodig die het beste haalt uit jouw experts. Om zo tot designs te komen die geschikt zijn voor implementatie."])},
        "training_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij hebben een achtergrond in het leiden van IT projecten. In elke maat, grote, volwassenheid en diversiteit. Van nieuwbouw tot migratie, van startup tot enterprise. In de jaren hebben we een breed scala aan tools, processen, en workshoptechnieken opgedaan. Eventstorming is een prachtige techniek waar alles samen komt. Wij hebben ervaring met het begeleiden van Eventstorming workshops, het deelnemen als engineer, tot het implementeren van hieruit voort vloeiende ontwerpen. Als het project nog in een vroeg stadium is (of een aanscherping nodig heeft) kunnen we starten met een productvisie sessie. Eventueel kunnen we helpen bij het inrichten van de organisatie rondom de implementatie en de bijhorende architectuur."])},
        "training_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elke workshop is maatwerk en details worden samen ingevuld. Voor inspiratie hebben we enkele opties:"])},
        "training_p4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project bootstrap workshop"])},
        "training_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een halve of hele dag als pressure cooker waar vanuit niets een complete plaat van het systeem (of zelfs het IT landschap) wordt opgebouwd! Na een hele dag ondergedompeld te zijn heeft het team een sterk begrip van het domein, de complexiteit en bottlenecks of probleemgebieden. Een project starten met een gedeelde veronderstelling is een enorme versneller voor uw project."])},
        "training_p5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full design workshop"])},
        "training_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een twee tot vier daagse workshop waar we starten met een high-level overzicht op het domein. Daarna itereren we opnieuw en opnieuw waar detaillering wordt aangebracht tot uiteindelijk het implementatieniveau. Dit is de perfecte manier om je domein op te breken in mooie stromen werk en de beste manier om afhankelijkheid en samenwerking goed op te zetten. We nemen de architectuurdoelstelling (bv microservices) mee in de discussies en beslissingen en bijvoorbeeld het hoe het design het beste kan landen in een domain-driven-design paradigma. Indien gewenst kunnen we ook de implementatie voor een organisatie met DevOps, Safe, en 'Team Topologies' meenemen."])}
      }
    }
  })
}
