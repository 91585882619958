export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find the blogs we regularly write about topics that we think are worth sharing with you."])}
      },
      "nl": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier vindt u de blogs die we regelmatig schrijven over onderwerpen die we de moeite vinden om met u te delen."])}
      }
    }
  })
}
