<template>
  <div>
    <poster_header></poster_header>
    <training_topologies></training_topologies>
  </div>
</template>
<script>
import postHeader from "@/components/poster-header-small";
import training_topologies from "@/components/training/training-team-topologies";

export default {
  name: 'team-topologies-training-page',
  components: {
    poster_header: postHeader,
    training_topologies: training_topologies
  },
  metaInfo: {
    title: 'Team topologies training | Software in Rhythm',
  }
};
</script>
