export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our services"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivering the right product the right way at the right time is our core expertise. It takes a carefully structured organization and a holistic approach to be able to do so. Below are some of the subjects we helped, and are still helping, numerous customers with:"])},
        "sub1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Becoming Cloud native"])},
        "sub1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most organizations have tried cloud technology or even switched completely, however we notice that many organizations fail to get the benefits out of these transitions. We can help when:"])},
        "sub1_desc2": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery of new features is still slow"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The migration to the cloud takes (too) long"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Architecture got increasingly complex"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiring new employees is difficult, Skills are not matching the IT landscape"])},
          
        ],
        "sub2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platforms & platform teams"])},
        "sub2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximizing the delivery of business value is only possible when supporting platforms and sub-system teams also strive to reach the same goals as the (customer-facing feature) teams. We provide guidance, training and practical methods on how to set up and run in-company platform teams. We have extensive experience in:"])},
        "sub2_desc2": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building solid platform teams"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating platforms as products"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of inner source and community driven Platforms"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage the culture and organizational transition"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewing your team structure and organization, Training"])},
          
        ],
        "sub3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DevOps transformation"])},
        "sub3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getting the full benefits from a transformation to a DevOps organization is pretty complex. It touches upon all aspects of an organisation and their products, and is absolutely not only of interest to the IT department. When done right DevOps as an organizational structure can deliver enormous value to both the company as their customers. We know all about:"])},
        "sub3_desc2": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting the desired outcomes DevOps should provide for"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applying DevOps as organization model"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training and hands-on consultancy"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improving Continuous Integration and Delivery"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building an engineering culture"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aligning Domain models"])},
          
        ],
        "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How we work"])},
        "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different organizations require different tactics. We have formulated some of our common types of interactions with clients."])},
        "sub4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management coaching"])},
        "sub4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need a second pair of eyes, inspiration or a sparring partner? We know from experience that IT transformations are complicated and delicate. Every day it seems we need to make hundreds of irreversible decisions and still have to be on top of everything. We offer coaching based on an experience in IT transformations."])},
        "sub5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meetups & trainings"])},
        "sub5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are happy to do an on-site or online knowledge session for your organization or Meetup group. We can tailor our content completely to your needs but also offer a wide range of pre-made trainings."])},
        "sub5_desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a look at our "])},
        "sub5_desc3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to see what subjects suits your situation."])},
        "sub6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultancy"])},
        "sub6_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some situations require dedicated attention from experienced people. We are happy to help with digital transformation projects or transformations to a new way of working, using a strong hands-on approach."])}
      },
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze diensten"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het leveren van het juiste product op de juiste manier op het juiste moment is onze kernexpertise. Daarvoor is een zorgvuldig gestructureerde organisatie en een holistische benadering nodig. Hieronder vind je een aantal onderwerpen waarmee we talloze klanten hebben geholpen en nog steeds helpen:"])},
        "sub1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud native worden"])},
        "sub1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De meeste organisaties hebben cloud technologieën uitgeprobeerd of zijn zelfs helemaal overgestapt, maar we merken dat veel organisaties er niet in slagen om de voordelen uit deze transities te halen. We kunnen helpen wanneer:"])},
        "sub1_desc2": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levering van nieuwe features nog steeds te traag is"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De migratie naar de cloud (te) lang duurt, Architectuur steeds complexer wordt"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe medewerkers aannemen moeilijk is"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaardigheden van de medewerkers niet passen bij het IT-landschap"])},
          
        ],
        "sub2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platformen & platform teams"])},
        "sub2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het maximaliseren van de levering van bedrijfswaarde is alleen mogelijk als platforms en subsysteem teams er ook naar streven om dezelfde doelen te bereiken als de feature of end-to-end teams. Wij bieden begeleiding, training en praktische methodes voor het opzetten en runnen van in-company platform teams. We hebben uitgebreide ervaring in:"])},
        "sub2_desc2": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het creëren van solide platform teams"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het creëren van platforms als producten"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het creëren van inner-source community gestuurde platforms"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management van de cultuur en organisatie transitie"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herziening van uw teamstructuur en organisatie"])},
          
        ],
        "sub3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DevOps-transformatie"])},
        "sub3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het volledige voordeel halen uit een transformatie naar een DevOps-organisatie kan erg complex zijn. De verandering raakt alle aspecten van een organisatie en haar producten en is absoluut niet alleen van belang voor de IT-afdeling. Als de transformatie goed wordt gedaan, kan DevOps als organisatiestructuur enorme waarde opleveren voor zowel het bedrijf als hun klanten. We weten alles over:"])},
        "sub3_desc2": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bepalen van de gewenste resultaten die DevOps moet bieden"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het toepassen van DevOps als organisatiemodel"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training en hands-on advies"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouwen aan een engineering cultuur"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeinmodellen op elkaar afstemmen en tweaken"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbeteren van Continuous Integration and Delivery"])},
          
        ],
        "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe we werken"])},
        "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschillende organisaties hebben verschillende tactieken nodig. We hebben enkele van onze meest voorkomende typen interacties met klanten beschreven"])},
        "sub4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management coaching"])},
        "sub4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een tweede paar ogen, inspiratie of een sparringpartner nodig? Uit ervaring weten we dat IT-transformaties ingewikkeld en delicaat zijn. Elke dag lijkt het erop dat we honderden onomkeerbare beslissingen moeten nemen en toch overal bovenop moeten zitten. Wij bieden coaching die gebaseerd is op jarenlange ervaring in IT transformaties."])},
        "sub5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meetups & trainingen"])},
        "sub5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij verzorgen graag een kennissessie op locatie of online voor uw organisatie of Meetup groep. We kunnen onze inhoud volledig op uw behoeften afstemmen, maar bieden ook een breed scala aan kant-en-klare trainingen."])},
        "sub5_desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk onze "])},
        "sub5_desc3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" om te zien welke onderwerpen bij uw situatie passen."])},
        "sub6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultancy"])},
        "sub6_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommige situaties vragen om toegewijde aandacht van ervaren mensen. Wij helpen graag bij digitale transformatie projecten of transformaties naar een nieuwe manier van werken waarbij een sterke hands-on aanpak hanteren."])}
      }
    }
  })
}
