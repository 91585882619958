<template>
  <section class="page-section " id="patterns">
    <div class="container">
      <div class="text-left">
        <h2 class="section-heading text-uppercase">Software management patterns</h2>
        <h3 class="section-subheading text-muted" v-if="pattern"><strong>Pattern: </strong> {{ pattern.title }}</h3>
      </div>
      <div class="row" v-if="pattern">
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-2"><h5>Symptoms</h5></div>
            <div class="col-lg-10 text-left">
              <p v-for="(symptom,index) in pattern.symptoms" v-bind:key="index">{{ symptom }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-2"><h5>Impact</h5></div>
            <div class="col-lg-10 text-left">
              <p v-for="(impactItem,index) in pattern.impact" v-bind:key="index">{{ impactItem }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-2"><h5>Remedy</h5></div>
            <div class="col-lg-10 text-left">
              <p v-for="(remedy,index) in pattern.remedies" v-bind:key="index">{{ remedy }}</p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-12 text-left">
              <router-link :to="{name:'patterns-list'}">Back to the list</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-3 text-left">
          <div class="row mb-4" v-if="relatedPatterns.length>0">
            <div class="col-lg-12 text-left">
              <h5 class="text-left">Related patterns</h5>
              <ul>
                <li v-for="pattern in relatedPatterns"
                    v-bind:key="pattern.id">
                  <a
                      v-bind:href="'/patterns/details/'+pattern.id" class="text-truncate d-block"
                      v-bind:alt="pattern.title">{{ pattern.title }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="row mb-4" v-if="pattern.references.length>0">
            <div class="col-lg-12 text-left">
              <h5 class="text-left">Links for this pattern</h5>
              <ul>
                <li v-for="reference in pattern.references"
                    v-bind:key="reference.title">
                  <a
                      v-bind:href="reference.link" class="text-truncate d-block"
                      target="_blank"
                      v-bind:alt="reference.title">{{ reference.title }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="row" v-if="pattern">
            <!--            <div class="col-lg-12 text-left">-->
            <h5>Share this pattern</h5>
            <p>Have you found this pattern useful, and like to share it with anyone? Please share! </p>
            <a v-bind:href="
                   'https://twitter.com/intent/tweet?text=I have found an interesting software delivery pattern: '
                   +pattern.title
                   +' Read it on: https://www.softwareinrhythm.nl/patterns/details/'+pattern.id" data-size="large" target="_blank">
              <span class="fa-2x">
                <font-awesome-icon  :icon="['fab','twitter']" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {PATTERNS} from "@/components/pattern/patterns";

export default {
  name: 'pattern-details',
  metaInfo: {
    title: 'Software management patterns | Software in Rhythm',
  },
  data: () => {
    return {
      relatedPatterns: [],
      id: null,
      pattern: null,
    }
  },
  created: function () {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.pattern = PATTERNS.patterns.find(pattern => pattern.id === this.id);
    } else {
      this.pattern = null
    }
    this.relatedPatterns = [];
    const categoriesInArticle = PATTERNS.patterns.find(pattern => pattern.id === this.id).category;

    categoriesInArticle.forEach(category => {
      const patternsWithMatchingCategory = PATTERNS.patterns.filter(pattern => pattern.category.includes(category))
      this.relatedPatterns = this.relatedPatterns.concat(patternsWithMatchingCategory);
      this.relatedPatterns = this.relatedPatterns
          .sort((a, b) => {
            if (a.id < b.id) {
              return -1
            } else if (b < a) {
              return 1;
            }
            return 0
          })
          .filter(function (value, index, array) {
            return (index === 0) || (value.id !== array[index - 1].id);
          }).filter(pattern => pattern.id !== this.id);
    });
  },
};
</script>
