<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
  <section class="page-section" id="offering">
    <div class="container">
      <div class="text-left">
        <h1 class="section-heading text-uppercase"> {{ t("title")}} </h1>
        <div class="row my-5" id="portfolio">
          <div class="col-lg-8 col-sm-8 mb-8">
            <p class="section-subheading text-muted justified"> {{ t("training_p1")}} </p>
              <ul class="text-muted">
                <li> {{ t("training_p1_lo1")}} </li>
                <li> {{ t("training_p1_lo2")}} </li>
                <li> {{ t("training_p1_lo3")}} </li>
              </ul>
            <p class="section-subheading text-muted justified"> {{ t("training_p2")}} </p>
            <img class="card-img" src="/assets/img/training/product-owner-course-types.png" alt="models">
            <h3> {{ t("training_p3_title")}} </h3>
            <p class="section-subheading text-muted justified"> {{ t("training_p3")}} </p>
            <img class="card-img" src="/assets/img/training/po-flow-final.png" alt="models">
            <h3> {{ t("training_p4_title")}} </h3>
            <p class="section-subheading text-muted justified">
                {{ t("training_p5_title")}}
              <ul class="text-muted">
                <li> {{ t("training_p5_lo1")}} </li>
                <li> {{ t("training_p5_lo2")}} </li>
                <li> {{ t("training_p5_lo3")}} </li>
              </ul>
                {{ t("training_p6_title")}}
              <ul class="text-muted">
                <li> {{ t("training_p6_lo1")}} </li>
                <li> {{ t("training_p6_lo2")}} </li>
                <li> {{ t("training_p6_lo3")}} </li>
              </ul>
              </p>
                <h3> {{ t("training_p7_title")}} </h3>
              <p class="section-subheading text-muted justified">
                {{ t("training_p8_title")}}
              <ul class="text-muted">
                <li> {{ t("training_p8_lo1")}} </li>
                <li> {{ t("training_p8_lo2")}} </li>
              </ul>
                {{ t("training_p9_title")}}
              <ul class="text-muted">
                <li> {{ t("training_p9_lo1")}} </li>
                <li> {{ t("training_p9_lo2")}} </li>
                <li> {{ t("training_p9_lo3")}} </li>
                <li> {{ t("training_p9_lo4")}} </li>
              </ul>
              </p>
                <h3> {{ t("training_p10_title")}} </h3>
              <p class="section-subheading text-muted justified">
                {{ t("training_p11_title")}}
              <ul class="text-muted">
                <li> {{ t("training_p11_lo1")}} </li>
                <li> {{ t("training_p11_lo2")}} </li>
                <li> {{ t("training_p11_lo3")}} </li>
                <li> {{ t("training_p11_lo4")}} </li>
                <li> {{ t("training_p11_lo5")}} </li>
              </ul>
                {{ t("training_p12_title")}}
              <ul class="text-muted">
                <li> {{ t("training_p12_lo1")}} </li>
                <li> {{ t("training_p12_lo2")}} </li>
                <li> {{ t("training_p12_lo3")}} </li>
              </ul>
              </p>
                <h3> {{ t("training_p13_title")}} </h3>
              <p class="section-subheading text-muted justified">
                {{ t("training_p14_title")}}
              <ul class="text-muted">
                <li> {{ t("training_p11_lo1")}} </li>
                <li> {{ t("training_p11_lo2")}} </li>
                <li> {{ t("training_p11_lo3")}} </li>
                <li> {{ t("training_p11_lo4")}} </li>
                <li> {{ t("training_p11_lo5")}} </li>
              </ul>
            </p>
            <p class="section-subheading text-muted justified"> {{ t("training_p15")}} </p>
            <hr>
            <h3> {{ t("training_p16_title")}} </h3>
            <p class="section-subheading text-muted justified"> {{ t("training_p16")}} </p>
          </div>
          <div class="col-lg-4 col-sm-4 mb-4">
            <img class="img-fluid" src="assets/img/training/beneath-surface.png" alt=""/>
            <div class="row pt-5">
              <router-link :to="{path:'/', hash:'#contact'}">
                <button class="btn btn-primary w-100" type="button">
                  Book a training
                </button>
              </router-link>
            </div>
            <div class="row pt-5">
              <h3 class="h5">{{ t("training_options") }}</h3>
              <p class="text-muted">{{ t("training_option_1") }}</p>
              <p class="text-muted">{{ t("training_option_2") }}</p>
              <p class="text-muted">{{ t("training_option_3") }}</p>
              <p class="text-muted"></p>
            </div>
            <div class="row pt-5 ">
              <h3 class="h5">{{ t("training_advantage_title") }}</h3>
              <ul class="text-muted px-4">
                <li>{{ t("training_ad_1") }}</li>
                <li>{{ t("training_ad_2") }}</li>
                <li>{{ t("training_ad_3") }}</li>
              </ul>
            </div>
            <div class="row pt-5">
              <h3 class="h5">{{ t("training_rel_blogs") }}</h3>
              <ul class="text-muted px-4">
                <li><a href="https://www.linkedin.com/pulse/practical-guide-getting-ideas-production-part-1-niels-talens/" target="_blank">A practical guide for getting ideas to production - Part 1</a></li>
                <li><a href="https://www.linkedin.com/pulse/practical-guide-getting-ideas-production-part-2-niels-talens/" target="_blank">A practical guide for getting ideas to production - Part 2</a></li>
                <li><a href="https://www.linkedin.com/pulse/practical-guide-getting-ideas-production-part-3-niels-talens/" target="_blank">A practical guide for getting ideas to production - Part 3</a></li>
                <li><a href="https://www.linkedin.com/pulse/only-4-activities-product-owner-niels-talens/" target="_blank">The only 4 activities of a Product Owner</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Practical Product Owner course",
    "training_p1": "The Practical Product Owner course is set up in a modulair fashion and according to the TED (Theory, Experience and Deliver) principle. This means that every session follows the same pattern:",
    "training_p1_lo1": "The theory and essence of a subject is explained",
    "training_p1_lo2": "The subject will be hands on and in depth experienced",
    "training_p1_lo3": "The exercise results in a ready and directly usable artefact",
    "training_p2": "The sequence of the sessions will be determined by the direct needs of the organisation. If the need for improvement is the highest in the delivery process we will start there and work our way towards the strategy. In other cases we start with the why and work our way towards end user feedback:",
    "training_p3_title": "Content of the course",
    "training_p3": "The course is designed to be 7 group sessions with hands-on coaching in between these sessions. The level and intensity of coaching depends on factors like group size, complexity, level of both the organisation as product and experience of participants. The sessions are logically grouped together based on certain phases in the whole system from strategy to usage of the products:",
    "training_p4_title": "Objectives phase",
    "training_p5_title": "Session 1: Strategy",
    "training_p5_lo1": "Systems thinking",
    "training_p5_lo2": "Strategy",
    "training_p5_lo3": "Business case – Business model canvas/Lean canvas",
    "training_p6_title": "Session 2: Product high level ",
    "training_p6_lo1": "Product Vision (Describe the essence of your product: why, for who, what and how)",
    "training_p6_lo2": "Personas",
    "training_p6_lo3": "Stakeholder management/mapping",
    "training_p7_title": "Input phase",
    "training_p8_title": "Session 3: Product specific",
    "training_p8_lo1": "Impact mapping (set business goals and the way and means to achieve these goals)",
    "training_p8_lo2": "Event storming (create a collective understanding with business, IT and stakeholder)",
    "training_p9_title": "Session 4: High level planning",
    "training_p9_lo1": "Prioritisation methods",
    "training_p9_lo2": "Roadmapping",
    "training_p9_lo3": "Story mapping",
    "training_p9_lo4": "Dependency mapping",
    "training_p10_title": "Output phase",
    "training_p11_title": "Session 5: Planning to sprint",
    "training_p11_lo1": "Epic goals",
    "training_p11_lo2": "User flows",
    "training_p11_lo3": "UI/UX",
    "training_p11_lo4": "Datamodel",
    "training_p11_lo5": "Acceptance criteria",
    "training_p12_title": "Session 6: Technical practises explained for non-tech people",
    "training_p12_lo1": "CI/CD",
    "training_p12_lo2": "Automated testing",
    "training_p12_lo3": "ITSM & Agile & DevOps",
    "training_p13_title": "Outcomes phase",
    "training_p14_title": "Session 7: Feedback",
    "training_p14_lo1": "Gathering feedback",
    "training_p14_lo2": "Required data to validate objectives",
    "training_p14_lo3": "Different kinds of feedback",
    "training_p14_lo4": "Feedback systems",
    "training_p15": "Note that this workshop does not focus on Scrum processes. During the workshop we actually produce real artefacts that are the basis for your products and backlog.",
    "training_p16_title": "After the workshop",
    "training_p16": "After this workshop participants will have a clear view on the full scope of Product Ownership which is much broader than merely Scrum or the backlog. Participants will know about, and have practical experience with, numerous practical tools and activities which they can use right away. Participants will have knowledge on the toolset to organise and structure the flow from idea to customer feedback.",
    "training_book": "Book a training",
    "training_options": "Training options",
    "training_option_1": "Language: Dutch/English",
    "training_option_2": "Duration: 7 sessions",
    "training_option_3": "Location: Onsite / hosted venue",
    "training_advantage_title": "Training advantages",
    "training_ad_1": "A clear view on the full scope of Product Ownership",
    "training_ad_2": "Participants will know about, and have practical experience with, numerous practical tools and activities which they can use right away.",
    "training_ad_3": "Participants will have knowledge on the toolset to organise and structure the flow from idea to customer feedback.",
    "training_rel_blogs": "Related blogs"
  },
  "nl": {
    "title": "Practische Product Owner leergang",
    "training_p1": "De Praktische Product Owner leergang is modulair en volgens het TED-principe (Theory, Experience and Deliver) opgezet. Dit betekent dat elke sessie hetzelfde patroon volgt:",
    "training_p1_lo1": "De theorie en essentie van een onderwerp wordt uitgelegd",
    "training_p1_lo2": "Het onderwerp wordt hands-on en op basis van echte cases ervaren",
    "training_p1_lo3": "De oefening resulteert in een kant-en-klaar uitgewerkt artefact",
    "training_p2": "De volgorde van de sessies wordt bepaald door de directe behoeften van de organisatie. Als de behoefte aan verbetering het hoogst is in het delivery proces, dan beginnen we daar en naar de strategie toe werken. In andere gevallen beginnen we juist met het waarom en werken we toe naar feedback van eindgebruikers:",
    "training_p3_title": "Inhoud van de leergang",
    "training_p3": "De cursus is ontworpen als 7 sessies met hands-on coaching in de periodes tussen deze sessies. Het niveau en de intensiteit van de coaching is afhankelijk van factoren als groepsgrootte, complexiteit, niveau van zowel de organisatie als het product en de ervaring van deelnemers. Dit alles in goed overleg. De sessies zijn logisch gegroepeerd op basis van bepaalde fasen in het hele systeem van strategie tot gebruik van de producten:",
    "training_p4_title": "Doelstellingen fase",
    "training_p5_title": "Session 1: Strategie",
    "training_p5_lo1": "Systeemdenken",
    "training_p5_lo2": "Strategie en strategische doelen",
    "training_p5_lo3": "Business case – Business model canvas/Lean canvas",
    "training_p6_title": "Session 2: Product high level",
    "training_p6_lo1": "Product Visie (Beschrijf de essentie van uw product: waarom, voor wie, wat en hoe)",
    "training_p6_lo2": "Persona's",
    "training_p6_lo3": "Stakeholder management/mapping",
    "training_p7_title": "Input fase",
    "training_p8_title": "Session 3: Productspecifiek",
    "training_p8_lo1": "Impact Mapping (bedrijfsdoelen en de manier en middelen om deze doelen te bereiken)",
    "training_p8_lo2": "Event storming (creëer een collectief begrip met business, IT en belanghebbenden)",
    "training_p9_title": "Session 4: High level planning",
    "training_p9_lo1": "Prioriteringmethoden",
    "training_p9_lo2": "Roadmapping",
    "training_p9_lo3": "Story mapping",
    "training_p9_lo4": "Dependency mapping",
    "training_p10_title": "Output phase",
    "training_p11_title": "Session 5: Roadmap to sprint",
    "training_p11_lo1": "Epic goals",
    "training_p11_lo2": "User flows",
    "training_p11_lo3": "UI/UX",
    "training_p11_lo4": "Datamodel",
    "training_p11_lo5": "Acceptancie criteria",
    "training_p12_title": "Technische praktijken uitgelegd voor non-techies",
    "training_p12_lo1": "CI/CD",
    "training_p12_lo2": "Geautomatiseerd testen",
    "training_p12_lo3": "ITSM & Agile & DevOps",
    "training_p13_title": "Resultaten fase",
    "training_p14_title": "Session 7: Feedback",
    "training_p14_lo1": "Verzamelen van feedback",
    "training_p14_lo2": "Benodigde data om doelstellingen te valideren",
    "training_p14_lo3": "Verschillende soorten feedback",
    "training_p14_lo4": "Feedback systemen",
    "training_p15": "Let op: deze workshop is niet gericht op Scrum processen. Tijdens de workshop maken we daadwerkelijk echte artefacten die de basis vormen voor uw producten en backlog.",
    "training_p16_title": "Na de workshop",
    "training_p16": "Na deze workshop hebben de deelnemers een duidelijk zicht op de volledige scope van Product Ownership die veel breder is dan alleen Scrum of de backlog. Deelnemers kennen, en hebben praktische ervaring met tal van tools en activiteiten waar ze direct mee aan de slag kunnen in hun dagelijks werk. Deelnemers hebben kennis van de toolset om de stroom van idee naar klantfeedback te organiseren en te structureren.",
    "training_book": "Book a training",
    "training_options": "Training opties",
    "training_option_1": "Taal: Nederlands/Engels",
    "training_option_2": "Duration: 7 sessions",
    "training_option_3": "Locatie: op locatie / hosted venue",
    "training_advantage_title": "Training voordelen",
    "training_ad_1": "Biedt duidelijk zicht op de volledige scope van Product Ownership",
    "training_ad_2": "Deelnemers krijgen praktische ervaring met tal van tools en activiteiten waar ze direct mee aan de slag kunnen in hun dagelijks werk. ",
    "training_ad_3": "Deelnemers kennen de toolset om de stroom van idee naar klantfeedback te organiseren en te structureren.",
    "training_rel_blogs": "Gerelateerde blogs"
  }
}
</i18n>

<script>

const lang = {
  "d":'d'
}

export default {
  name: 'TrainingProductowner'
};
</script>
