export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team topologies training"])},
        "training_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During this training participants will learn all there is to know about organizing business and technology teams in order to serve the business goals in an optimal way."])},
        "training_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In many organizations, teams are set up in such a way that it appears very clear and logical on the organization chart, but in reality it is very difficult to achieve the speed and quality that customers and users expect. There is a lot of mutual interaction, dependencies and employees must have knowledge of many topics that are not necessarily needed for their daily work (a high cognitive load)."])},
        "training_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current setup often prevents organizations from really applying product thinking, where customers and users frequently receive qualitative features."])},
        "training_p4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building high-performance organizations & teams"])},
        "training_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The training provides a practical hands-on structure for organizational design and team interaction and the identification of bottlenecks. Not only does the training provide insights in both the current situation and the desired end state but gives you also the practical steps on how to get there, tailored to your situation."])},
        "training_p5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning objectives"])},
        "training_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upon completion of this training participants will know:"])},
        "training_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How different team patterns are used by successful organizations."])},
        "training_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The team patterns that are best suited for your situation and products."])},
        "training_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When and why to use different team patterns."])},
        "training_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to create a mapping of your current and the desired organizational design and team interactions."])},
        "training_lo5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practical steps on how to gradually transform from the current to the desired state."])},
        "training_lo6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to develop your own framework for tailor-made change."])},
        "training_lo7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What software engineering performance strategies are and their importance."])},
        "training_lo8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the concepts of team topologies."])},
        "training_lo9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to identify performance opportunities."])},
        "training_lo10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How DORA metrics relate to performance."])},
        "training_lo11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How these things relate to modern software architectures."])},
        "training_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a training"])},
        "training_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training options"])},
        "training_option_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language: Dutch/English"])},
        "training_option_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration: Program / 1 day / half day"])},
        "training_option_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location: Onsite / hosted venue"])},
        "training_advantage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training advantages"])},
        "training_ad_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understand Team Topologies"])},
        "training_ad_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimize team performance"])},
        "training_ad_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Align people, organization, and IT"])},
        "training_ad_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtain new management techniques"])},
        "training_rel_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related blogs"])}
      },
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team topologies training"])},
        "training_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdens deze training leren de deelnemers alles wat er te weten valt over het organiseren van business- en IT teams om de bedrijfsdoelstellingen op een optimale manier te dienen. "])},
        "training_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In veel organisaties zijn teams zo ingericht zodat het op het organogram erg overzichtelijk en logisch lijkt maar het in werkelijkheid heel lastig is om de snelheid en kwaliteit te behalen die klanten en gebruikers verwachten. Zo is er veel onderlinge interactie, afhankelijkheden en moeten medewerkers kennis hebben van veel onderwerpen die niet perse nodig zijn voor de dagelijkse werkzaamheden (veel cognitieve belasting)."])},
        "training_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De huidige organisatiestructuur weerhoudt organisaties er veelal van om echt het product denken toe te passen waarbij klanten en gebruikers vaak kwalitatieve features krijgen. "])},
        "training_p4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High-performance organisaties en teams"])},
        "training_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De training biedt een praktische, hands-on structuur voor organisatieontwerp en teaminteractie en de identificatie van knelpunten. De training geeft niet alleen inzicht in zowel de huidige situatie als de gewenste eindtoestand, maar geeft ook de praktische stappen om daar te komen, afgestemd op uw situatie."])},
        "training_p5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerdoelen"])},
        "training_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na afloop van deze training weten de deelnemers:"])},
        "training_lo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat de verschillende team structuren die worden gebruikt door succesvolle organisaties zijn."])},
        "training_lo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke team structuren het beste passen bij uw situatie en producten."])},
        "training_lo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer en waarom verschillende team structuren te gebruiken."])},
        "training_lo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe u uw huidige en gewenste organisatieontwerp en team interacties in kaart brengt."])},
        "training_lo5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktische stappen om geleidelijk van de huidige naar de gewenste toestand te transformeren."])},
        "training_lo6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe een eigen framework te ontwikkelen voor verandering op maat."])},
        "training_lo7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke prestatie strategieën voor software-engineering zijn en hun belang."])},
        "training_lo8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de concepten van team topologieën."])},
        "training_lo9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer prestatiemogelijkheden te identificeren."])},
        "training_lo10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe DORA-statistieken betrekking hebben op prestaties."])},
        "training_lo11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe bovenstaande is gerelateerd aan het bouwen van moderne software architecturen."])},
        "training_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book een training"])},
        "training_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training mogelijkheden"])},
        "training_option_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal: Nederlands/Engels"])},
        "training_option_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duur: leerlijn / 1 dag / halve dagen"])},
        "training_option_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie: Onsite / hosted venue"])},
        "training_advantage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training voordelen"])},
        "training_ad_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begrip van Team Topologies"])},
        "training_ad_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimaliseren van team performance"])},
        "training_ad_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alignment tussen mensen, organisatie, en IT"])},
        "training_ad_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe managementtechnieken"])},
        "training_rel_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerelateerde blogs"])}
      }
    }
  })
}
