export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building high performance organizations is what makes us tick!"])},
        "kevin_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An engineering leader that has a thorough understanding of the state-of-the-art technologies as well as how to build a high-performance organizations to match it. I enjoy the diversity of work. Whether it is setting with a domain architecture, creating an engineering culture, speeding up development's output or creating a reference architecture. The end result should be a healthy, enjoyable working environment for engineers with great outcomes for the business."])},
        "niels_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For me the most important thing is finding ways to help companies in creating the best possible products and services. In this quest there are numerous tools, ways of working, environments, trends and frameworks that can help us in doing this secure, fast and reliable. Whether it is agile portfolio management or continuous delivery, Devops transitions or product visions, I always look for optimization the whole. For me this is the flow from strategic goals to customer feedback."])},
        "link_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more about me"])}
      },
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over ons"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bouwen van high performance organisaties is wat ons drijft!"])},
        "kevin_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een technisch leider die een grondige kennis heeft van de allernieuwste technologieën en die ook weet hoe hij een krachtige organisatie kan bouwen die hiermee uit de voeten kan. Ik geniet van de diversiteit van het werk. Of het nu gaat om het instellen met een domeinarchitectuur, het creëren van een engineering cultuur, het versnellen van de output van de ontwikkeling of het creëren van een referentiearchitectuur. Het eindresultaat moet een gezonde, plezierige werkomgeving voor ingenieurs zijn met geweldige resultaten voor het bedrijf."])},
        "niels_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor mij is het belangrijkste om manieren te vinden om bedrijven te helpen bij het creëren van de best mogelijke producten en diensten. In deze zoektocht zijn er tal van tools, werkwijzen, omgevingen, trends en frameworks die ons kunnen helpen om dit veilig, snel en betrouwbaar te doen. Of het nu gaat om agile portfoliomanagement of continuous delivery, Devops transities of product visies, ik zoek altijd naar optimalisatie van het geheel. Voor mij is dit de flow van strategische doelen naar klantfeedback."])},
        "link_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer over mij"])}
      }
    }
  })
}
