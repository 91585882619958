<template>
  <div>
    <poster_header></poster_header>
    <blogList></blogList>
  </div>
</template>
<script>
import postHeader from "@/components/poster-header-small";
import blogList from "./blog-list.vue";

export default {
  name: 'BlogsPage',
  components: {
    poster_header: postHeader,
    blogList: blogList,
  },
};
</script>
