<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n();
</script>

<template>
  <section class="page-section" id="offering">
    <div class="container">
      <div class="text-left">
        <h1 class="section-heading text-uppercase">{{ t("title") }}</h1>
        <div class="row my-5" id="portfolio">
          <div class="col-lg-8 col-sm-8 mb-8">
            <p class="section-subheading text-muted justified">{{ t("training_p1") }}</p>
            <h3>{{ t("training_p2_title") }}</h3>
            <p class="section-subheading text-muted justified">{{ t("training_p2") }}</p>
            <p class="section-subheading text-muted justified">{{ t("training_p3") }}</p>
            <h3>{{ t("training_p4_title") }}</h3>
            <p class="section-subheading text-muted justified">{{ t("training_p4") }}</p>
            <p class="section-subheading text-muted justified">{{ t("training_p5") }}</p>
            <p class="section-subheading text-muted justified">{{ t("training_p6") }}</p>
            <h3>{{ t("training_p7_title") }}</h3>
            <p class="section-subheading text-muted justified">{{ t("training_p7") }}</p>
            <h3>{{ t("training_p8_title") }}</h3>
            <p class="section-subheading text-muted justified">{{ t("training_p8") }}</p>
          </div>

          <div class="col-lg-4 col-sm-4 mb-4">
            <img class="img-fluid" src="assets/img/offering/eventstorming-kevin.png" alt=""/>
            <div class="row pt-5">
              <router-link :to="{path:'/', hash:'#contact'}">
                <button class="btn btn-primary w-100" type="button">
                  Book a training
                </button>
              </router-link>
            </div>
            <div class="row pt-5 ">
              <h3 class="h5">Training advantages</h3>
              <ul class="text-muted px-4">
                <li>Expert knowledge transfer</li>
                <li>Domain exploration</li>
                <li>Durable software designs</li>
                <li>Bootstrap your project/team</li>
                <li>System Architecture</li>
              </ul>
            </div>
            <div class="row pt-5">
              <h3 class="h5">Related blogs</h3>
              <ul class="text-muted px-4">
                <li><a
                    href="https://medium.com/codex/domain-driven-design-for-it-managers-285d92e723c1"
                    target="_blank">Domain-Driven Design for IT managers</a></li>
                <li><a
                    href="https://medium.com/codex/domain-driven-design-ddd-or-just-domain-oriented-design-dod-9098abecd456"
                    target="_blank">DDD or just domain oriented design?</a></li>
                <li><a href="https://kevin-van-ingen.medium.com/mix-event-storming-in-your-agile-toolset-437a54939aeb"
                       target="_blank">Embed eventstorming in your agile toolset</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<i18n lang="json">
{
  "en": {
    "title": "DDD for IT leadership",
    "training_p1": "A training domain Driven Design for IT leadership and managers.",
    "training_p2_title": "Why DDD and why IT leadership?",
    "training_p2": "One of the challenges in software product development is to correctly understand, describe, and verify what is needed. DDD offers insights and tools for efficient delivery in a scalable organization. It helps to set up the right organization, team, and communication structures. Where trends like agile and DevOps increased our pace to get feedback early, it often leaves room for improvement in finding the correct interpretation of our problem space.",
    "training_p3": "DDD helps to connect business and IT and to build the foundation of a healthy architecture and a modern software department. It’s crucial to get IT leadership and management onboard on domain-driven ideology.",
    "training_p4_title": "Short description of the training",
    "training_p4": "This training helps to create a strategy, and support for the adoption of domain-driven philosophy. More and more we see that domain-driven is an enabler for the modern enterprise. In order to make digitization a success we must support our business and software domains with the right structure. Despite having the cloud as a technical enabler, we need socio-technical enablers to support digitization fully. ",
    "training_p5": "This training starts with an overview of strategy IT and how it relates to DDD. We will get more insights on modern trends like domain-driven, microservices, the data mesh, team toplogoies and the socio-technical system in which they relate. We will learn how to manage and influence the adoption of these trends through best-practices and implementation patterns. We will use case studies to make this hands-on and recognizable. ",
    "training_p6": "Optionally, we can expand the training with an Eventstorming case. This offers an interactive workshop way of learning more on DDD and will make participants get a real feal on how it is to work on a DDD enabled field complex platform.",
    "training_p7_title": "Target audience",
    "training_p7": "This training is intended for people in roles such as software development manager, product owners, product managers, C-level management, architects, agile staff, IT managers and engineering leads, in short, anyone who wants to make software development teams a success but may not have the technical background of an engineer. Technical knowledge is certainly not required, but some experience in or around a software team is usefull.",
    "training_p8_title": "Preperation",
    "training_p8": "No preparation is required. In consultation, examples and assignments can be aimed at the domain of the participants."
  },
  "nl": {
    "title": "DDD voor IT leadership",
    "training_p1": "Een training domain Driven Design voor IT leadership en managers.",
    "training_p2_title": "Waarom DDD en waarom voor IT leadership?",
    "training_p2": "Eén van de grootste uitdagingen bij product development is het correct kunnen begrijpen, beschrijven en toetsen van hetgeen wat er nodig is. DDD biedt de inzichten en tools die  nodig zijn om zo effectief mogelijk de juiste producten te bouwen in een schaalbare organisatie. Het helpt de juiste organisatie-, team- en communicatiestructuren op te zetten. Waar we door manieren van werken als agile en organisatie structuren als DevOps sneller feedback krijgen is hier in de praktijk nog te veel ruimte voor verkeerde interpretaties en inefficiënte organisatie.",
    "training_p3": "DDD helpt in de aansluiting tussen business en IT en helpt bij het neerzetten van een gezonde architectuur voor een moderne software-afdeling. Het is cruciaal dat we de beslissingmakers in ons business-IT-landschap meenemen in domain-driven gedachtegoed.   ",
    "training_p4_title": "Korte omschrijving van de training",
    "training_p4": "De training DDD for IT leadership helpt met het creëren van strategie, draagvlak en ondersteuning voor de adoptie van domain-driven gedachtengoed. Domain-driven is steeds meer een enabler van moderne software omgevingen. Om van digitalisering een succes te maken moeten we onze business en software domeinen met de juiste structuur organiseren. Ondanks dat de cloud een technische enabler is, hebben we socio-technische enablers nodig om digitalisering voldoende te ondersteunen.",
    "training_p5": "Deze training begint met een verkenning van strategisch IT en hoe dit met DDD ondersteunt kan worden. Je krijgt inzicht op moderne trends als domain-driven, microservices, de data mesh, team topologies en het socio-technische systeem waarin ze zich tot elkaar verhouden. Via best-practices en bekende patronen bespreken we hoe hierop te sturen valt en waar men rekening mee moet houden. We behandelen casussen om het praktisch en herkenbaar te maken. ",
    "training_p6": "De training is uit te breiden met een Evenstorming casus. Dit is een interactieve kennismaking met het gedachtegoed en laat de deelnemers ervaren hoe het is om  complexe platformen te ontwerpen. ",
    "training_p7_title": "Doelgroep",
    "training_p7": "Deze training is bedoeld voor mensen in rollen als software development manager, product owners, product managers, C-level management, architecten, agile staff, IT managers en engineering leads, kortom iedereen die van software development teams een succes wil maken maar wellicht niet de technische achtergrond van een engineer heeft.  Technische kennis is zeker niet vereist, maar het enige ervaring in of rondom een software team komt van pas. ",
    "training_p8_title": "Voorbereiding",
    "training_p8": "Er is geen voorbereiding benodigd. In overleg kunnen voorbeelden en opdrachten op het domein van de deelnemers gericht worden.  "
  }
}
</i18n>
<script>
export default {
  name: 'TrainingEventstorming'
};
</script>
