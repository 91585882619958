<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n();
</script>

<template>
  <section id="offering" class="page-section">
    <div class="container">
      <div id="portfolio">
        <div class="row">
          <div class="col-lg-12  col-md-12">
            <h2 class="section-heading text-uppercase">{{ t("title") }}</h2>
          </div>
          <div class="col-lg-7 col-md-12">
            <div class="row">
              <p class="text-muted justified"> {{ t("introduction") }} </p>
              <video-player
                  src="/assets/video/sir-it-partnership.mp4"
                  controls=true
                  autoplay="any"
                  muted=true
                  loop=true
                  aspectRatio="16:9"
              />
            </div>
            <div class="row mt-5">
              <p class="text-muted justified">
                {{ t("introduction2") }}
              </p>
              <ul class="text-muted ms-3">
                <li>{{ t("introduction_li1") }}</li>
                <li>{{ t("introduction_li2") }}</li>
                <li>{{ t("introduction_li3") }}</li>
                <li>{{ t("introduction_li4") }}</li>
                <li>{{ t("introduction_li5") }}</li>
              </ul>
            </div>
            <div class="row mt-4">
              <h4>{{ t("approach_title") }}</h4>
              <p class="text-muted justified"> {{ t("approach") }} </p>
              <p class="text-muted justified"> {{ t("approach2") }} </p>
              <img src="assets/img/proposition/sir-cycle.jpg " alt="video" style="width: 100%">
              <p class="text-muted justified"> {{ t("approach3") }} </p>
              <p class="text-muted justified"> {{ t("approach4") }} </p>
            </div>
          </div>
          <div class="col-lg-5 ps-2">
              <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-2 mb-2">
                  <span class="fa-2x ms-lg-4">  <font-awesome-icon icon="stopwatch" class="icon-color"/>     </span>
                </div>
                <div class="col-lg-10 col-md-10 col-sm-10"><h5 class="my-3">{{ t("sub1_title") }}</h5></div>
                <p class="text-muted justified ms-lg-4">{{ t("sub1_desc") }}</p>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-2 col-md-2 col-sm-2 mb-2">
                  <span class="fa-2x ms-lg-4"> <font-awesome-icon icon="coins" class="icon-color"/> </span>
                </div>
                <div class="col-lg-10 col-md-10 col-sm-10"><h5 class="my-3">{{ t("sub2_title") }}</h5></div>
                <p class="text-muted justified ms-lg-4">{{ t("sub2_desc") }}</p>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-2 col-md-2 col-sm-2 mb-2">
                  <span class="fa-2x ms-lg-4"><font-awesome-icon icon="arrows-left-right" class="icon-color"/>   </span>
                </div>
                <div class="col-lg-10 col-md-10 col-sm-10"><h5 class="my-3">{{ t("sub3_title") }}</h5></div>
               <p class="text-muted justified ms-lg-4">{{ t("sub3_desc") }}</p>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-2 col-md-2 col-sm-2 mb-2">
                  <span class="fa-2x ms-lg-4">  <font-awesome-icon icon="book" class="icon-color"/> </span>
                </div>
                <div class="col-lg-10 col-md-10 col-sm-10"><h5 class="my-3">{{ t("sub4_title") }}</h5></div>
                 <p class="text-muted justified ms-lg-4">{{ t("sub4_desc") }}</p>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-2 col-md-2 col-sm-2 mb-2">
                  <span class="fa-2x ms-lg-4"> <font-awesome-icon icon="compass" class="icon-color"/> </span>
                </div>
                <div class="col-lg-10 col-md-10 col-sm-10"><h5 class="my-3">{{ t("sub5_title") }}</h5></div>
               <p class="text-muted justified ms-lg-4">{{ t("sub5_desc") }}</p>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-2 col-md-2 col-sm-2 mb-2">
                  <span class="fa-2x ms-lg-4">  <font-awesome-icon icon="gauge" class="icon-color"/> </span>
                </div>
                <div class="col-lg-10 col-md-10 col-sm-10"><h5 class="my-3">{{ t("sub6_title") }}</h5></div>
               <p class="text-muted justified ms-lg-4">{{ t("sub6_desc") }}</p>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-2 col-md-2 col-sm-2 mb-2">
                  <span class="fa-2x ms-lg-4"> <font-awesome-icon icon="users" class="icon-color"/> </span>
                </div>
                <div class="col-lg-10 col-md-10 col-sm-10"><h5 class="my-3">{{ t("sub7_title") }}</h5></div>
               <p class="text-muted justified ms-lg-4">{{ t("sub7_desc") }}</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n lang="json">
{
  "en": {
    "title": "CTO Partnership",
    "introduction": "The CTO Partnership is the solution for part time IT leadership. We work in a partnership, which means that we aim for long-term success. ",
    "introduction2": "The ways we help with our partnership:",
    "introduction_li1": "As fractional/part time CTO or IT Lead/Manager",
    "introduction_li2": "As coach or consultant for big IT transitions (cloud, devops, etc).",
    "introduction_li3": "As coach for  IT leadership, like a CTO or IT Lead/Manager",
    "introduction_li4": "As a sparring partner for IT management or the CTO",
    "introduction_li5": "As sparring partner for principal IT engineers of architecten",
    "approach_title": "A partnership, not just contracting",
    "approach": "We do this in a phased approach with attention and care for the design, development and safeguarding of a strong IT organization that serves the business objectives. Once the CTO role can be filled permanently, we will help with the selection of candidates and, as long as the need exists, we will remain involved and provide periodic advice and guidance. Creating a high-performance IT organization does not happen overnight.",
    "approach2": "The IT Leadership Partnership consists of a phased approach with attention to the plan, implementation, and tranfer of a strong IT organisation that services the business goals.",
    "approach3": "Above we describe a typical course of a collaboration where the intensity naturally varies depending on the situation. We know that the moment of 'standing on our own two feet' is actually the most important and we continuously work towards that. No matter how nice a collaboration is, it is healthy to gradually reduce it to a situation where the company meets the needs on its own.",
    "approach4": "We will also stay in touch then. Technology, products and ways of working continue to develop and we will provide advice and assistance on new issues. Creating a high-performance IT organization does not happen overnight! That is one of the reasons why a partnership is a lasting relationship.",
    "subtitle2": "All the benefits of a CTO Partnership at a glance:",
    "sub1_title": "Timing",
    "sub1_desc": "Recruiting a CTO is a big decision that often depends on a number of factors such as sufficient turnover, growth or profitability. To prevent strategic decisions and direction from being made too late, a CTO partnership is the solution.",
    "sub2_title": "Cost savings",
    "sub2_desc": "Hiring a full-time CTO is a significant cost item and difficult to justify, while strategic technical choices have to be made. A CTO partnership, on the other hand, offers the expertise you need, at the right time, at a fraction of the cost.",
    "sub3_title": "Flexibility",
    "sub3_desc": "Projects, transformations and business growth all have specific timelines with their own phasing. We adapt to the changing needs of the business and so services can scale up or down",
    "sub4_title": "Expertise",
    "sub4_desc": "A CTO partnership brings a wealth of experience and a broad view of things, which makes it possible to avoid common pitfalls and seize opportunities. It means insights and advice based on experience in the field, in order to make informed decisions.",
    "sub5_title": "Strategic insight",
    "sub5_desc": "We are able to map technological initiatives onto business objectives at a strategic level. To identify opportunities for technological innovation, determine what resources are best suited for it and develop technology roadmaps that support business goals.",
    "sub6_title": "Speed",
    "sub6_desc": "We get to work immediately to accelerate your technological initiatives so you stay ahead of your competition. We will quickly gain insight into your business, identify areas for improvement and implement solutions, saving you valuable time.",
    "sub7_title": "Technical leadership",
    "sub7_desc": "It is very possible that there will come a time when all the conditions for hiring a CTO are in place. We will help you to coach and train people within the organization to become CTOs as well as to recruit external candidates."
  },
  "nl": {
    "title": "IT Leadership Partnership",
    "introduction": "Het IT Leadership Partnership is dé oplossing voor situaties waar de randvoorwaarden voor full-time IT Leiderschap nog ontbreken. Het is een duurzame samenwerking gericht op snelle resultaten, maar met oog op de lange termijn.",
    "introduction2": "De manieren waarop we binnen het partnership helpen:",
    "introduction_li1": "Als fractional/deeltijd CTO of IT Lead/Manager",
    "introduction_li2": "Als begeleider bij grote IT transities (cloud, devops, etc).",
    "introduction_li3": "Als coach voor IT leadership rollen als CTO of IT Lead/Manager",
    "introduction_li4": "Als sparringpartner voor IT management of de CTO",
    "introduction_li5": "Als sparringpartner voor principal IT engineers of architecten",
    "approach_title": "Een partnership, en geen detachering",
    "approach": "Het IT Leadership Partnership bestaat uit een gefaseerde aanpak met aandacht en zorg voor de opzet, uitwerking en borging van een sterke IT organisatie die de zakelijke doelstellingen dient.",
    "approach2": "In tegenstelling tot detachering of consultancy modellen kijken wij uit naar een duurzaam partnerschap. We inventariseren bij de start en doen een voorstel voor het traject. Naargelang de behoefte, komen we samen (waar nodig met experts uit het netwerk) en volgt er een intensieve samenwerkingsperiode.",
    "approach3": "Hierboven beschrijven wij een gangbaar verloop van een samenwerking waar de intensiteit uiteraard afhankelijk van de situatie varieert. Wij weten dat het moment van 'op eigen benen staan' eigenlijk het belangrijkste is en werken daar continu naar toe. Hoe fijn een samenwerking ook is, het is gezond om deze geleidelijk af te bouwen naar een situatie waar de onderneming op eigen kracht voorziet in de behoefte.",
    "approach4": "Ook dan blijven we in contact. Technologie, producten en manieren van werken blijven zich ontwikkelen en we zullen bij nieuwe vraagstukken met raad en daad bijstaan. Het neerzetten van een high-performance IT organisatie gaat niet over één nacht ijs! Dat is één van de redenen waarom een partnerschap een duurzame relatie is.",
    "subtitle2": "Een aantal van de voordelen:",
    "sub1_title": "Voorkomen ipv genezen",
    "sub1_desc": "Het werven van een IT Leiderschapsrol is een grote beslissing die vaak afhankelijk is van een aantal factoren zoals voldoende omzet, groei of winstgevendheid. Om toch te voorkomen dat er te laat strategische beslissingen worden genomen en richting wordt gegeven is het IT leiderschap partnership de oplossing.",
    "sub2_title": "Kostenbesparing",
    "sub2_desc": "Het aannemen van een fulltime IT Leiderschapsrol is een aanzienlijke kostenpost en lastig te verantwoorden terwijl er wel strategische technische keuzes gemaakt moeten worden.  Het partnership daarentegen biedt de expertise die je nodig hebt, op het juiste moment tegen een fractie van de kosten.",
    "sub3_title": "Flexibiliteit",
    "sub3_desc": "Projecten, transformaties en bedrijfsgroei hebben alle specifieke tijdslijnen met hun eigen fasering. Wij passen ons aan aan de veranderende behoeften van het bedrijf en dus kunnen diensten op- of afschalen.",
    "sub4_title": "Expertise",
    "sub4_desc": "Het partnership brengt een schat aan ervaring en een brede kijk op zaken met zich mee, Dit maakt het mogelijk om veelvoorkomende valkuilen te vermijden en kansen te benutten. Het betekent inzichten en advies op basis van ervaring in het veld, om zo geïnformeerde beslissingen te kunnen nemen.",
    "sub5_title": "Strategisch inzicht",
    "sub5_desc": "Wij in staat om op strategisch niveau technologische initiatieven af te plotten op zakelijke doelstellingen. Om mogelijkheden voor technologische innovatie te identificeren, te bepalen wat voor resources daar het meest geschikt voor zijn en om technologische roadmaps te ontwikkelen die de bedrijfsdoelen ondersteunen.",
    "sub6_title": "Snelheid",
    "sub6_desc": "Wij gaan direct aan de slag om zo je technologische initiatieven te versnellen waardoor je concurrentie voorblijft. Wij hebben snel inzicht in je bedrijf, zullen gebieden voor verbetering identificeren en oplossingen implementeren, wat je waardevolle tijd bespaart.",
    "sub7_title": "Technical leadership",
    "sub7_desc": "Het is zeer goed mogelijk dat er een moment komt dat alle voorwaarden om een IT Leiderschapsrol te vervullen er zijn. Wij helpen je om zowel binnen de organisatie mensen te coachen en op te leiden als om de werving van externe kandidaten te vervullen."
  }
}
</i18n>

<script>
import {VideoPlayer} from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

export default {
  name: "TrainingsList",
  components: {VideoPlayer}
};
</script>
<style scoped>
#portfolio .portfolio-item {
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;
}

#portfolio .column-example {
  margin-top: 10rem;
}

#portfolio .portfolio-item img {
  height: 250px;
}

#portfolio .portfolio-item .portfolio-link {
  position: relative;
  display: block;
  margin: 0 auto;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 200, 0, 0.9);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity ease-in-out 0.25s;
}

#portfolio
.portfolio-item
.portfolio-link
.portfolio-hover
.portfolio-hover-content {
  font-size: 1.25rem;
  color: white;
}

#portfolio .portfolio-item .portfolio-link:hover .portfolio-hover {
  opacity: 1;
}

#portfolio .portfolio-item .portfolio-caption {
  padding: 1.5rem;
  text-align: center;
  background-color: #fff;
}

#portfolio .portfolio-item .portfolio-caption .portfolio-caption-heading {
  font-size: 1.5rem;
  font-family: "Montserrat",
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";
  font-weight: 700;
  margin-bottom: 0;
}

#portfolio .portfolio-item .portfolio-caption .portfolio-caption-subheading {
  font-style: italic;
  font-family: "Roboto Slab",
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";
}

@media only screen and (min-width: 991.98px) {
  .icon-align {
    text-align: right;
    padding-right: 25px;
  }
}

.icon-color {
  color: #17a2b8;
}
</style>
