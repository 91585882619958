<template>
  <div>
    <poster_header></poster_header>
    <router-view name="patternView"></router-view>
  </div>
</template>
<script>
import postHeader from "@/components/poster-header-small";

export default {
  name: 'pattern-page',
  components: {
    poster_header: postHeader,
  },
  metaInfo: {
    title: 'Software management patterns | Software in Rhythm',
  }
};
</script>
